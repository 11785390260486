import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles(() => ({
  CardField: {
    flexGrow: 1,
    flexWrap: 'wrap',
    justifyContent: 'center',
    //rowGap: '10px',
    // columnGap: '20px',

    '&.isDoctor': {
      gap: '10px',
      padding: '10px',
    },

    '&.isClient': {
      gap: '20px',
    },

    height: '100%',
    width: '100%',
  },

  CardField__slot: {
    position: 'relative',
    backgroundColor: pandaColors.black._800,
    flexShrink: 1,
    // flexGrow: 1,
    // flexBasis: '50%',
  },

  CardField__rows: {
    backgroundColor: pandaColors.black._800,
    // minWidth: '150px',
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: '0px', // flexBasis plus gaps etc
    // minWidth: '173px',
    //flexBasis: '47%',
    //marginBottom: '10px',
    //'&:last-child': {
    //  marginBottom: 0,
    //},
  },
  CardField__columns: {
    backgroundColor: pandaColors.black._800,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: '0px',
  },

  CardField__enlarge: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: pandaColors.black._800,
  },
}));
