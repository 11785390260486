import React, { MouseEventHandler } from 'react';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Fade from '@material-ui/core/Fade/Fade';
import Modal from '@material-ui/core/Modal/Modal';
import { ClickAwayListener } from '@material-ui/core';
import { MixProps } from '@src/common/generics';
import { useAppSelector } from '@app/hooks';
import { selectWrapperOffsets } from '@store/reducers/session.reducer';
import { styles } from './styles';

interface Props {
  isOpen: boolean | undefined | any; // without any ts-error
  handleClose: MouseEventHandler;
  isFullscreen?: boolean;
  resetWidth?: boolean;
  padding?: boolean;
  overlayTransparent?: boolean;
  backgroundColor?: string;
}

const CommonModal: React.FC<MixProps<Props>> = ({
  isOpen,
  handleClose,
  isFullscreen = false,
  className,
  resetWidth = false,
  padding = true,
  overlayTransparent = false,
  backgroundColor = 'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)',
  children,
}) => {
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);

  const classes = styles(wrapperOffsets);

  if (!isOpen) return <></>;

  return (
    // @ts-ignore
    <ClickAwayListener onClickAway={handleClose}>
      <Modal
        open={isOpen}
        onClose={handleClose}
        className={`
          ${classes.modal}
          ${overlayTransparent ? 'overlayTransparent' : ''}
          ${isFullscreen ? 'isFullscreen' : ''}
        `}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
        style={{ ...wrapperOffsets }}
      >
        <Fade in={isOpen}>
          <div
            id="transition-modal-description"
            className={`
              ${classes.paper} ${className}
              ${resetWidth ? 'resetWidth' : ''}
              ${padding ? 'padding' : ''}
            `}
            style={{ background: backgroundColor }}
          >
            <div className={classes.content}>{children}</div>
          </div>
        </Fade>
      </Modal>
    </ClickAwayListener>
  );
};

export default CommonModal;
