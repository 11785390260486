import { SimpleLoader } from '@pc/components/ui/Loader/Loader';
import pandaColors from '@src/_pandacards/helpers/colors';
import { styles } from './ChoosingCardsOverlay.styles';
import { TableCard, TakeCardData } from '@src/shared/sessionInterfaces/standardSession.socket';

type TProps = {
  cards: TableCard[];
  takeCardData: TakeCardData;
};

const ChoosingCardsOverlay: React.FC<TProps> = ({ cards, takeCardData }) => {
  const classes = styles();

  return (
    <div className={classes.ChoosingCardsOverlay}>
      <div className={classes.ChoosingCardsOverlay__wrapper}>
        <SimpleLoader color={pandaColors.primary.gold} />

        <p>
          Клиент выбирает карты&nbsp;<span>{takeCardData.takenAmount}</span>/<span>{takeCardData.amount}</span>
        </p>
      </div>
    </div>
  );
};

export default ChoosingCardsOverlay;
