import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles((theme) => ({
  CardsWillBeSoon: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: pandaColors.black._800,
    '& > img': {
      width: '240px',
      // client mobile
      [theme.breakpoints.down('xs')]: {
        width: '160px',
      },
    },

    '& > h3': {
      marginTop: '24px',
      marginBottom: 0,
      color: '#EFEFEF',
      textAlign: 'center',
      fontSize: '28px',
      fontWeight: 600,
      // client mobile
      [theme.breakpoints.down('xs')]: {
        marginTop: '22px',
        fontSize: '20px',
        padding: '0 25%',
      },
    },
  },
}));
