import { ScrollContainer } from '@pc/components/layout';
import { MixProps } from '@src/common/generics';
import { PersonItemBig } from '../../common/VideoChat/parts/PersonItem/PersonItemVariants';
import ImagineQuestion from './ImagineQuestion/ImagineQuestion';
import { styles } from './ClientLayout.styles';
import { LostConnection } from '@src/_pandacards/sessions/standard/parts';
import { selectCounterPartyIsOnline } from '@src/store/room/roomSlice';
import { useAppSelector } from '@src/app/hooks';

type TProps = {
  question: string;
  content?: React.ReactNode;
};

const ClientLayout: React.FC<MixProps<TProps>> = (props) => {
  const classes = styles();
  const isOtherPlayerOnline = useAppSelector(selectCounterPartyIsOnline);

  return (
    <ScrollContainer>
      <main className={classes.ClientLayout}>
        {/* game top content for client */}
        <section className={classes.ClientLayout__top}>
          <ImagineQuestion sessionQuestion={props.question} />
        </section>

        {/* center content */}
        <section className={classes.ClientLayout__center}>
          <div style={{ flexGrow: 1, position: 'relative', zIndex: 1 }}>
            {/* game center content for client */}
            {props.content}

            {/* doctor big video absoluted inside */}
            {!isOtherPlayerOnline ? (<LostConnection isDoctor={false} />) : (<PersonItemBig />)}
          </div>
        </section>
      </main>
    </ScrollContainer>
  );
};

export default ClientLayout;
