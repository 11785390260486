import { useRef, useState } from 'react';
import { videoData, TMedia, TSourceMedia } from './videoData';
// import { PlayIcon } from '@pc/components/icons';
import { styles } from './VideoPlayer.styles';

type TProps = {
  data?: TMedia;
  maxWidth?: number;
};

const VideoPlayer = ({ data = videoData, maxWidth = 640 }: TProps) => {
  const classes = styles();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isStarted, setStarted] = useState(false);

  const startPlay = () => {
    videoRef.current?.play();
    setStarted(true);
  };

  return (
    <div className={`${classes.VideoPlayer} VideoPlayer`} style={{ maxWidth }}>
      <video ref={videoRef} width="100%" height="100%" controls>
        {data?.src?.map((source: TSourceMedia, index: number) => {
          const { link, type } = source;

          return <source key={index} src={link} type={type} />;
        })}
        {/* No support phrase */}
        Your browser does not support HTML5 video tag.
      </video>

      {/* custom poster image on start */}
      {!isStarted && data?.poster && (
        <div
          onClick={startPlay}
          style={{ backgroundImage: `url(${data?.poster.link})` }}
          className={`${classes.VideoPlayer__poster} VideoPoster`}
        >
          {/* play icon */}
          {/* <span>
            <PlayIcon />
          </span> */}

          {/* posterTitle */}
          {data?.poster.title && <h3>{data?.poster.title}</h3>}
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
