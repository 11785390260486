import { VideoPlacement } from '@src/store/reducers/common';
import { PersonItem } from './PersonItem';
import { useAppSelector } from '@src/app/hooks';
import {
  selectClient,
  selectCounterParty,
  selectDoctor,
  selectIsDoctor,
  selectRoomPlayers,
} from '@src/store/room/roomSlice';
import { MixProps } from '@src/common/generics';
import { selectPlayerVideoState } from '@src/store/reducers/common';
import { styles } from './PersonItem.styles';

export const PersonItemDoctorSmall: React.FC<MixProps> = (props) => {
  const classes = styles();
  const doctor = useAppSelector(selectDoctor);
  const players = useAppSelector(selectRoomPlayers);
  const roomOwnerVideoState = useAppSelector(selectPlayerVideoState(players[0]?.playerId));

  if (!roomOwnerVideoState?.placement) {
    return (
      <PersonItem
        {...props}
        player={doctor}
        isLoading={false}
        placement={VideoPlacement.SMALL}
        isShowToggleButtonForClient={true}
      />
    );
  } else return <div className={classes.PersonItem__videoPlaceholder} />;
};

export const PersonItemClientSmall: React.FC<MixProps> = (props) => {
  const isClient = useAppSelector(selectClient);
  const isDoctor = useAppSelector(selectIsDoctor);

  return isDoctor || !isClient ? null : (
    <PersonItem {...props} player={isClient} placement={VideoPlacement.SMALL} isLoading={false} />
  );
};

export const PersonItemBig: React.FC<MixProps> = (props) => {
  const counterParty = useAppSelector(selectCounterParty);

  return counterParty ? (
    <PersonItem
      {...props}
      isLoading={false}
      player={counterParty}
      className="PersonItemBig"
      placement={VideoPlacement.LARGE}
      isShowToggleButtonForClient={true}
    />
  ) : null;
};
