import { gql } from '@apollo/client';
import { IdType } from '@shared/generics';
import { ROOM_STATE } from '@src/store/room/roomSlice';

export interface IRoom {
  id: IdType;
  title: string;
  icon: string;
  owner: IdType;
}

export const roomFragment = gql`
  fragment RoomFragment on Room {
    id
    title
    icon
    owner
  }
`;

export interface IActiveRoom extends IRoom {
  gameId?: IdType;
  state: ROOM_STATE;
}

export const activeRoomFragment = gql`
  fragment ActiveRoomFragment on ActiveRoom {
    id
    title
    icon
    owner
    gameId
    state
  }
`;
