import { useParams } from 'react-router-dom';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { CommonModal, Button } from '@pc/components/ui';
import { useAppSelector } from '@src/app/hooks';
import { selectRoomPlayers } from '@src/store/room/roomSlice';
import { styles } from './EndSessionModal.styles';

type TProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const EndSessionModal = ({ isOpen, closeModal }: TProps) => {
  const classes = styles();
  const { roomId } = useParams<{ roomId: string }>();
  const players = useAppSelector(selectRoomPlayers);
  const clientId = players[1]?.playerId;

  const handleEndSession = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_KICKED_FROM_ROOM, {
      roomId,
      targetId: clientId,
    });

    closeModal();
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={closeModal}>
      <div className={classes.EndSessionModal}>
        <h3>Завершить сеанс</h3>
        <p>
          Вы уверены, что хотите звершить сеанс
          <br /> и удалить клиента из кабинета?
        </p>

        <div>
          <Button type="blue" onClick={closeModal}>
            Оставить
          </Button>

          <Button type="white" onClick={handleEndSession}>
            Удалить
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default EndSessionModal;
