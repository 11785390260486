import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles(() => ({
  HowItWorksButton: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '200px',
    height: '40px',
    padding: '5px',
    flexShrink: 0,
    borderRadius: '20px',
    backgroundColor: pandaColors.black._650,
    color: pandaColors.white._50,

    '&:hover': {
      cursor: 'pointer',
      '& > span': {
        color: pandaColors.primary.gold,
      },
      '& path': {
        fill: pandaColors.primary.gold,
      },
    },

    '& > i': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      backgroundColor: pandaColors.black._600,
      marginRight: '15px',
      '& path': {
        transition: 'all 0.23s',
      },
    },

    '& > span': {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.25px',
      transition: 'all 0.23s',
    },
  },
}));
