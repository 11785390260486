import React from 'react';
import { CommonModal } from '@pc/components/ui';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import CloseCross from '@src/components/CloseCross/CloseCross';
import {
  selectCamera,
  selectCameras,
  selectIsAgoraLoading,
  selectMicrophone,
  selectMicrophones,
  setCamera,
  setMicrophone,
  setResetAgora,
} from '@src/store/reducers/playerData.reducer';

import { styles } from './SettingsModal.styles';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const SettingsModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const cameras = useAppSelector(selectCameras);
  const microphones = useAppSelector(selectMicrophones);
  const camera = useAppSelector(selectCamera);
  const microphone = useAppSelector(selectMicrophone);
  const isAgoraLoading = useAppSelector(selectIsAgoraLoading);

  const onCameraChange = (deviceId: string) => {
    const currentCamera = cameras.find((camera) => camera.deviceId === deviceId);
    dispatch(setCamera(currentCamera));
    dispatch(setResetAgora({}));
  };

  const onMicrophoneChange = (deviceId: string) => {
    const currentMicrophone = microphones.find((microphone) => microphone.deviceId === deviceId);
    dispatch(setMicrophone(currentMicrophone));
    dispatch(setResetAgora({}));
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={closeModal}>
      <div className={classes.SettingsModal}>
        <CloseCross onClick={closeModal} />

        <h3 id="transition-modal-title">Настройки</h3>

        <div className={classes.SettingsModal__content}>
          <div className={classes.SettingsModal__contentItem}>
            <label htmlFor="camera" className="title">
              Камера
            </label>

            <select
              name="camera"
              value={camera?.deviceId}
              onChange={(event) => onCameraChange(event.currentTarget.value)}
              className="menu"
              disabled={isAgoraLoading || cameras.length < 1}
            >
              {cameras.length > 0 ? (
                cameras.map((camera, index) => (
                  <option value={camera.deviceId} key={index}>
                    {camera.label}
                  </option>
                ))
              ) : (
                <option value={''}>У тебя нет подключенной камеры</option>
              )}
            </select>

            <div className="arrow" />
          </div>

          <div className={classes.SettingsModal__contentItem}>
            <label htmlFor="microphone" className="title">
              Микрофон
            </label>

            <select
              name="microphone"
              value={microphone?.deviceId}
              onChange={(event) => onMicrophoneChange(event.currentTarget.value)}
              className="menu"
              disabled={isAgoraLoading || cameras.length < 1}
            >
              {microphones.length > 0 ? (
                microphones.map((micro, index) => (
                  <option value={micro.deviceId} key={index}>
                    {micro.label}
                  </option>
                ))
              ) : (
                <option value={''}>У тебя нет подключенного микрофона</option>
              )}
            </select>
          </div>
        </div>
      </div>
    </CommonModal>
  );
};

export default SettingsModal;
