import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '360px',
    flexDirection: 'column',
    alignSelf: 'center',
    '& > h3': {
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '42px',
      textTransform: 'uppercase',
      marginTop: 0,
      [theme.breakpoints.up('md')]: {
        marginTop: '5px',
      },
    },
  },

  text: {
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: '20px',
  },

  btnContainer: {
    color: 'inherit',
  },
}));
