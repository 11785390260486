import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  closeCross: {
    width: '36px',
    height: '36px',
    background: 'linear-gradient(180deg, #E8FDFF 0%, #BCE1FC 100%)',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all .23s',
    borderRadius: '50%',
    position: 'absolute',
    top: '-63px',
    right: '-9px',
    '&:hover': {
      cursor: 'pointer',
      transition: 'all .23s',
      '& > svg > path': {
        fill: 'black',
      },
    },
  },
}));
