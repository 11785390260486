import { useAppSelector } from '@src/app/hooks';
import { useAppDispatch } from '@app/hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { selectDeviceInfo, setDeviceSize } from '@store/reducers/session.reducer';
import { useEffect } from 'react';

export enum DEVICE_SIZE {
  UNDEFINED = 'undefined',
  MOBILE = 'isMobile',
  TABLET = 'isTablet',
  DESKTOP = 'isDesktop',
}

export const deviceSizeService = () => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 599px)');
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const deviceSize = isMobile ? DEVICE_SIZE.MOBILE : isDesktop ? DEVICE_SIZE.DESKTOP : DEVICE_SIZE.TABLET;

  useEffect(() => {
    console.log('DEVICE SIZE', deviceSize);
    dispatch(setDeviceSize(deviceSize));
  }, [isMobile, isDesktop]);

  return undefined;
};

export const useDeviceSize = () => useAppSelector(selectDeviceInfo);
