import { CommonModal } from '@pc/components/ui';
import { styles } from './ChooseDecksModal.styles';
import { useAppSelector } from '@src/app/hooks';
import { selectDecks, selectPlayerDecksExtended } from '@src/store/reducers/common';
import { IdType } from '@src/shared/generics';
import WSClient from '@src/sockets';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { getAvatarLink } from '@src/_pandacards/helpers/deckDataMethods';
import { MAX_DECKS } from '@src/common/constants/system';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const ChooseDecksModal: React.FC<Props> = ({ isOpen, closeModal }) => {
  const classes = styles();
  const decks = useAppSelector(selectDecks);
  const playerDecks = useAppSelector(selectPlayerDecksExtended);

  const sendDeckSelected = (cardDeckId: IdType, isChosen: boolean) => {
    if (playerDecks.length < MAX_DECKS || !isChosen) {
      WSClient.emit(CLIENT_EVENTS.PLAYER_UPDATED_CARD_DECK, { cardDeckId, isChosen });
    }
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={() => closeModal()}>
      <div className={classes.ChooseDecksModal}>
        <div className={classes.ChooseDecksModal__top}>
          <h3>Выбранные колоды</h3>
          <span onClick={() => closeModal()}>Готово</span>

          <ul className={classes.ChooseDecksModal__slots}>
            {/* selected decks in slots */}
            {playerDecks.map((deck) => {
              return (
                <li key={deck.id} className="filledSlot">
                  <div className="delete" onClick={() => sendDeckSelected(deck.id, false)} />
                  <i style={{ backgroundImage: `url(${getAvatarLink(deck.dataLink)})` }} />
                  <span>{deck.name}</span>
                </li>
              );
            })}

            {/* fill empty slots */}
            {new Array(MAX_DECKS - playerDecks.length).fill('').map((_item, index) => {
              return (
                <li key={index} className="emptySlot">
                  <i />
                </li>
              );
            })}
          </ul>
        </div>

        {/* decks list */}
        <div className={classes.ChooseDecksModal__bottom}>
          <h4>
            Доступно для выбора <span>{decks.length}</span> колоды
          </h4>

          <ul>
            {decks.map((deck) => {
              const isSelected = playerDecks.some((selectedDeck) => selectedDeck.id === deck.id);

              return (
                <li key={deck.id} onClick={() => sendDeckSelected(deck.id, !isSelected)}>
                  <i
                    style={{ backgroundImage: `url(${getAvatarLink(deck.dataLink)})` }}
                    className={`${isSelected ? 'isSelected' : ''}`}
                  />
                  <span>{deck.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </CommonModal>
  );
};

export default ChooseDecksModal;
