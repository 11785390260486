import { styles } from './Discarded.styles';

type TProps = {
  children: React.ReactNode;
};

const Discarded: React.FC<TProps> = ({ children }) => {
  const classes = styles();

  return <div className={classes.Discarded}>{children}</div>;
};

export default Discarded;
