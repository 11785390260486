import { IdType } from '@shared/generics';

export enum NOTIFICATION_TYPE {
  COPY_LINK,
  COPY_ID,
  SEND,
  RECEIVE,
  WITHDRAW,
  PUBLICITY,
  QUEST_PROGRESS,
  QUEST_DONE,
  QUEST,
  ERROR,
  DEPOSIT,
}

export interface INotification {
  type: NOTIFICATION_TYPE;
}

export interface IResultNotification extends INotification {
  success: boolean;
}

export interface IRecievedNotification extends INotification {
  playerId: IdType;
  amount: number;
  name: string;
}

export interface IQuestNotification extends INotification {
  questId: IdType;
}

export interface IDepositNotification extends INotification {
  result: boolean;
  amount?: number;
}
