import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    loginForm: {
      padding: '0 25px',
      width: '100%',
      marginTop: '40px',
      marginBottom: '3px',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },

    forgotPassword: {
      fontWeight: 500,
      width: '100%',
      textAlign: 'right',
      fontSize: '12px',
      lineHeight: '14px',
      marginTop: '-9px',
      '& a': {
        color: '#2C74F3',
      },
    },

    submitButton: {
      width: '145px',
      minHeight: 'auto',
      height: '36px',
      padding: '0',
      background: '#2C74F3',
      borderRadius: '30px',
      fontWeight: 500,
      boxShadow: 'none',
      marginTop: '17px',
      alignSelf: 'center',
      marginBottom: '18px',
      [theme.breakpoints.up('sm')]: {
        width: '220px',
        height: '40px',
        marginBottom: '22px',
      },
      '& span': {
        fontSize: '16px',
        lineHeight: '16px',
      },
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: '#356FD6',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: '#3662AE',
      },
    },
  })
);
