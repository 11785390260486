import { RouteComponentProps } from 'react-router';
import { SessionType } from '@src/shared/common';
import { StandardSession } from '@src/_pandacards/sessions';

interface RouterProps {}

interface Props extends RouteComponentProps<RouterProps> {}

const PandaRoomSession: React.FC<Props> = ({ match }) => {
  return renderSession(SessionType.STANDARD);
};

const renderSession = (sessionType: SessionType) => {
  switch (sessionType) {
    case SessionType.STANDARD:
      return <StandardSession />;
    default:
      return null;
  }
};

export { PandaRoomSession };
