import { useEffect, useRef, useState } from 'react';
import { Button, CommonModal } from '@pc/components/ui';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { IdType } from '@src/shared/generics';
import { Arrow, CloseIcon } from '@pc/components/icons';
import pandaColors from '@src/_pandacards/helpers/colors';
import { TableCard, TakeCardData, UsedDeck } from '@src/shared/sessionInterfaces/standardSession.socket';
import { styles } from './CardSwapperMobile.styles';
import { useDeckCards } from '../../modals/TakeCards/TakeCardsOpenedModal/useDeckCards';
import { SimpleCard } from '../Card/Card';
import { CardPlacement, ITableSlot } from '../../standardSession.types';
import { Aspect, defaultAspect } from '../cardMath';
import { useAppSelector } from '@src/app/hooks';
import { selectCardBack } from '@src/store/room/roomFeaturesData.slice';
import { HookType } from '../../standardSession.hook';
import { EnlargedSlot } from '../CardField/EnlargedSlot';

type TProps = {
  hook: HookType;
};

const CardSwapperFieldMobile: React.FC<TProps> = ({ hook }) => {
  const classes = styles();
  const session = hook.session;
  const slotIndices = session.slots.reduce(
    (result, slot, index) => (slot.cards.length > 0 ? [...result, index] : result),
    [] as number[]
  );
  const [slidePosition, setSlidePosition] = useState<number>(0);
  const SwipeableViewsVirtualize = virtualize(SwipeableViews);
  const slotRef = useRef<HTMLDivElement>(null);
  const [aspect, setAspect] = useState<Aspect>(defaultAspect);

  const rotateLeft = () => setSlidePosition(slidePosition <= 0 ? slotIndices.length - 1 : slidePosition - 1);
  const rotateRight = () => setSlidePosition(slidePosition + 1 < slotIndices.length ? slidePosition + 1 : 0);

  useEffect(() => {
    if (slotRef.current) {
      setAspect({ height: slotRef.current.clientHeight, width: slotRef.current.clientWidth });
    }
  }, [slotRef.current]);

  return (
    <div className={classes.TakeCardsOpenedModal}>
      <section className={classes.TakeCardsOpenedModal__slider}>
        {/* Слайдер с виртуальным списком */}
        <div className={classes.TakeCardsOpenedModal__sliderWrapper} ref={slotRef}>
          {slotIndices.length > 0 && aspect.height > 0 && (
            <SwipeableViewsVirtualize
              axis="x"
              index={slidePosition}
              slideCount={slotIndices.length}
              className={classes.TakeCardsOpenedModal__sliderVirtualList}
              onChangeIndex={(slidePosition) => setSlidePosition(slidePosition)}
              slideRenderer={({ index, key }) => (
                <EnlargedSlot
                  key={key}
                  hook={hook}
                  slotIndex={slotIndices[index]}
                  slotAspect={aspect}
                  placement={CardPlacement.MODAL}
                />
              )}
            />
          )}
        </div>

        {slotIndices.length > 1 && (
          <div className={classes.TakeCardsOpenedModal__sliderControls}>
            <i onClick={rotateLeft}>
              <Arrow />
            </i>
            <i onClick={rotateRight}>
              <Arrow direction="right" />
            </i>
          </div>
        )}
      </section>
    </div>
  );
};

export { CardSwapperFieldMobile };
