import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  root: {
    color: 'inherit',
  },

  wrapper: {
    width: '100hw',
    color: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',
    '& > h3': {
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '42px',
      textTransform: 'uppercase',
      marginTop: 0,
      [theme.breakpoints.up('sm')]: {
        marginBottom: '15px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '5px',
      },
    },
  },

  button: {
    color: 'inherit',
  },

  btnContainer: {
    justifyContent: 'center',
  },

  formInner: {
    color: 'inherit',
    flexDirection: 'column',

    // checbox label
    '& .MuiFormControlLabel-label': {
      fontFamily,
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21px',
      color: '#1B2036',
    },
  },
}));
