import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    // input
    rootPassword: {
      width: '100%',
      border: 0,
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },

      // change icon color when clicked
      '& input[type="text"] + div svg > path': {
        fill: '#2C74F3',
      },

      // eye button
      // '& .MuiIconButton-edgeEnd': {
      // outline: '1px solid red',
      // },

      '&.Mui-focused.Mui-error fieldset': {
        borderColor: '#F98593',
        borderWidth: '1px',
      },

      // label (floating) static
      '& label': {
        fontSize: '14px',
        lineHeight: '21px',
        color: '#BBBCC3',
        transform: 'translate(14px, 12px) scale(1)',
        [theme.breakpoints.up('sm')]: {
          transform: 'translate(14px, 10px) scale(1)',
        },

        // label (floating) focused
        '&.Mui-focused': {
          color: '#2C74F3',
          transform: 'translate(14px, -8px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -8px) scale(0.75)',
        },
      },

      // input
      '& .MuiOutlinedInput-root': {
        height: '48px',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#1B2036',
        boxSizing: 'border-box',
        [theme.breakpoints.up('sm')]: {
          height: '44px',
        },

        // input static
        '& fieldset': {
          borderColor: '#E8E9EB',
          borderWidth: '1px',
        },

        // input hover
        '&:hover fieldset': {
          borderColor: '#BBBCC3',
          borderWidth: '1px',
        },

        // input focus
        '&.Mui-focused fieldset': {
          borderColor: '#2C74F3',
          borderWidth: '1px',

          // label bg
          '& legend': {
            width: '55px !important',
          },
        },
      },

      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
    },
  })
);
