const initializeLogger = () => {
  const logLevels = ['debug', 'error', 'info', 'log', 'trace', 'warn'] as const;
  type LogLevelType = typeof logLevels[number];

  //const logLevel = (process.env.NODE_ENV === 'production') ? 'info' : 'debug';
  const logger = (logLevel: LogLevelType, ...args: any[]) => {
    return console[logLevel](...args);
  };

  // make it dynamically fillable and with process.env.NODE_ENV exclusion.
  return {
    debug: (...args: any[]) => logger('debug', ...args),
    error: (...args: any[]) => logger('error', ...args),
    info: (...args: any[]) => logger('info', ...args),
    log: (...args: any[]) => logger('log', ...args),
    trace: (...args: any[]) => logger('trace', ...args),
    warn: (...args: any[]) => logger('warn', ...args),
  };
};

const logger = initializeLogger();

export { logger };
