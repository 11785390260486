import { makeStyles } from '@material-ui/core';

import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  root: {
    fontFamily,
    color: 'inherit',
  },

  wrapper: {
    color: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',

    '& > h3': {
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '42px',
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: '15px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '15px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '5px',
      },
    },
  },

  formInner: {
    color: 'inherit',
    flexDirection: 'column',
    '& > h6': {
      fontFamily: fontFamily,
      fontWeight: 500,
      fontSize: '17px',
      lineHeight: '17px',
      marginTop: 0,
      marginBottom: '10px',
      textAlign: 'start',
    },
  },

  form: {
    color: 'inherit',
  },

  button: {
    color: 'inherit',
  },

  avatarsContainer: {
    alignItems: 'start',
    marginBottom: '10px',
    overflowX: 'scroll',
    margin: '0 -15px',
    padding: '0 15px',
    [theme.breakpoints.up('sm')]: {
      margin: '10px -30px',
      padding: '0 35px',
      flexWrap: 'wrap',
      overflowX: 'unset',
    },
    [theme.breakpoints.up('md')]: {
      margin: '10px -15px',
      padding: '0 15px',
    },
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      overflowX: 'initial',
      flexWrap: 'wrap',
    },
  },

  avatarContainer: {
    display: 'flex',
    position: 'relative',
  },

  avatar: {
    width: '60px',
    height: '60px',
    marginBottom: '5px',
    borderRadius: '50%',
    border: '3px solid transparent',
    transition: '0.2s ease-out',
    '&:not(last-child)': {
      marginRight: '3px',
    },
    '&:hover': {
      border: '3px solid #2C74F3',
    },
  },

  check: {
    position: 'absolute',
    zIndex: 2,
    transform: 'translate(-50%, -50%)',
    top: '48%',
    left: '48%',
    width: '16px',
    height: '16px',
  },

  background: {
    background: 'rgba(255, 255, 255, 0.4)',
    width: '60px',
    height: '60px',
    position: 'absolute',
    zIndex: 1,
    border: '3px solid #2C74F3',
    borderRadius: '50%',
  },
}));
