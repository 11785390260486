import { gql } from '@apollo/client';
import { AppDispatch, AppGetState } from '@src/app/store';
import { fetchGraphql, mutateGraphql } from '@src/library/fetch';
import { analytics } from '@src/services/amplitude';
import { AUTH_TYPE, LoginType } from '@src/shared/common';
import { IdType } from '@src/shared/generics';
import { IRoom, roomFragment } from '@src/shared/misc/room.fragment';
import { loginFinish, loginStart } from '@src/store/reducers/loadActions';
import {
  makeQuestClaimed,
  selectHasConnectQuest,
  selectHasInviteQuest,
  selectPlayerId,
  setBalance,
  setEnergy,
  setIsAuthenticated,
  setPlayerData,
  setPlayerRoom,
  setRewards,
} from '@src/store/reducers/player.reducer';
import { setServerDiff } from '@src/store/reducers/session.reducer';
import { updatePlayerThunk } from '@src/store/thunk/player.thunk';
import {
  energyFragment,
  IEnergy,
  IPlayerData,
  IRewards,
  PlayerAuth,
  playerAuthFragment,
  playerDataFragment,
  rewardsFragment,
} from './player.fragment';

export const playerDataQuery = gql`
  query PlayerDataQuery($playerId: ID!) {
    playerData(playerId: $playerId) {
      ...PlayerDataFragment
    }
  }
  ${playerDataFragment}
`;

export type PlayerDataQueryParams = {
  playerId: IdType;
};

export type PlayerDataQueryResponse = {
  playerData: IPlayerData;
};

export const requestPlayerData = (dispatch: AppDispatch, getState: AppGetState) => {
  const playerId = selectPlayerId(getState());

  if (!playerId) {
    return;
  }

  return fetchGraphql<PlayerDataQueryParams, PlayerDataQueryResponse>(playerDataQuery, { playerId }, true, true).then(
    (data) => {
      dispatch(setPlayerData(data.playerData));
    }
  );
};

export const loginPlayerComplexMutation = gql`
  mutation loginPlayerComplex($playerData: PlayerInput!, $loginType: LOGIN_TYPE!) {
    loginPlayerComplex(playerData: $playerData, loginType: $loginType) {
      player {
        ...PlayerAuthFragment
      }
      room {
        ...RoomFragment
      }
    }
  }
  ${playerAuthFragment}
  ${roomFragment}
`;

export interface LoginPlayerComplexParams {
  playerData: {
    name: string | undefined;
  };
  loginType: LoginType;
}

export interface LoginPlayerComplexResponse {
  loginPlayerComplex: {
    player: PlayerAuth;
    room: IRoom;
  };
}

type OnResolve = (playerId: IdType, isNewPlayer: boolean) => void;
type OnReject = () => void;

export const loginPlayer = (variables: LoginPlayerComplexParams, onResolve: OnResolve, onReject: OnReject) => (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  dispatch(loginStart());
  mutateGraphql<LoginPlayerComplexParams, LoginPlayerComplexResponse>(loginPlayerComplexMutation, variables)
    .then((data) => {
      const response = data.loginPlayerComplex;
      console.log(response);
      dispatch(updatePlayerThunk(response.player));
      dispatch(setPlayerRoom(response.room));
      dispatch(setIsAuthenticated(true));
      onResolve(response.player.publicId, response.player.newPlayer);
    })
    .catch((reason) => {
      onReject();
    })
    .finally(() => {
      dispatch(loginFinish());
    });
};

export const getOrCreatePlayerMutation = gql`
  mutation getOrCreatePlayer($publicId: ID, $autocreate: Boolean, $playerData: PlayerInput!) {
    getOrCreatePlayer(publicId: $publicId, autocreate: $autocreate, playerData: $playerData) {
      player {
        ...PlayerAuthFragment
      }
      room {
        ...RoomFragment
      }
    }
  }
  ${playerAuthFragment}
  ${roomFragment}
`;

export interface GetOrCreatePlayerParams {
  publicId: IdType;
  autocreate: boolean;
  playerData: {
    name: string | undefined;
  };
}

export interface GetOrCreatePlayerResponse {
  getOrCreatePlayer: {
    player: PlayerAuth;
    playerData: IPlayerData;
    room: IRoom;
  };
}

export const requestPlayer = (variables: GetOrCreatePlayerParams, onResolve: OnResolve, onReject: OnReject) => (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  dispatch(loginStart());
  mutateGraphql<GetOrCreatePlayerParams, GetOrCreatePlayerResponse>(getOrCreatePlayerMutation, variables)
    .then((data) => {
      const response = data.getOrCreatePlayer;
      console.log(response);
      dispatch(updatePlayerThunk(response.player));
      dispatch(setPlayerData(response.playerData));
      dispatch(setPlayerRoom(response.room));
      dispatch(setIsAuthenticated(true));
      onResolve(response.player.publicId, response.player.newPlayer);
    })
    .catch((reason) => {
      onReject();
    })
    .finally(() => {
      dispatch(loginFinish());
    });
};

export const rewardsQuery = gql`
  query RewardsQuery($playerId: ID!) {
    player(publicId: $playerId) {
      rewards {
        ...RewardsFragment
      }
    }
  }
  ${rewardsFragment}
`;

export type RewardsQueryParams = {
  playerId: string;
};

export type RewardsQueryResponse = {
  player: {
    rewards: IRewards;
  };
};

export const requestRewards = (dispatch: AppDispatch, getState: AppGetState) => {
  const playerId = selectPlayerId(getState());

  /*if (!playerId) return;
  return fetchGraphql<RewardsQueryParams, RewardsQueryResponse>(rewardsQuery, { playerId }, true, true).then((data) => {
    dispatch(setRewards(data.player.rewards));
  });*/
};

export const energyQuery = gql`
  query EnergyQuery($playerId: ID!) {
    player(publicId: $playerId) {
      energy {
        ...EnergyFragment
      }
    }
  }
  ${energyFragment}
`;

export type EnergyQueryParams = {
  playerId: string;
};

export type EnergyQueryResponse = {
  player: {
    energy: IEnergy;
  };
};

export const requestEnergy = (dispatch: AppDispatch, getState: AppGetState) => {
  const playerId = selectPlayerId(getState());

  /*return fetchGraphql<EnergyQueryParams, EnergyQueryResponse>(energyQuery, { playerId }, true, true).then((data) => {
    dispatch(setEnergy(data.player.energy));
  });*/
};

export const claimQuestRewardMutation = gql`
  mutation claimQuestReward($playerId: ID!, $questId: ID!) {
    claimQuestReward(playerId: $playerId, questId: $questId) {
      result
      balance
      energy {
        amount
        end
      }
    }
  }
`;

export type ClaimQuestRewardParams = {
  playerId: IdType;
  questId: IdType;
};

export type ClaimQuestRewardResponse = {
  claimQuestReward: {
    result: boolean;
    balance: number;
    energy: IEnergy;
  };
};

export const serverSyncQuery = gql`
  query timeSync {
    timeSync
  }
`;

export interface SyncQueryResponse {
  timeSync: number;
}

export const requestServerSync = (dispatch: AppDispatch, getState: AppGetState) => {
  const requestTime = new Date().valueOf();
  fetchGraphql<{}, SyncQueryResponse>(serverSyncQuery, {}, true).then((data) => {
    const responseTime = new Date().valueOf();
    const ping = (responseTime - requestTime) * 0.5;
    const serverDiff = responseTime - data.timeSync - ping;

    dispatch(setServerDiff(serverDiff));
  });
};
