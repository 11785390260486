import { useEffect, useState } from 'react';
import { Aspect } from '../cardMath';

const getDoctorLayouts = (gap: string) => [
  { flexBasis: '0%', height: '0%' },
  { flexBasis: '100%', height: '100%' },
  { flexBasis: '100%', height: `calc(50% - 0.5 * ${gap})` },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: `calc(50% - 0.5 * ${gap})` },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: `calc(50% - 0.5 * ${gap})` },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: `calc(33.3% - 0.66 * ${gap})` },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: `calc(33.3% - 0.66 * ${gap})` },
];

const getDoctorSlotAspects = (gap: number, aspect: Aspect): Aspect[] => [
  { width: 0, height: 0 },
  { width: aspect.width, height: aspect.height },
  { width: aspect.width, height: 0.5 * (aspect.height - gap) },
  { width: 0.5 * (aspect.width - gap), height: 0.5 * (aspect.height - gap) },
  { width: 0.5 * (aspect.width - gap), height: 0.5 * (aspect.height - gap) },
  { width: 0.5 * (aspect.width - gap), height: 0.33 * aspect.height - 0.66 * gap },
  { width: 0.5 * (aspect.width - gap), height: 0.33 * aspect.height - 0.66 * gap },
];

const getClientLayouts = (gap: string) => [
  { flexBasis: '0%', height: '0%' },
  { flexBasis: '100%', height: '100%' },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: '100%' },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: `calc(50% - 0.5 * ${gap})` },
  { flexBasis: `calc(50% - 0.5 * ${gap})`, height: `calc(50% - 0.5 * ${gap})` },
  { flexBasis: `calc(33.3% - 0.66 * ${gap})`, height: `calc(50% - 0.5 * ${gap})` },
  { flexBasis: `calc(33.3% - 0.66 * ${gap})`, height: `calc(50% - 0.5 * ${gap})` },
];

const getClientSlotAspects = (gap: number, aspect: Aspect): Aspect[] => [
  { width: 0, height: 0 },
  { width: aspect.width, height: aspect.height },
  { width: 0.5 * (aspect.width - gap), height: aspect.height },
  { width: 0.5 * (aspect.width - gap), height: 0.5 * (aspect.height - gap) },
  { width: 0.5 * (aspect.width - gap), height: 0.5 * (aspect.height - gap) },
  { width: 0.33 * aspect.width - 0.66 * gap, height: 0.5 * (aspect.height - gap) },
  { width: 0.33 * aspect.width - 0.66 * gap, height: 0.5 * (aspect.height - gap) },
];

export const getLayouts = (isDoctor: boolean, gap: string) =>
  isDoctor ? getDoctorLayouts(gap) : getClientLayouts(gap);

export const getSlotAspects = (isDoctor: boolean, gap: number, aspect: Aspect) =>
  isDoctor ? getDoctorSlotAspects(gap, aspect) : getClientSlotAspects(gap, aspect);

export interface Layout {
  flexBasis: string;
  height: string;
  // maxWidth: string;
}

export const useLayouts = (isDoctor: boolean, aspect: Aspect, slotLength: number) => {
  const [layout, setLayout] = useState<Layout[]>([]);
  const [slotAspects, setSlotAspects] = useState<Aspect[]>([]);
  const gap = isDoctor ? 10 : 20;
  const padding = isDoctor ? 10 : 0;
  const slotStyle = layout[slotLength];
  const slotAspect = slotAspects[slotLength] || { width: 0, height: 0 };

  useEffect(() => {
    // const layout = getLayouts(isDoctor, gap);
    // setLayout(layout);
    const slotAspects = getSlotAspects(isDoctor, gap, aspect);
    setSlotAspects(slotAspects);
  }, [isDoctor, aspect]);

  return [gap, padding, slotAspect] as const;
};
