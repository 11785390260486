import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  socialCyrcleItem: {
    width: '65px',
    height: '65px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    fontSize: '12px',
    lineHeight: '14px',
    color: '#5F6372',
    borderRadius: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    transition: 'all .23s',

    '&.Google:hover': { backgroundColor: '#CA4539 !important' },
    '&.Facebook:hover': { backgroundColor: '#4C77D0 !important' },
    '&.Twitter:hover': { backgroundColor: '#23A2EA !important' },

    '&.Google:active': { backgroundColor: '#BD3B2F !important' },
    '&.Facebook:active': { backgroundColor: '#4870C6 !important' },
    '&.Twitter:active': { backgroundColor: '#229CE1 !important' },

    '&.More': {
      border: '1px solid #E8E9EB',
      '&:hover': { borderColor: '#BBBCC3' },
    },
  },
}));

interface Props {
  type: string;
  backgroundColor?: string;
  icon?: string;
  onClick?: void;
}

const SocialCircleItem: React.FC<Props> = ({ type, icon, backgroundColor, onClick }: Props) => {
  const classes = styles();

  if (type !== 'More') {
    return (
      <li>
        <a href="#" className={`${classes.socialCyrcleItem} ${type}`} style={{ backgroundColor: `${backgroundColor}` }}>
          <img onClick={() => onClick} src={icon} alt={type} />
        </a>
      </li>
    );
  } else {
    return (
      <li>
        <NavLink to="/loginSocial" className={`${classes.socialCyrcleItem} ${type}`}>
          {type}
        </NavLink>
      </li>
    );
  }
};

export default SocialCircleItem;
