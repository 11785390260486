import VideoPlaceholder from '@pc/assets/images/video2.jpg';

export type TSourceMedia = {
  link: string;
  type: string;
};

export type TMedia = {
  poster: {
    title: string;
    link: string;
  };
  src: TSourceMedia[];
};

const testExample: TMedia = {
  poster: {
    title: 'Big Buck Bunny',
    link:
      'https://resizer.mail.ru/p/f211f81a-7ec1-5e37-ae86-cb8136823ec1/dpr:133/AAACCfyTZokFA46CppsGytluzDmUzdqDdCxPEqrT12hKVQ82Hyp7yy0JCmcRYhMN5hqoAHldJ-f-xlNbxOel5DXWzq4.jpg',
  },

  src: [
    {
      link: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
      type: 'video/mp4',
    },
  ],
};

export const videoData: TMedia = {
  poster: {
    title: '',
    link: VideoPlaceholder,
  },

  src: [
    {
      link: testExample.src[0].link,
      type: 'video/mp4',
    },
  ],
};
