import { useState, useEffect } from 'react';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  MicrophoneAudioTrackInitConfig,
  CameraVideoTrackInitConfig,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalVideoTrack,
  ILocalAudioTrack,
  VideoEncoderConfigurationPreset,
  NetworkQuality,
} from 'agora-rtc-sdk-ng';
import { AGORA_STATE } from './agora.types';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectCurrentPlayerVideoState, setVideoState, setVideoStates } from '@store/reducers/common';
import { analytics } from '@services/amplitude';
import { muteAll } from '@store/thunk/playerData.thunk';
import {
  selectCamera,
  selectMicrophone,
  selectResetAgora,
  setCameras,
  setIsAgoraLoading,
  setMicrophones,
} from '@src/store/reducers/playerData.reducer';
import { sendAgoraError } from './helperMethods';

interface Props {
  videoProfile?: string;
}

const convertDeviceInfo = (deviceInfo: MediaDeviceInfo) => ({
  deviceId: deviceInfo.deviceId,
  label: deviceInfo.label,
});

const convertDeviceInfos = (deviceInfos: MediaDeviceInfo[]) => {
  return deviceInfos.map((deviceInfo) => convertDeviceInfo(deviceInfo));
};

export function useAgoraInternal(props: Props) {
  const dispatch = useAppDispatch();
  const playersVideoState = useAppSelector(selectCurrentPlayerVideoState);
  const [client, setClient] = useState<IAgoraRTCClient | undefined>(undefined);
  const [localVideoTrack, setLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);
  const [state, setState] = useState(AGORA_STATE.EMPTY);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const chosenMicrophone = useAppSelector(selectMicrophone);
  const chosenCamera = useAppSelector(selectCamera);
  const resetAgora = useAppSelector(selectResetAgora);

  const canPublish = () => [AGORA_STATE.CLIENT_JOINED, AGORA_STATE.STREAM_DISABLED].includes(state);

  const onVideoEnabled = (playerId: string, shouldEnable: boolean) =>
    dispatch(setVideoState({ playerId, data: { isVideoEnabled: shouldEnable } }));
  const onAudioEnabled = (playerId: string, shouldEnable: boolean) =>
    dispatch(setVideoState({ playerId, data: { isAudioEnabled: shouldEnable } }));

  async function getDevices(skipPermissionCheck: boolean) {
    const devices = await AgoraRTC.getDevices(skipPermissionCheck);
    const microphones = devices.filter((device) => device.kind === 'audioinput');
    const cameras = devices.filter((device) => device.kind === 'videoinput');
    dispatch(setMicrophones(convertDeviceInfos(microphones)));
    dispatch(setCameras(convertDeviceInfos(cameras)));
  }

  async function getCameras() {
    return AgoraRTC.getCameras(true);
  }

  async function getMicrophones() {
    return AgoraRTC.getMicrophones(true);
  }

  async function getRightCamera(videoConfig?: CameraVideoTrackInitConfig) {
    const cameras = await AgoraRTC.getCameras(true);
    const camera = cameras.find((camera) => camera.deviceId === chosenCamera?.deviceId);
    // const camera = cameras.find((camera) => !camera.label.startsWith('OBS Virtual Camera'));
    return {
      ...videoConfig,
      cameraId: camera?.deviceId,
    };
  }

  async function getRightMicrophone(audioConfig?: MicrophoneAudioTrackInitConfig) {
    const microphones = await AgoraRTC.getMicrophones(true);
    const microphone = microphones.find((microphone) => microphone.deviceId === chosenMicrophone?.deviceId);

    return {
      ...audioConfig,
      microphoneId: microphone?.deviceId,
    };
  }

  async function createLocalTracks(
    audioConfig?: MicrophoneAudioTrackInitConfig,
    videoConfig?: CameraVideoTrackInitConfig
  ): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const newVideoConfig = await getRightCamera(videoConfig);
    const newAudioConfig = await getRightMicrophone(audioConfig);
    // console.log(newVideoConfig.cameraId, newAudioConfig.microphoneId);
    const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks(
      newAudioConfig,
      newVideoConfig
    );
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    getDevices(false); // we have permission here already.
    return [microphoneTrack, cameraTrack];
  }

  async function join(appid: string, channel: string, token: string, uid: string) {
    if (!client) return;

    return client.join(appid, channel, token, uid).then((uid) => {
      setState(AGORA_STATE.CLIENT_JOINED);
      analytics.logEvent(analytics.EVENTS.AGORA_JOINED);
      //return publish(true);
    });
  }

  async function publishInternal(forced: boolean) {
    if (!forced && !canPublish()) return;

    setState(AGORA_STATE.STREAM_CREATING);

    const videoConfig: CameraVideoTrackInitConfig = {
      encoderConfig: (props.videoProfile as VideoEncoderConfigurationPreset) || '480p_1',
      optimizationMode: 'motion',
    };
    const [microphoneTrack, cameraTrack] = await createLocalTracks(undefined, videoConfig);

    await client!.publish([microphoneTrack, cameraTrack]);
    await microphoneTrack.setEnabled(false);
    await cameraTrack.setEnabled(false);

    // onVideoEnabled(client!.uid as string, true);
    setState(AGORA_STATE.STREAM_ENABLED);
    analytics.logEvent(analytics.EVENTS.AGORA_PERMISSION, { enabled: true });
  }

  async function unpublishInternal() {
    setState(AGORA_STATE.STREAM_CREATING);
    closeTracks();
    setLocalAudioTrack(undefined);
    setLocalVideoTrack(undefined);
    await client!.unpublish();
  }

  async function publish(forced = false): Promise<void> {
    return publishInternal(forced).catch((reason) => {
      dispatch(muteAll());
      setState(AGORA_STATE.STREAM_DISABLED);
      dispatch(sendAgoraError(client!.uid as string, reason?.message || 'unknown', true));
      analytics.logEvent(analytics.EVENTS.AGORA_PERMISSION, { enabled: false });
      return reason;
    });
  }

  async function setVideoEnabled(shouldEnable: boolean) {
    return localVideoTrack?.setEnabled(shouldEnable).then(() => {
      onVideoEnabled(client!.uid as string, shouldEnable);
    });
  }

  async function setAudioEnabled(shouldEnable: boolean) {
    return localAudioTrack?.setEnabled(shouldEnable).then(() => {
      onAudioEnabled(client!.uid as string, shouldEnable);
    });
  }

  function stopTracks() {
    localVideoTrack?.setEnabled(false);
    localAudioTrack?.setEnabled(false);
  }

  function resumeTracks() {
    localVideoTrack?.setEnabled(playersVideoState.isVideoEnabled);
    localAudioTrack?.setEnabled(playersVideoState.isAudioEnabled);
  }

  function closeTracks() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
  }

  async function leave() {
    closeTracks();
    setRemoteUsers([]);
    setState(AGORA_STATE.CLIENT_CREATED);
    await client?.leave();
  }

  useEffect(() => {
    const codec = 'vp8'; // 'h264'
    AgoraRTC.setLogLevel(3);
    AgoraRTC.enableLogUpload();
    const client = AgoraRTC.createClient({ codec, mode: 'rtc' });
    setClient(client);
    setState(AGORA_STATE.CLIENT_CREATED);
    getDevices(true);

    /*return () => {
      leave();
    };*/
  }, []);

  useEffect(() => {
    if (state === AGORA_STATE.STREAM_ENABLED || state === AGORA_STATE.STREAM_DISABLED) {
      dispatch(setIsAgoraLoading(true));
      unpublishInternal()
        .then(() => publish(true))
        .finally(() => dispatch(setIsAgoraLoading(false)));
    }
  }, [resetAgora]);

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      setRemoteUsers(Array.from(client.remoteUsers));
    };
    const handleStreamFallback = (uid: string, isFallbackOrRecover: 'fallback' | 'recover') => {
      dispatch(sendAgoraError(uid, isFallbackOrRecover));
    };
    const handleNetworkQuality = (stats: NetworkQuality) => {
      if (stats.downlinkNetworkQuality >= 4 || stats.uplinkNetworkQuality >= 4) {
        dispatch(
          sendAgoraError(client!.uid as string, `${stats.downlinkNetworkQuality} ${stats.uplinkNetworkQuality}`)
        );
      }
    };
    const handleException = (event: { code: number; msg: string; uid: string }) => {
      dispatch(sendAgoraError(event.uid, `${event.code} - ${event.msg}`));
    };

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on('stream-fallback', handleStreamFallback);
    client.on('network-quality', handleNetworkQuality);
    client.on('exception', handleException);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
      client.off('stream-fallback', handleStreamFallback);
      client.off('network-quality', handleNetworkQuality);
      client.off('exception', handleException);
    };
  }, [client]);

  useEffect(() => {
    if (!localVideoTrack) return;

    const handleTrackEnded = () => {
      dispatch(sendAgoraError(client!.uid as string, 'Handle track ended'));
    };

    localVideoTrack.on('track-ended', handleTrackEnded);

    return () => {
      localVideoTrack.off('track-ended', handleTrackEnded);
    };
  }, [localVideoTrack]);

  useEffect(() => {
    if (state >= AGORA_STATE.CLIENT_JOINED) {
      const videoStates = remoteUsers.reduce((previous, user) => {
        return {
          ...previous,
          [user.uid]: {
            isVideoEnabled: user.hasVideo,
            isAudioEnabled: user.hasAudio,
          },
        };
      }, {});
      dispatch(setVideoStates(videoStates));
    }
  }, [remoteUsers, state]);

  return {
    localAudioTrack,
    localVideoTrack,
    state,
    leave,
    join,
    publish,
    stopTracks,
    resumeTracks,
    remoteUsers,
    setVideoEnabled,
    setAudioEnabled,
    // getCameras,
    // getMicrophones,
    getRightCamera,
    getRightMicrophone,
  };
}

//(window as any).client = client;
//(window as any).videoTrack = cameraTrack;
