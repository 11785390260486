import _ from 'lodash';
import { MixProps } from '@src/common/generics';
import { styles } from './CardField.styles';
import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { ActionsType } from '../../standardSession.hook';
import { CardPlacement, SlotInfo } from '../../standardSession.types';
import { useEffect, useRef, useState } from 'react';
import { Aspect, aspectToStyle, defaultAspect } from '../cardMath';
import { Droppable } from 'react-beautiful-dnd';
import { SlotCard } from '../Card/Card';
import { useAppSelector } from '@src/app/hooks';
import { selectIsClient, selectIsDoctor } from '@src/store/room/roomSlice';

interface SlotProps {}

const Slot: React.FC<MixProps<SlotProps>> = ({ style, className, children }) => {
  const classes = styles();

  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

interface TableSlotProps extends SlotProps {
  cards: TableCard[];
  actions: ActionsType;
  slotInfo: SlotInfo;
  disableDrop: boolean;
  slotAspect: Aspect;
}

const TableSlot: React.FC<MixProps<TableSlotProps>> = ({
  cards,
  actions,
  slotInfo,
  slotAspect,
  className,
  disableDrop,
}) => {
  const slotRef = useRef<HTMLDivElement>(null);
  const isClient = useAppSelector(selectIsClient);
  // const [aspect, setAspect] = useState<Aspect>(defaultAspect);

  /*useEffect(() => {
    if (slotRef.current) {
      setAspect({ height: slotRef.current.clientHeight, width: slotRef.current.clientWidth });
    }
  }, [slotAspect]);*/

  const lastCard = _.last(cards);
  const index = cards.length - 1;
  const hasCards = cards.length > 0;

  const isFirstCardWord = Boolean(_.first(cards)?.word);
  const cardStack = isFirstCardWord ? cards.slice(1) : cards;
  const innerAspect = { height: slotAspect.height * 0.7, width: slotAspect.width * 0.7 };
  const realAspect = isFirstCardWord ? innerAspect : slotAspect;
  const lastStackCard = _.last(cardStack);
  const hiddenCard = cardStack[-2];
  const dragIndex = cards.length - 1;
  const lastIndex = cardStack.length - 1;
  const indexShift = isFirstCardWord ? 1 : 0;
  const isDropDisabled = isClient && !slotInfo.dragEnabled;
  const slotStyle = aspectToStyle(slotAspect);

  const getPlacement = (index: number) => (index === dragIndex ? CardPlacement.TABLE_TOP : CardPlacement.TABLE);

  // disable droppable if draggable is a word card!

  return (
    <Droppable droppableId={slotInfo.index.toString()} isDropDisabled={hasCards && (isDropDisabled || disableDrop)}>
      {(provided, snapshot) => {
        return (
          <div style={slotStyle} className={className}>
            <div
              style={{ height: '100%', width: '100%', display: 'grid' }}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {isFirstCardWord && (
                <SlotCard
                  key={0}
                  data={cards[0]}
                  placement={getPlacement(0)}
                  actions={actions}
                  slotInfo={slotInfo}
                  slotAspect={slotAspect}
                  index={0}
                  isVisible={true}
                />
              )}
              {cardStack.map((card, index) => (
                <SlotCard
                  key={index}
                  data={card}
                  placement={getPlacement(index + indexShift)}
                  actions={actions}
                  slotInfo={slotInfo}
                  slotAspect={realAspect}
                  index={index + indexShift}
                  isVisible={index === lastIndex || (index === lastIndex - 1 && Boolean(snapshot.draggingFromThisWith))}
                />
              ))}
              {/*<span style={{ margin: 'auto', gridRow: 1, gridColumn: 1 }}>{provided.placeholder}</span>*/}
              {provided.placeholder}
            </div>
          </div>
        );
      }}
    </Droppable>
  );
};

export { TableSlot };
