import { Theme, makeStyles } from '@material-ui/core';
import toggleVideoToBigIcon from '@pc/assets/icons/toggle-video-to-big.svg';
import pandaColors from '@src/_pandacards/helpers/colors';
import videoOff from '@pc/assets/images/video-off.svg';
import micOffIcon from '@pc/assets/icons/mic-off.svg';

export const styles = makeStyles((theme: Theme) => ({
  PersonItem: {
    marginBottom: '20px',

    '&.PersonItemBig': {
      position: 'absolute',
      zIndex: 2,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      flexGrow: 1,
      marginBottom: 0,

      '& .PersonWrapper': {
        flexGrow: 1,
      },

      '& .PersonVideoStream': {
        flexGrow: 1,
        height: '100%',
      },
    },
  },

  PersonItem__wrapper: {
    position: 'relative',
    zIndex: 1,

    // resize toggle
    '& > i': {
      position: 'absolute',
      zIndex: 2,
      right: '10px',
      bottom: '10px',
      display: 'block',
      width: '36px',
      height: '36px',
      cursor: 'pointer',
      '& path': {
        transition: 'all 0.23s',
      },

      '&:hover': {
        '& path': {
          fill: pandaColors.primary.gold,
        },
      },
    },
  },

  // mute audio icon
  PersonItem__muteAudio: {
    position: 'absolute',
    zIndex: 2,
    top: '10px',
    right: '10px',
    display: 'block',
    width: '24px',
    height: '24px',
    borderRadius: '2px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${micOffIcon})`,
  },

  PersonItem__videoPlaceholder: {
    flexShrink: 0,
    width: '240px',
    height: '240px',
    borderRadius: '10px',
    marginBottom: '20px',
    backgroundColor: pandaColors.black._650,
    backgroundImage: `url(${videoOff})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  bigMute: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '10px',
    zIndex: 2,
    background: 'rgba(0, 0, 0)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    '& img': {
      width: '30px',
      height: '40px',
      alignSelf: 'center',
      [theme.breakpoints.up('sm')]: {
        width: '60px',
        height: '80px',
      },
    },
  },
}));
