import { getIsWsConnected } from '@store/reducers/common';
import { Typography, Backdrop } from '@material-ui/core';
import { styles } from './styles';
import { ReactComponent as Loading } from '@src/art/icons/loading.svg';
import { useAppSelector } from '@src/app/hooks';

const ConnectToServerLoader: React.FC = () => {
  const classes = styles();
  const isWsConnected = useAppSelector(getIsWsConnected);

  return (
    <Backdrop className={classes.backdrop} open={!isWsConnected}>
      <Loading />
      <Typography>Please wait, we’re loading...</Typography>
    </Backdrop>
  );
};

export default ConnectToServerLoader;
