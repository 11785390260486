import { useAppSelector } from '@app/hooks';
import { selectAgora } from '@store/room/roomSlice';
import { selectPlayerId } from '@store/reducers/player.reducer';
import { useEffect } from 'react';
import { useAgoraInternal } from './useAgoraInternal';
import { IdType } from '@shared/generics';
import { AGORA_STATE } from './agora.types';
import { selectIsAudioEnabled, selectIsVideoEnabled } from '@store/reducers/playerData.reducer';

const useAgora = (shouldJoin: boolean, roomId: IdType) => {
  const playerId = useAppSelector(selectPlayerId);
  const agoraData = useAppSelector(selectAgora);
  const agora = useAgoraInternal({ ...agoraData });
  const canJoin = agora.state === AGORA_STATE.CLIENT_CREATED;
  const shouldEnableAudio = useAppSelector(selectIsAudioEnabled);
  const shouldEnableVideo = useAppSelector(selectIsVideoEnabled);

  useEffect(() => {
    if (shouldJoin && canJoin && agoraData) {
      agora.join(agoraData.appId, roomId, agoraData.token, playerId);
    }
  }, [shouldJoin, canJoin, agoraData]);

  useEffect(() => {
    const shouldEnable = shouldEnableAudio || shouldEnableVideo;
    // agora.state === AGORA_STATE.STREAM_DISABLED &&
    if (shouldEnable) {
      agora.publish();
    }
  }, [shouldEnableAudio, shouldEnableVideo, agora.state]);

  useEffect(() => {
    if (agora.state === AGORA_STATE.STREAM_ENABLED) {
      agora.setAudioEnabled(shouldEnableAudio);
    }
  }, [shouldEnableAudio, agora.state]);

  useEffect(() => {
    if (agora.state === AGORA_STATE.STREAM_ENABLED) {
      agora.setVideoEnabled(shouldEnableVideo);
    }
  }, [shouldEnableVideo, agora.state]);

  return agora;
};

export { useAgora };

/*
useEffect(() => {
    if (videoStateRequest.shouldEnableAudio === undefined) return;

    if (agora.state === AGORA_STATE.STREAM_DISABLED) {
      agora.publish();
    }
    if (agora.state === AGORA_STATE.STREAM_ENABLED) {
      agora.setAudioEnabled(videoStateRequest.shouldEnableAudio);
    }
    dispatch(setVideoStateRequest({ shouldEnableAudio: undefined }));
  }, [videoStateRequest.shouldEnableAudio, agora.state]);

  useEffect(() => {
    if (videoStateRequest.shouldEnableVideo === undefined) return;

    if (agora.state === AGORA_STATE.STREAM_DISABLED) {
      agora.publish();
    }
    if (agora.state === AGORA_STATE.STREAM_ENABLED) {
      agora.setVideoEnabled(videoStateRequest.shouldEnableVideo);
    }
    dispatch(setVideoStateRequest({ shouldEnableVideo: undefined }));
  }, [videoStateRequest.shouldEnableVideo, agora.state]);
*/
