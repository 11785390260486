const pandaColors = {
  primary: {
    blue: '#6680F7',
    blueHover: '#9985C9',
    gold: '#EFBE76',
    goldHover: '#CD8E54',
  },

  white: {
    _0: '#FFFFFF',
    _25: '#F4F6FF',
    _50: '#F2F2F2',
    _200: '#CCCCCC',
  },

  black: {
    _500: '#666666',
    _600: '#4D4D4D',
    _650: '#333333',
    _700: '#292929',
    _800: '#222222',
    _900: '#1C1C1C',
  },

  red: '#F83E3A',
};

export default pandaColors;
