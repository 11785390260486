import React from 'react';
import SocialItem from '@components/SocialItem';
import { loginSocialItems } from '@src/common/constants/loginSocialItems';
import { useStyles } from './styles';

const LoginSocial: React.FC = () => {
  const classes = useStyles();
  const { loginSocial, whiteBox, socialList } = classes;

  return (
    <div className={loginSocial}>
      <div className={whiteBox}>
        <div className={socialList}>
          {loginSocialItems.map((item, index) => (
            <SocialItem
              key={index}
              type={item.type}
              icon={item.icon}
              iconSize={item.iconSize}
              iconPosition={item.iconPosition}
              onClick={() => alert(`${item.type} onClick event`)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoginSocial;
