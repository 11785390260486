import React from 'react';
import SocialCircleItem from '@components/SocialCircleItem';
import { loginSocialCirleItems } from '@src/common/constants/loginSocialCirleItems';
import { useStyles } from './styles';

const SocialList: React.FC = () => {
  const classes = useStyles();

  return (
    <ul className={classes.socialList}>
      {loginSocialCirleItems.map((item, index) => (
        <SocialCircleItem key={index} type={item.type} icon={item.icon} backgroundColor={item.backgroundColor} />
      ))}
    </ul>
  );
};

export default SocialList;
