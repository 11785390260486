import { CardDeck } from '@src/graphql/queries';
import { IdType } from '@src/shared/generics';

export const getAvatarLink = (link: string) => `${link}avatar.png`;

export const getCardFrontLink = (link: string) => `${link}cardFace.png`;

export const getCardBackLink = (link: string) => `${link}cardBack.png`;

export const getCardLink = (link: string, index: number) => `${link}card${index}.png`;

export const getDeckTextLink = (link: string) => `${link}words.txt`;

export const getDeck = (decks: CardDeck[], deckId: IdType) => decks.find((deck) => deck.id == deckId);

export const getChosenDecks = (decks: CardDeck[], deckIds: IdType[]) =>
  decks.filter((deck) => deckIds.includes(deck.id));
