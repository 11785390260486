import { useEffect, useRef, useState } from 'react';
import { Button, CommonModal } from '@pc/components/ui';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { IdType } from '@src/shared/generics';
import { Arrow, CloseIcon } from '@pc/components/icons';
import pandaColors from '@src/_pandacards/helpers/colors';
import { TableCard, TakeCardData, UsedDeck } from '@src/shared/sessionInterfaces/standardSession.socket';
import { styles } from './CardSwapperMobile.styles';
import { useDeckCards } from '../../modals/TakeCards/TakeCardsOpenedModal/useDeckCards';
import { SimpleCard } from '../Card/Card';
import { CardPlacement } from '../../standardSession.types';
import { Aspect, defaultAspect } from '../cardMath';
import { useAppSelector } from '@src/app/hooks';
import { selectCardBack } from '@src/store/room/roomFeaturesData.slice';

type TProps = {
  isOpen: boolean;
  takeCard: TakeCardData;
  onCardTaken: (deckId: IdType | undefined, index: number, faceUp?: boolean) => void;
};

const CardSwapperMobile: React.FC<TProps> = ({ isOpen, takeCard, onCardTaken }) => {
  const classes = styles();
  const cards = takeCard.faceUp ? useDeckCards(takeCard.taken, takeCard.deckId) : [];
  const [slidePosition, setSlidePosition] = useState<number>(0);
  const SwipeableViewsVirtualize = virtualize(SwipeableViews);
  const slotRef = useRef<HTMLDivElement>(null);
  const [aspect, setAspect] = useState<Aspect>(defaultAspect);

  const cardBack = useAppSelector(selectCardBack);
  const cardData: TableCard = { cardId: '', cardBack, cardFace: '', faceUp: false };

  const onCardTakenClick = () => {
    onCardTaken(takeCard.deckId, slidePosition, true);
  };

  const onCardNotTakenClick = () => {
    onCardTaken(undefined, 0);
  };

  const rotateLeft = () => setSlidePosition(slidePosition <= 0 ? cards.length - 1 : slidePosition - 1);
  const rotateRight = () => setSlidePosition(slidePosition + 1 < cards.length ? slidePosition + 1 : 0);

  useEffect(() => {
    if (slotRef.current) {
      setAspect({ height: slotRef.current.clientHeight, width: slotRef.current.clientWidth });
    }
  }, [slotRef.current]);

  return (
    <div className={classes.TakeCardsOpenedModal}>
      <i className={classes.TakeCardsOpenedModal__close} onClick={onCardNotTakenClick}>
        <CloseIcon color={pandaColors.black._500} />
      </i>

      <h3>
        Выберите карту <span>{takeCard.takenAmount + 1}</span>/<span>{takeCard.amount}</span>
      </h3>

      <section className={classes.TakeCardsOpenedModal__slider}>
        {/* Слайдер с виртуальным списком */}
        <div className={classes.TakeCardsOpenedModal__sliderWrapper} ref={slotRef}>
          {cards.length > 0 && aspect.height > 0 && (
            <SwipeableViewsVirtualize
              axis="x"
              index={slidePosition}
              slideCount={cards.length}
              className={classes.TakeCardsOpenedModal__sliderVirtualList}
              onChangeIndex={(slidePosition) => setSlidePosition(slidePosition)}
              slideRenderer={({ index, key }) => (
                <div key={key} style={{ height: '100%', width: '100%', margin: 'auto' }}>
                  <SimpleCard data={cards[index]} placement={CardPlacement.MODAL} slotAspect={aspect} />
                </div>
              )}
            />
          )}

          {!takeCard.faceUp && aspect.height > 0 && (
            <div style={{ height: '100%', width: '100%', margin: 'auto' }}>
              <SimpleCard data={cardData} placement={CardPlacement.MODAL} slotAspect={aspect} />
            </div>
          )}
        </div>

        {cards.length > 1 && (
          <div className={classes.TakeCardsOpenedModal__sliderControls}>
            <i onClick={rotateLeft}>
              <Arrow />
            </i>
            <i onClick={rotateRight}>
              <Arrow direction="right" />
            </i>
          </div>
        )}
      </section>

      {/* Взять */}
      <Button type="blue" onClick={onCardTakenClick}>
        Взять
      </Button>
    </div>
  );
};

export { CardSwapperMobile };
