import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { CardPlacement, SlotInfo } from '../../standardSession.types';
import { useEffect, useState } from 'react';
import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';

export enum TooltipMenuLink {
  ZOOM_IN = 'zoom-in',
  ZOOM_OUT = 'zoom-out',
  SAVE = 'save',
  FLIP = 'flip',
  ENTER_WORD = 'enter-word',
  ALLOW_COVER = 'allow-cover',
  DISALLOW_COVER = 'disallow-cover',
  REMOVE = 'remove',
  REMOVE_ALL = 'remove-all',
  WITHDRAW = 'withdraw',
}

const getModalLinks = () => [] as TooltipMenuLink[];
const getDiscardLinks = () => [TooltipMenuLink.WITHDRAW];
const getEnlargedLinks = (isDoctor: boolean) => {
  const links = [TooltipMenuLink.ZOOM_OUT];

  if (!isDoctor) {
    links.push(TooltipMenuLink.SAVE);
  }

  return links;
};

const getTableLinks = (card: TableCard, isDoctor: boolean): TooltipMenuLink[] => {
  const links = [] as TooltipMenuLink[];

  if (isDoctor && card.word !== undefined) {
    links.push(TooltipMenuLink.ENTER_WORD);
  }

  return links;
};

const getTableTopLinks = (card: TableCard, isDoctor: boolean, slotInfo: SlotInfo): TooltipMenuLink[] => {
  const links = [TooltipMenuLink.ZOOM_IN, TooltipMenuLink.FLIP];

  if (isDoctor) {
    if (card.word !== undefined) {
      links.push(TooltipMenuLink.ENTER_WORD);
    }

    links.push(slotInfo.dragEnabled ? TooltipMenuLink.DISALLOW_COVER : TooltipMenuLink.ALLOW_COVER);
    links.push(TooltipMenuLink.REMOVE);
    links.push(TooltipMenuLink.REMOVE_ALL);
  }

  if (!isDoctor) {
    links.push(TooltipMenuLink.SAVE);
  }

  return links;
};

const getLinks = (card: TableCard, placement: CardPlacement, isDoctor: boolean, slotInfo?: SlotInfo) => {
  switch (placement) {
    case CardPlacement.TABLE:
      return getTableLinks(card, isDoctor);
    case CardPlacement.TABLE_TOP:
      return getTableTopLinks(card, isDoctor, slotInfo!);
    case CardPlacement.DISCARD:
      return getDiscardLinks();
    case CardPlacement.MODAL:
      return getModalLinks();
    case CardPlacement.ENLARGED:
      return getEnlargedLinks(isDoctor);
  }
};

const useCardActions = (card: TableCard, placement: CardPlacement, slotInfo?: SlotInfo) => {
  const [links, setLinks] = useState<TooltipMenuLink[]>([]);
  const isDoctor = useAppSelector(selectIsDoctor);

  useEffect(() => {
    const links = getLinks(card, placement, isDoctor, slotInfo);
    setLinks(links);
  }, [card, placement, slotInfo]);

  return links;
};

export { useCardActions };
