import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { createSelector } from 'reselect';
import { createSimpleReducer } from '@store/reduxHelpers';
import { Avatar, Background, Sticker } from '@shared/misc/roomFeatures.types';
import { StickerSentServerEvent } from '@shared/socketEvents/socketEvents';
import { INotification, NOTIFICATION_TYPE } from './notifications';
import _, { create } from 'lodash';

export interface StickerAction extends Omit<StickerSentServerEvent, 'roomId'> {}

const notificationPredicate = (payload: INotification) => (notification: INotification) => {
  if (notification.type !== payload.type) return false;

  if (notification.type === NOTIFICATION_TYPE.PUBLICITY) return true;

  // @ts-ignore
  if (notification.type === NOTIFICATION_TYPE.QUEST && notification.questId === payload.questId) return true;

  return false;
};

const initialState = {
  avatars: [] as Avatar[],
  backgrounds: [] as Background[],
  stickers: [] as Sticker[],
  stickerActions: [] as StickerAction[],
  roomIcons: [] as string[],
  roomIcon: '' as string,
  notifications: [] as INotification[],
  playingNotification: undefined as INotification | undefined,
  cardBack: '' as string,
};

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;

export const roomFeaturesSlice = createSlice({
  name: 'roomFeatures',
  initialState,
  reducers: {
    setStickers: createSimpleReducer('stickers'),
    setAvatars: createSimpleReducer('avatars'),
    setBackgrounds: createSimpleReducer('backgrounds'),
    setRoomIcons: createSimpleReducer('roomIcons'),
    setRoomIcon: createSimpleReducer('roomIcon'),
    setCardBack: createSimpleReducer('cardBack'),
    pushStickerAction: (state, action: PayloadAction<StickerAction>) => {
      state.stickerActions.push(action.payload);
    },
    popStickerAction: (state) => {
      state.stickerActions.shift();
    },
    pushNotification: (state, action: PayloadAction<INotification>) => {
      const shouldReplace = [
        NOTIFICATION_TYPE.PUBLICITY,
        NOTIFICATION_TYPE.QUEST,
        NOTIFICATION_TYPE.COPY_LINK,
        NOTIFICATION_TYPE.COPY_ID,
      ].includes(action.payload.type);
      if (shouldReplace) {
        const index = state.notifications.findIndex(notificationPredicate(action.payload));
        if (index !== -1) {
          state.notifications[index] = action.payload;
          return;
        }
      }

      state.notifications.push(action.payload);
    },
    popNotification: (state) => {
      const notification = state.notifications.shift();
      // state.playingNotification = notification;
    },
    cleanNotification: (state) => {
      state.playingNotification = undefined;
    },
  },
});

export const {
  setStickers,
  setAvatars,
  setBackgrounds,
  setRoomIcons,
  setCardBack,
  pushStickerAction,
  popStickerAction,
  pushNotification,
  popNotification,
  cleanNotification,
} = roomFeaturesSlice.actions;

const selectState = (state: RootState) => state.roomFeatures || initialState;

export const selectStickers = createSelector(selectState, (state) => _.sortBy(state.stickers, ['price']));
export const selectAvatars = createSelector(selectState, (state) => state.avatars);
export const selectBackgrounds = createSelector(selectState, (state) => state.backgrounds);
export const selectStickerAction = createSelector(selectState, (state) => state.stickerActions[0]);
export const selectRoomIcons = createSelector(selectState, (state) => state.roomIcons);
export const selectCardBack = createSelector(selectState, (state) => state.cardBack);
export const selectNotification = createSelector(selectState, (state) => state.notifications[0]);
export const selectPlayingNotification = createSelector(selectState, (state) => state.playingNotification);

export default roomFeaturesSlice;
