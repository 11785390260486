import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import checkIcon from '@art/icons/checkbox.svg';

export const styles = makeStyles((theme) => ({
  radioCustom: {
    fontFamily,
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: 0,
    },
    '& *': {
      boxSizing: 'border-box',
    },

    // checked input - labes styles
    '& input:checked + label::before': {
      backgroundImage: `url(${checkIcon})`,
      backgroundColor: '#2C74F3',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
  },

  radioCustomLabel: {
    position: 'relative',
    display: 'inline-flex',
    paddingLeft: '28px',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '21px',
    color: '#1B2036',
    cursor: 'pointer',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      marginTop: '-10px',
      left: 0,
      display: 'block',
      width: '20px',
      height: '20px',
      backgroundColor: '#FFF',
      borderRadius: '50%',
      border: '1px solid #E8E9EB',
      boxSizing: 'border-box',
      transitionProperty: 'background-color',
      transitionDuration: '.23s',
    },
  },
}));
