import { fontFamily } from '@src/art/theme/textStyles';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    whiteBackground: {
      display: 'block',
      position: 'sticky',
      bottom: 0,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      [theme.breakpoints.up('sm')]: {
        flexBasis: '40%',
      },
    },

    wrapperButton: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    ownerText: {
      fontFamily: fontFamily,
      backgroundColor: '#F3F4F5',
      borderRadius: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      minHeight: '42px',
      padding: '5px 15px',

      '& > span': {
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '17px',
        color: '#1B2036',
      },
      '& > img': {
        width: '21px',
        heigth: 'auto',
        marginLeft: '3px',
        marginRight: '3px',
        marginTop: '-3px',
      },
    },
    blueButton: {
      display: 'flex',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#FFFFFF',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
      padding: '10px 23px',
      background: 'rgba(44, 116, 243, 1)',
      borderRadius: '30px',
      margin: 'auto',
      border: '0',
      outline: 'none',
      cursor: 'pointer',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      '&:hover': {
        transform: 'scale(1.02)',
      },
      transition: '0.2s ease-out',
      [theme.breakpoints.up('sm')]: {
        // padding: '9px 30px',
        fontSize: '16px',
        lineHeight: '19px',
        justifyContent: 'center !important',
      },
    },
    violetteButton: {
      display: 'flex',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      textAlign: 'center',
      color: '#FFFFFF',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
      padding: '9px 20px',
      borderRadius: '30px',
      border: '0',
      outline: 'none',
      cursor: 'pointer',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      transition: '0.2s ease-out',
      background: 'linear-gradient(77.61deg, #8E78FF 0%, #FC7D7B 100%)',
      [theme.breakpoints.up('sm')]: {
        padding: '9px 55px',
        justifyContent: 'center !important',
      },
      '&.mb-15': {
        marginBottom: '15px',
      },
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
    blueButtonHomePage: {
      padding: '9px 42px',
      width: 'unset',
      flexShrink: 0,
      marginRight: '15px',
    },
  })
);
