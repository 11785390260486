import { getRoomId } from '@store/room/roomSlice';
import { gql } from '@apollo/client';
import { AppDispatch, AppGetState } from '@src/app/store';
import { mutateGraphql } from '@src/library/fetch';
import { IdType } from '@src/shared/generics';
import { IRoom, roomFragment } from '@src/shared/misc/room.fragment';
import { selectPlayerId, setRoomIcon, updatePlayerAvatar } from '@src/store/reducers/player.reducer';

export const postClientLog = gql`
  mutation PostClientLog($userId: String!, $tag: String, $message: String) {
    postClientLog(userId: $userId, tag: $tag, msg: $message)
  }
`;

export const postTap = gql`
  mutation postTap($type: Int!, $x: Int!, $y: Int!) {
    postTap(tap: { type: $type, x: $x, y: $y })
  }
`;

export const ratePartyMutation = gql`
  mutation rateParty($partyId: ID!, $playerId: ID!, $rate: Int!) {
    rateParty(partyId: $partyId, playerId: $playerId, rate: $rate)
  }
`;

export const setTutorialSeen = gql`
  mutation setTutorialSeen($playerId: ID!, $bannerId: ID!) {
    setTutorialSeen(playerId: $playerId, bannerId: $bannerId)
  }
`;

export const updatePlayerNameMutation = gql`
  mutation updatePlayerName($playerId: ID, $name: String!) {
    updatePlayer(publicId: $playerId, playerData: { name: $name }) {
      autocreate
    }
  }
`;

export const updatePlayerAvatarMutation = gql`
  mutation updatePlayerAvatar($playerId: ID!, $avatarId: String!) {
    updatePlayer(publicId: $playerId, playerData: { avatarId: $avatarId }) {
      avatarId
    }
  }
`;

export type AvatarMutationParams = {
  playerId: IdType;
  avatarId: IdType;
};

export type AvatarMutationResponse = {
  updatePlayer: {
    avatarId: IdType;
  };
};

export const requestAvatarUpdate = (avatarId: IdType) => (dispatch: AppDispatch, getState: AppGetState) => {
  const playerId = selectPlayerId(getState());

  return mutateGraphql<AvatarMutationParams, AvatarMutationResponse>(updatePlayerAvatarMutation, {
    playerId,
    avatarId,
  }).then((data) => {
    if (data.updatePlayer) {
      dispatch(updatePlayerAvatar(data.updatePlayer.avatarId));
    }
  });
};

export const updateRoomIconMutation = gql`
  mutation updateRoomIcon($playerId: ID!, $icon: String) {
    updateRoom(playerId: $playerId, roomData: { icon: $icon }) {
      icon
    }
  }
`;

export type RoomIconMutationParams = {
  playerId: IdType;
  icon: string;
};

export type RoomIconMutationResponse = {
  updateRoom: {
    icon: string;
  };
};

export const requestRoomIconChange = (icon: string) => (dispatch: AppDispatch, getState: AppGetState) => {
  const playerId = selectPlayerId(getState());

  return mutateGraphql<RoomIconMutationParams, RoomIconMutationResponse>(updateRoomIconMutation, {
    playerId,
    icon,
  }).then((data) => {
    if (data.updateRoom) {
      dispatch(setRoomIcon(data.updateRoom.icon));
    }
  });
};

export enum CLAIM_RESPONSE {
  OK = 'OK',
  FAILED = 'FAILED',
  UNKNOWN = 'UNKNOWN',
}

export type ClaimRewardParams = {
  playerId: IdType;
  wallet: IdType;
  amount: number;
};

export type ClaimRewardResponse = {
  claimReward: CLAIM_RESPONSE;
};

export const claimReward = gql`
  mutation claimReward($playerId: ID!, $wallet: ID!, $amount: Int!) {
    claimReward(playerId: $playerId, wallet: $wallet, amount: $amount)
  }
`;

export const addRewardDebug = gql`
  mutation addRewardDebug($playerId: ID!, $amount: Float!) {
    addRewardDebug(playerId: $playerId, amount: $amount)
  }
`;

export const addEnergyDebug = gql`
  mutation addEnergyDebug($playerId: ID!, $amount: Int!, $isBonus: Boolean!) {
    addEnergyDebug(playerId: $playerId, amount: $amount, isBonus: $isBonus)
  }
`;

export const createRoomMutation = gql`
  mutation createRoom($roomData: RoomInput!) {
    createRoom(roomData: $roomData) {
      ...RoomFragment
    }
  }
  ${roomFragment}
`;

export interface CreateRoomResponse {
  createRoom: IRoom;
}
