import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    // input
    root: {
      width: '100%',
      border: 0,
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },

      '& label.Mui-error': {
        // color: 'pink',
      },
      '& label.Mui-error.Mui-focused': {
        color: '#F98593',
      },

      // label focused
      '& label.Mui-focused': {
        color: '#2C74F3',
        transform: 'translate(14px, -8px) scale(0.75)',
      },

      '& label.MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },

      // out-focus
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E8E9EB',
          borderWidth: '1px',
          '& legend span': {
            fontSize: '0.9em',

            [theme.breakpoints.up('sm')]: {
              fontSize: '0.75em',
            },

            [theme.breakpoints.up('md')]: {
              fontSize: '0.7em',
            },
          },
        },

        // hover
        '&:hover fieldset': {
          borderColor: '#BBBCC3',
          borderWidth: '1px',
        },

        // focus
        '&.Mui-focused fieldset': {
          borderColor: '#2C74F3',
          borderWidth: '1px',
        },

        // focus with error
        '&.Mui-focused.Mui-error fieldset': {
          borderColor: '#F98593',
          borderWidth: '1px',
        },
      },
    },

    // lable in outfocus
    lable: {
      fontSize: '14px',
      lineHeight: '21px',
      color: '#BBBCC3',
      transform: 'translate(14px, 12px) scale(1)',
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },

    input: {
      // padding: '14.5px 14px',
      height: '48px',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#1B2036',
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        height: '44px',
      },
    },

    formHelperEmail: {
      display: 'flex',
      justifyContent: 'flex-start',
      margin: 0,
      marginBottom: '0 !important',
      paddingLeft: '15px',
    },
  })
);
