import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';
import videoOff from '@pc/assets/images/video-off.svg';

export const styles = makeStyles(() => ({
  PersonVideo: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
  },

  PersonVideo__stream: {
    width: '240px',
    height: '240px',
    overflow: 'hidden',
    borderRadius: '10px',
    // backgroundColor: pandaColors.black._600,
    // backgroundRepeat: 'no-repeat',
    // backgroundImage: `url(${videoOff})`,
  },

  PersonVideo__muteVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${videoOff})`,
    backgroundColor: pandaColors.black._650,
  },
}));
