import { makeStyles } from '@material-ui/core/styles';
import pandaColors from '@src/_pandacards/helpers/colors';
// import pandaColors from '@src/_pandacards/helpers/colors';

const { primary, black, white } = pandaColors;

export const styles = makeStyles(() => ({
  Button: {
    outline: 'none',
    border: 'none',
    fontSize: '20px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    padding: '12px 36px',
    textTransform: 'uppercase',
    borderRadius: '40px',
    color: black._900,
    transition: 'all 0.23s',
    '&.gold': {
      backgroundColor: primary.gold,
      '&:hover': {
        backgroundColor: primary.goldHover,
      },
      '&.outlined': {
        backgroundColor: 'transparent',
        border: `1px solid ${primary.gold}`,
        color: primary.gold,
        '&:hover': {
          backgroundColor: 'transparent',
          border: `1px solid ${primary.goldHover}`,
          color: primary.goldHover,
        },
      },
    },
    '&.blue': {
      color: white._25,
      backgroundColor: primary.blue,
      '&:hover': {
        backgroundColor: primary.blueHover,
      },
    },
    '&.white': {
      color: black._500,
      backgroundColor: white._0,
      border: `1px solid ${black._500}`,
      '&:hover': {
        backgroundColor: white._25,
      },
    },

    '&:hover': {
      cursor: 'pointer',
    },

    // disabled
    '&[disabled]': {
      pointerEvents: 'none',
      cursor: 'default',
      opacity: 0.5,
    },
  },
}));
