import { gql } from '@apollo/client';
import { LOCALE } from '@shared/common';

export type IString = {
  [K in LOCALE]: string;
};

export const stringFragment = gql`
  fragment StringFragment on LString {
    EN
    RU
  }
`;
