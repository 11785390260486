import { Theme, createStyles, makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';

export interface StylesProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const styles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    CommonModal: {
      boxSizing: 'border-box',
      display: 'flex',
      '& *': {
        boxSizing: 'border-box',
      },

      left: '280px', // (props) => `${props.left}px`,
      right: (props) => `${props.right}px`,
      bottom: (props) => `${props.bottom}px`,
      top: (props) => `${props.top}px`,

      // overlayTransparent
      '& .MuiBackdrop-root': {
        backgroundColor: 'transparent',
        left: (props) => `${props.left}px`,
        right: (props) => `${props.right}px`,
        bottom: (props) => `${props.bottom}px`,
        top: (props) => `${props.top}px`,
      },

      /*'& .isFullscreen': {
        '& .MuiBackdrop-root': {
          left: 0,
        }
      },*/

      border: 'none !important',
      outline: 'none !important',
      boxShadow: 'none !important',

      // client mobile
      '&.isClient': {
        [theme.breakpoints.down('xs')]: {
          left: '0px !important',

          '& .CommonModal__backdrop': {
            paddingBottom: '30px',
          },

          '& .CommonModal__modal': {
            alignSelf: 'flex-end',
            minWidth: 'calc(100vw - 30px)',
            width: 'calc(100vw - 30px)',
            // minHeight: '650px',
            maxHeight: 'none',
            animationName: 'appearFromBottom',
            animationDuration: '.23s',
          },
        },
      },
    },

    CommonModal__backdrop: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      border: 'none !important',
      outline: 'none !important',
      boxShadow: 'none !important',
    },

    CommonModal__modal: {
      borderRadius: '20px',
      background: 'white',
      alignSelf: 'center',
      flexDirection: 'column',
      position: 'relative',
      maxWidth: '90%',
      maxHeight: '90%',
      overflow: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',

      // y-scrollbar track width / height
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },

      // scrollbar track background (полоска)
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      // scrollbar thumb (бегунок)
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: pandaColors.black._600,
        borderRadius: '8px',
      },
    },
  })
);
