import { useAppSelector } from '@src/app/hooks';
import { createContext, useContext, useEffect, useState } from 'react';
import protobuf from 'protobufjs';
import file from '@shared/proto/data/draw.proto';

interface IGlobalContext {
  protobuf: protobuf.Root;
}

const GlobalContext = createContext<IGlobalContext>({ protobuf: new protobuf.Root() });

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  return context;
  // return { ...context };
};

export const useProtobuf = () => {
  const context = useGlobalContext();
  return context.protobuf;
};

export const GlobalContextProvider: React.FC = ({ children }) => {
  const [protobufRoot, setProtobufRoot] = useState<protobuf.Root | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const root = await protobuf.load(file);
      setProtobufRoot(root);
    })();
  }, []);

  const value = { protobuf: protobufRoot! };

  return <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>;
};
