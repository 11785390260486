import { getIsWsConnected } from '@store/reducers/common';
import { Typography, Backdrop } from '@material-ui/core';
import { ReactComponent as Loading } from '@src/art/icons/loading.svg';
import { useAppSelector } from '@src/app/hooks';
import cn from 'classnames';
import { styles } from './Loader.styles';
import pandaColors from '@src/_pandacards/helpers/colors';

type TLoaderProps = {
  color?: string;
  text?: string;
};

const createArray = (length: number) => Array.from(Array(length).keys());

export const LoaderWsConnect = ({ color = pandaColors.primary.blue, text = 'Загрузка ...' }: TLoaderProps) => {
  const classes = styles();
  const isWsConnected = useAppSelector(getIsWsConnected);

  return (
    <Backdrop className={classes.backdrop} open={!isWsConnected}>
      <div className={cn(classes.Loader, classes.Loader__type2)}>
        {createArray(12).map((i) => (
          <div key={i} style={{ backgroundColor: `${color} ` }} />
        ))}
      </div>

      <Typography className={classes.text}>{text}</Typography>
    </Backdrop>
  );
};

type TSimpleLoaderProps = {
  color?: string;
};

export const SimpleLoader: React.FC<TSimpleLoaderProps> = ({ color = pandaColors.primary.blue }) => {
  const classes = styles();

  return (
    <div className={cn(classes.Loader, classes.Loader__type2)}>
      {createArray(12).map((i) => (
        <div key={i} style={{ backgroundColor: `${color} ` }} />
      ))}
    </div>
  );
};
