import React from 'react';
import { Typography, Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextInput from '@components/Inputs/TextInput';
import PasswordInput from '@components/Inputs/PasswordInput';
import { useStyles } from './styles';

const LoginForm: React.FC = () => {
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    showPassword: false,
    errorEmail: false,
    errorPassword: false,
    buttonClicked: false,
  });

  const handleChangeEmail = (event: any) => {
    setValues({
      ...values,
      buttonClicked: false,
      email: event.target.value,
    });
  };

  const handleChangePassword = (prop: any) => (event: any) => {
    setValues({
      ...values,
      buttonClicked: false,
      [prop]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const classes = useStyles();
  const { loginForm, forgotPassword, submitButton } = classes;
  const { email, errorEmail, password, errorPassword, showPassword, buttonClicked } = values;

  const submitForm = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setValues({ ...values, buttonClicked: true });
    alert('Form submited!');
  };

  return (
    <form className={loginForm} noValidate autoComplete="off" method="POST" onSubmit={submitForm}>
      {/* EMAIL */}
      <TextInput
        id="login-email"
        label="Email"
        variant="outlined"
        type="email"
        error={errorEmail}
        value={email}
        onChange={handleChangeEmail}
      />

      {/* PASSWORD */}
      <PasswordInput
        password={password}
        showPassword={showPassword}
        handleChangePassword={handleChangePassword('password')}
        handleClickShowPassword={handleClickShowPassword}
        handleMouseDownPassword={handleMouseDownPassword}
        errorPassword={errorPassword}
      />

      <Typography className={forgotPassword}>
        <Link href="#">Forgot password?</Link>
      </Typography>

      <Button
        variant="contained"
        color="primary"
        className={submitButton}
        type="submit"
        disableRipple
        disabled={!email || !password || buttonClicked}
      >
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
