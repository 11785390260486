import React from 'react';
import Button from '@components/Button';
import CommonModal from '@components/Modals/CommonModal';
import { styles } from './styles';
import { useAppDispatch } from '@app/hooks';
import { exitToHomepage } from '@store/thunk/room';
import { setRoomFullShown } from '@store/reducers/popups';

interface Props {
  isOpen: boolean;
}

const FullRoomModal: React.FC<Props> = ({ isOpen }) => {
  const dispatch = useAppDispatch();
  const classes = styles();

  const handleClose = () => {
    dispatch(exitToHomepage('Room is full'));
    dispatch(setRoomFullShown(false));
  };

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      className={classes.paperSmall}
      isFullscreen={true}
      backgroundColor={'white'}
    >
      <div className={classes.fullWrapper}>
        <h3 id="transition-modal-title">Room is full</h3>

        <div className={classes.fullPicture} />

        <p>
          You cannot join the room <br /> because 5 players already joined the room.
        </p>

        <Button type="primary-blue-new" text="Okay" onClick={handleClose} className={classes.button} />
      </div>
    </CommonModal>
  );
};

export default FullRoomModal;
