import { HookType } from '../../standardSession.hook';
import { TableSlot } from './Slot';
import { styles } from './CardField.styles';
import { Layout } from './useLayouts';
import { DragDropContext, DragStart, DropResult } from 'react-beautiful-dnd';
import { ITableSlot } from '../../standardSession.types';
import { useState } from 'react';
import { Aspect } from '../cardMath';

interface Props {
  hook: HookType;
  slotAspect: Aspect;
}

const CardFieldLayout: React.FC<Props> = ({ hook, slotAspect }) => {
  const classes = styles();
  const session = hook.session;
  const [isDropDisabled, setIsDropDisabled] = useState(false);

  const slotsWithCards: ITableSlot[] = session.slots.map((slot, index) => ({
    cards: slot.cards.map((cardId) => session.cards.find((card) => card.cardId === cardId)!),
    info: {
      index,
      dragEnabled: slot.dragEnabled,
    },
  }));

  const onDragStart = (start: DragStart) => {
    hook.actions.onCardDragChanged(true);

    const card = session.cards.find((card) => card.cardId === start.draggableId);
    if (card?.word) {
      setIsDropDisabled(true);
    }
  };

  const onDragEnd = ({ source, destination, draggableId }: DropResult) => {
    console.log('on drag end');

    setIsDropDisabled(false);

    // Make sure we have a valid destination (by default index is also checked)
    if (destination === undefined || destination === null || source.droppableId === destination.droppableId) {
      hook.actions.onCardDragChanged(false);
      return null;
    }

    hook.actions.onCardDragged(draggableId, Number(source.droppableId), Number(destination.droppableId));
    return null;
  };

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {slotsWithCards.map((slot) => (
        <TableSlot
          key={slot.info.index}
          cards={slot.cards}
          actions={hook.actions}
          slotInfo={slot.info}
          slotAspect={slotAspect}
          className={classes.CardField__slot}
          disableDrop={isDropDisabled}
        />
      ))}
    </DragDropContext>
  );
};

export { CardFieldLayout };
