import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import CardTooltip from './CardTooltip';
import { MixProps } from '@src/common/generics';
import { CardInternal } from './CardInternal';
import { ActionsType } from '../../standardSession.hook';
import { CardPlacement, SlotInfo } from '../../standardSession.types';
import { Aspect } from '../cardMath';
import { Draggable } from 'react-beautiful-dnd';

interface SimpleCardProps {
  data: TableCard;
  placement: CardPlacement;
  actions?: ActionsType;
  slotAspect?: Aspect;
}

const SimpleCard: React.FC<MixProps<SimpleCardProps>> = ({ data, placement, actions, slotAspect }) => {
  return (
    <div style={{ margin: 'auto', gridColumn: 1, gridRow: 1 }}>
      <CardTooltip card={data} placement={placement} actions={actions}>
        <CardInternal data={data} placement={placement} slotAspect={slotAspect} />
      </CardTooltip>
    </div>
  );
};

interface SlotCardProps {
  data: TableCard;
  placement: CardPlacement;
  actions: ActionsType;
  slotAspect: Aspect;
  slotInfo: SlotInfo;
  index: number;
  isVisible: boolean;
}

const SlotCard: React.FC<MixProps<SlotCardProps>> = ({
  data,
  placement,
  actions,
  slotAspect,
  slotInfo,
  index,
  isVisible,
}) => {
  return (
    <Draggable draggableId={data.cardId} index={index || 0} isDragDisabled={placement !== CardPlacement.TABLE_TOP}>
      {(provided, snapshot) => {
        const styleCheat = snapshot.isDragging ? provided.draggableProps.style : {};
        const visibleStyle = isVisible ? {} : { display: 'none' };
        const style = { margin: 'auto', gridColumn: 1, gridRow: 1, ...styleCheat, ...visibleStyle };
        // const style = styleCheat;

        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={style}>
            {
              <CardTooltip card={data} placement={placement} actions={actions} slotInfo={slotInfo}>
                <CardInternal data={data} placement={placement} slotAspect={slotAspect} />
              </CardTooltip>
            }
          </div>
        );
      }}
    </Draggable>
  );
};

export { SimpleCard, SlotCard };
