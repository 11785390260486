import { DeckType } from '../common';
import { IdType } from '../generics';
import { BaseSessionState } from '../socketEvents/socketEvents';

export enum STANDARD_SESSION_STATE {
  DEFAULT = 'DEFAULT',
  DEAL_CARD = 'DEAL_CARD',
  TAKE_CARD = 'TAKE_CARD',
  DRAG = 'DRAG',
  ENLARGE = 'ENLARGE',
}

export interface ISlot {
  cards: IdType[];
  dragEnabled: boolean;
}

export interface ICard {
  cardId: IdType;
  cardBack: string;
  cardFace: string;
  faceUp: boolean;
  word?: string;
}

export interface TableCard extends ICard {}
export interface DiscardedCard extends ICard {}

export interface UsedDeck {
  deckId: IdType;
  cards: number[];
}

export interface DragData {
  cardId: IdType;
  from: number;
  to: number;
}

export interface TakeCardData {
  deckId: IdType;
  amount: number;
  takenAmount: number;
  taken: number[];
  faceUp: boolean;
}

export interface StandardSessionState extends BaseSessionState {
  state: STANDARD_SESSION_STATE;
  question: string;
  slots: ISlot[];
  cards: TableCard[];
  discarded: DiscardedCard[];
  enlarged: number;

  usedCards?: UsedDeck[];
  dragging?: IdType;
  drag?: DragData;
  takeCard?: TakeCardData;
}

export interface StandardSessionData {
  data: {
    standard: StandardSessionState;
  };
}
