import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },

  root: {
    height: '48px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#1B2036',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      height: '44px',
    },

    '& .MuiMenu-list': {
      width: '100%',
    },

    '&:hover fieldset': {
      borderColor: '#BBBCC3 !important',
      borderWidth: '1px !important',
    },

    // focus
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: '#2C74F3 !important',
        borderWidth: '1px !important',
      },

      '& label': {
        color: 'red !important',
      },
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      height: '48px',
      textAlign: 'left',
      paddingTop: '14px',
      paddingBottom: '14px',
      backgroundColor: 'white',
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        height: '44px',
        paddingTop: '12px',
        paddingBottom: '12px',
      },
    },

    '& fieldset': {
      borderColor: '#E8E9EB',
    },
  },

  // lable in outfocus
  label: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#BBBCC3',
    transform: 'translate(14px, 12px) scale(1)',

    [theme.breakpoints.up('sm')]: {
      transform: 'translate(14px, 10px) scale(1)',
    },

    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -8px) scale(0.75)',
    },

    '&.Mui-focused': {
      color: '#2C73F3',
    },
  },

  selectEmpty: {
    // marginTop: theme.spacing(3),
  },
}));
