import { MixProps } from '@src/common/generics';
import React, { MouseEventHandler } from 'react';
import { styles } from './styles';

type ButtonColor =
  | 'primary-blue'
  | 'primary-blue-new'
  | 'primary-green'
  | 'primary-green-new'
  | 'primary-red-new'
  | 'secondary'
  | 'danger'
  | 'primary-white';

interface Props {
  text?: string;
  disabled?: boolean;
  href?: string;
  onClick?: MouseEventHandler;
  type?: ButtonColor;
  buttonType?: 'button' | 'reset' | 'submit' | undefined;
  size?: 'small' | 'general';
  fontSize?: string;
}

const Button: React.FC<MixProps<Props>> = ({
  text,
  href = '',
  type = 'primary-blue',
  className = '',
  buttonType = 'button',
  onClick,
  disabled = false,
  size = 'general',
  children,
  style,
  fontSize,
}) => {
  const classes = styles();

  if (href) {
    return (
      <a className={classes.buttonLink} href={href} target="_blank" rel="noreferrer" onClick={onClick}>
        <span>{text}</span>
      </a>
    );
  } else {
    return (
      <button
        type={buttonType}
        onClick={onClick}
        disabled={disabled}
        className={`${classes.button} ${className} ${type ? type : ''} ${size}`}
        style={style}
      >
        <span style={{ fontSize: fontSize }}>{text}</span>
        {children}
      </button>
    );
  }
};

export default Button;
