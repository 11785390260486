import { makeStyles } from '@material-ui/core';
import FullRoom from '@art/images/full.svg';

export const styles = makeStyles((theme) => ({
  fullWrapper: {
    flexDirection: 'column',
    textAlign: 'center',
    color: '#1B2036',
    boxSizing: 'border-box',
    '& > *': {
      boxSizing: 'border-box',
    },

    '& > h3': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '32px',
      textTransform: 'uppercase',
      marginBottom: '25px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '17px',
      },
    },
    '& > p': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '16px',
      marginBottom: '21px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },
  },

  fullPicture: {
    backgroundImage: `url(${FullRoom})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '105px',
    marginBottom: '19px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '16px',
    },
  },

  button: {
    color: 'inherit',
    width: '165px !important',
    '& > span': {
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '180px !important',
      height: '40px !important',
    },
  },
  paperSmall: {
    minWidth: '320px',
    padding: '20px 0 25px',
  },
}));
