import { GiphyFetch } from '@giphy/js-fetch-api';

const DEFAULT_URL = 'http://localhost:3001';
// @ts-ignore
const INJECTED_SERVER_URL = window.INJECTED_SERVER_URL;
// @ts-ignore
const INJECTED_AMPLITUDE_API_KEY = window.INJECTED_AMPLITUDE_API_KEY;
// @ts-ignore
const INJECTED_DISABLE_DEBUG_FLAG = window.INJECTED_DISABLE_DEBUG_FLAG;

const REACT_APP_URL = process.env.REACT_APP_URL || INJECTED_SERVER_URL || DEFAULT_URL;

export const IS_DEBUG = !INJECTED_DISABLE_DEBUG_FLAG;
export const IS_AGORA_ENABLED = !!process.env.REACT_APP_ENABLE_AGORA || false;
export const API_URL = REACT_APP_URL + '/api';
export const API_WS_URL = REACT_APP_URL + '/';
export const GRAPHQL_API_URL = REACT_APP_URL + '/graphql';
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || INJECTED_AMPLITUDE_API_KEY || '';
export const SCROLL_EPSILON = 1;

console.log(REACT_APP_URL, IS_AGORA_ENABLED);

export const PLAYER_STATUS = {
  IN_LOBBY: 'IN_LOBBY',
  READY_TO_JOIN_GAME: 'READY_TO_JOIN_GAME',
  IN_GAME: 'IN_GAME',
  READY_TO_QUIT_GAME: 'READY_TO_QUIT_GAME',
  ABSENT: 'ABSENT',
};
export type PlayerStatus = 'IN_LOBBY' | 'READY_TO_JOIN_GAME' | 'IN_GAME' | 'READY_TO_QUIT_GAME';

export enum ROOM_STATUS {
  UNKNOWN = 'UNKNOWN',
  LOBBY = 'LOBBY',
  GAME_IN_PROGRESS = 'GAME_IN_PROGRESS',
}

// @FIXME move variable to env
export const giphyFetch = new GiphyFetch('dwRQIWNtf337J7L0PnPI0KGD915lDsPT');

export const BIG_SCREEN_PX = '960px';

export const ENERGY_COST = 1;
export const MS_IN_MINUTE = 60000;

// for <PersonsContainer />
export const NUMBER_OF_PERSONS = 2;
export const MAX_DECKS = 10;
// export const PERSONS_ARRAY = [1, 2, 3, 4, 5];
export const PERSONS_ARRAY = [1, 2];
export const PERSON_STATUS = {
  READY: 'Ready',
  CHEERS: 'Cheers',
  DRINK: 'Drink',
  VOTED: 'Voted',
  ACTIVE: 'Active',
  OFFLINE: 'Offline',
};

export enum FROM_LOCATIONS {
  LOGIN = 'LOGIN',
  TOOLTIP = 'TOOLTIP',
  BANNER = 'BANNER',
  CREATE_ROOM = 'CREATE_ROOM',
  DEEP_LINK = 'DEEP_LINK',
  GAME_CARD = 'GAME_CARD',
  ROOM = 'ROOM',
  HOME_PAGE = 'HOME_PAGE',
}

export const isHomepageLocation = (from: FROM_LOCATIONS) =>
  [FROM_LOCATIONS.TOOLTIP, FROM_LOCATIONS.BANNER, FROM_LOCATIONS.CREATE_ROOM, FROM_LOCATIONS.GAME_CARD].includes(from);
