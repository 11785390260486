import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  roomWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },

  desktopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },

  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box',
  },

  refWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    position: 'relative',
    background: 'white',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
    '&::after': {
      content: '""',
      display: 'block',
      height: '1px',
      backgroundColor: '#E8E9EB',
      bottom: 0,
      left: 0,
      right: 0,
      position: 'absolute',
    },
  },

  roomButton: {
    width: 'auto !important',
    padding: '0px 25px',
    // marginLeft: 'auto',
    '& span': {
      fontSize: '18px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
    },
  },

  contentContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(243, 244, 245, 1)',
    boxSizing: 'border-box',
    overflowY: 'scroll',
    overflowX: 'hidden',
    justifyContent: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
  },
  whiteBackground: {
    display: 'flex',
    // position: 'sticky',
    bottom: 48,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    background: 'linear-gradient(180deg, rgba(243, 244, 245, 0) 0%, #F3F4F5 100%)',
    padding: '10px 0 20px',
    position: 'absolute',
    zIndex: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));
