// import { IdType } from '@shared/generics';
import { styles } from './PersonVideo.styles';
// import { useAppSelector } from '@src/app/hooks';
// import { selectPlayerVideoContainerId } from '@src/store/reducers/common';
import { MixProps } from '@src/common/generics';

interface IProps {
  containerId: string;
  muteVideoCondition: boolean;
}

const PersonVideo: React.FC<MixProps<IProps>> = ({ containerId, muteVideoCondition, style, children }) => {
  const classes = styles();

  return (
    <div className={`${classes.PersonVideo} ${muteVideoCondition ? 'muteVideo' : ''} PersonVideo`} style={style}>
      <div id={containerId} className={`${classes.PersonVideo__stream} PersonVideoStream`} />

      {muteVideoCondition && <div className={classes.PersonVideo__muteVideo} />}
      {children}
    </div>
  );
};

export default PersonVideo;
