import React, { Ref, useEffect /*useCallback, useEffect, useRef*/, useRef } from 'react';
import { useHistory } from 'react-router';
import { makeStyles, Theme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { MixProps, ParamsType } from '@src/common/generics';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // maxWidth: '100%',
    width: '100%',
    height: '100%',
    backgroundColor: '#F3F4F5',
    overflowY: 'scroll',
    alignItems: 'center',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    // WebkitOverflowScrolling: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      display: 'none',
      background: 'transparent',
      width: 0,
    },
  },
}));

const Canvas = React.forwardRef<HTMLDivElement, MixProps>(function Canvas(props, ref) {
  const classes = useStyles();

  return (
    <div className={classes.root} ref={ref} style={props.style}>
      {props.children}
    </div>
  );
});

export { Canvas };
