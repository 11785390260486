import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const CameraOffIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5898 13.0898C10.3555 13.3241 10.3555 13.704 10.5898 13.9383L11.4178 14.7663C11.1558 15.1062 10.9995 15.5345 10.9995 15.9999V23.9999C10.9995 25.1045 11.8795 25.9999 12.965 25.9999H22.6514L23.9249 27.2735C24.1592 27.5078 24.5391 27.5078 24.7735 27.2735C25.0078 27.0391 25.0078 26.6592 24.7735 26.4249L11.4383 13.0898C11.204 12.8555 10.8241 12.8555 10.5898 13.0898ZM21.4514 24.7999L12.2841 15.6326C12.2304 15.741 12.1995 15.8652 12.1995 15.9999V23.9999C12.1995 24.4615 12.5618 24.7999 12.965 24.7999H21.4514Z"
      fill={color}
    />
    <path
      d="M23.5581 15.9999V22.7094L24.7581 23.9094V15.9999C24.7581 14.8953 23.8781 13.9999 22.7926 13.9999H14.8487L16.0487 15.1999H22.7926C23.1958 15.1999 23.5581 15.5383 23.5581 15.9999Z"
      fill={color}
    />
    <path
      d="M26.54 18.2668C26.2412 18.5201 26.0685 18.8954 26.0685 19.2911V20.7087C26.0685 21.1044 26.2412 21.4797 26.54 21.733L28.9249 23.7554C29.3516 24.1172 29.9995 23.8084 29.9995 23.2432V16.7566C29.9995 16.1914 29.3516 15.8826 28.9249 16.2444L26.54 18.2668Z"
      fill={color}
    />
  </svg>
);

export default CameraOffIcon;
