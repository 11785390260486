import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles(() => ({
  EndSessionModal: {
    fontFamily: 'SF Pro Display',
    flexDirection: 'column',
    textAlign: 'center',
    color: pandaColors.black._900,
    minWidth: '560px',
    padding: '30px',

    '& > h3': {
      fontWeight: 600,
      fontSize: '32px',
      marginTop: 0,
      marginBottom: '4px',
    },

    '& > p': {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      marginBottom: '25px',
    },

    '& > div': {
      justifyContent: 'space-between',
      '& > button:nth-child(1)': {
        width: '280px',
      },
      '& > button:nth-child(2)': {
        width: '200px',
      },
    },
  },
}));
