import { IdType } from '@src/shared/generics';
import { selectDeck } from '@src/store/reducers/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CardDeck } from '@src/graphql/queries';
import { ICard, TableCard, UsedDeck } from '@src/shared/sessionInterfaces/standardSession.socket';
import { getCardFrontLink, getCardLink, getDeckTextLink } from '@src/_pandacards/helpers/deckDataMethods';
import { fetchExternalText, fetchText } from '@src/library/fetch';
import { DeckType } from '@src/shared/common';

const getImageCards = (deck: CardDeck): TableCard[] => {
  return Array.from({ length: deck.amount }, (_, index) => ({
    cardId: '',
    cardFace: getCardLink(deck.dataLink, index),
    cardBack: '',
    dragEnabled: false,
    faceUp: true,
  }));
};

const getWordCards = async (deck: CardDeck) => {
  const link = getDeckTextLink(deck.dataLink);
  const cardFace = getCardFrontLink(deck.dataLink);
  const wordsText = await fetchExternalText(link);
  const words = wordsText.split('\n');

  // check words.length === deck.amount;
  return Array.from({ length: words.length }, (_, index) => ({
    cardId: '',
    cardFace,
    cardBack: '',
    word: words[index],
    dragEnabled: false,
    faceUp: true,
  }));
};

const getCards = async (deck: CardDeck) =>
  deck.deckType === DeckType.IMAGE ? getImageCards(deck) : getWordCards(deck);

export const useDeckCards = (usedCards: number[], deckId?: IdType) => {
  const deck = useSelector(selectDeck(deckId));
  const [deckCards, setDeckCards] = useState<TableCard[]>([]);
  const [filteredCards, setFilteredCards] = useState<TableCard[]>([]);

  useEffect(() => {
    setDeckCards([]);

    if (deck) {
      getCards(deck).then((cards) => setDeckCards(cards));
    }
  }, [deckId]);

  useEffect(() => {
    if (deckCards.length > 0) {
      const newCards = [...deckCards] as (TableCard | undefined)[];

      usedCards.forEach((cardIndex) => (newCards[cardIndex] = undefined));
      const filteredCards = newCards.filter((card) => card !== undefined);

      setFilteredCards(filteredCards as TableCard[]);
    }
  }, [deckCards, usedCards]);

  return filteredCards;
};
