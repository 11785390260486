import { useState, useEffect } from 'react';
import { styles } from './ReverseTimer.styles';
import { reverseTimerInterval } from '@src/common/constants/common';

interface NumberComponentProps {
    initialTimeInSeconds: number;
    runOnExpire?: () => void;
}

const ReverseTimer: React.FC<NumberComponentProps> = ({ initialTimeInSeconds, runOnExpire = () => { } }) => {
    const [remainingTime, setRemainingTime] = useState(initialTimeInSeconds);
    const classes = styles();

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime((prevTime) => {
                // if remaining time is already 0, stop the timer
                if (prevTime <= 0) {
                    clearInterval(interval);
                    runOnExpire();
                    return 0;
                }
                return prevTime - 1;
            });
        }, reverseTimerInterval);

        // clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    // calculate minutes and seconds from remaining time
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;

    return (
        <div className={`${classes.ReverseTimer__timer} ReverseTimer__timer`}>
            <span>{String(minutes).padStart(2, '0')}</span>
            <span>{String(seconds).padStart(2, '0')}</span>
        </div>
    );
};

export default ReverseTimer;
