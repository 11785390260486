import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles((theme) => ({
  LeaveModal: {
    fontFamily: 'SF Pro Display',
    flexDirection: 'column',
    textAlign: 'center',
    color: pandaColors.black._900,
    minWidth: '560px',
    padding: '30px',
    '&.isClient': {
      // client mobile
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
        paddingBottom: 0,
        '& > h3': {
          fontSize: '28px',
        },
        '& > p': {
          fontSize: '20px',
          '& br': {
            display: 'none',
          },
        },
        '& > div': {
          flexDirection: 'column',
          '& > button': {
            width: 'auto',
            marginBottom: '10px',
            '&:last-child': {
              border: 'none',
            },
          },
        },
      },
    },

    '& > h3': {
      fontWeight: 600,
      fontSize: '32px',
      marginTop: 0,
      marginBottom: '4px',
    },

    '& > p': {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      marginBottom: '25px',
    },

    '& > div': {
      justifyContent: 'space-between',
      '& > button:nth-child(1)': {
        width: '280px',
      },
      '& > button:nth-child(2)': {
        width: '200px',
      },
    },
  },
}));
