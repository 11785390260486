import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles(() => ({
  QuestionInput: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    width: '100%',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '10px 10px 0px 0px',
    color: pandaColors.black._900,
    padding: '15px 20px',

    '& > h3': {
      fontSize: '20px',
      fontWeight: 600,
      marginTop: 0,
      marginBottom: '7px',
    },

    '& > textarea': {
      fontSize: '18px',
      fontWeight: 400,
      border: 'none',
      outline: 'none',
      width: '100%',
      minHeight: '30px',
      resize: 'none',
      '&[placeholder]': {
        color: pandaColors.black._650,
      },
      '&:focus': {
        '& + svg path': {
          fill: pandaColors.primary.blue,
        },
      },
    },

    '& > svg': {
      position: 'absolute',
      zIndex: 2,
      display: 'block',
      width: '20px',
      height: '20px',
      right: 15,
      top: 15,
      '& > path': {
        transition: 'all 0.23s',
      },
    },

    '& > span': {
      paddingTop: '10px',
      color: pandaColors.red,
    },
  },
}));
