import { useState } from 'react';
import { InfoIcon } from '@pc/components/icons';
import { CommonModal } from '@pc/components/ui';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { styles } from './HowItWorksButton.styles';

const HowItWorksButton = () => {
  const classes = styles();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <div className={classes.HowItWorksButton} onClick={() => setModalOpen(true)}>
        <i>
          <InfoIcon />
        </i>

        <span>Как работать?</span>
      </div>

      <CommonModal
        isOpen={isModalOpen}
        isFullscreen={true}
        handleClose={() => setModalOpen(false)}
        style={{ backgroundColor: 'transparent' }}
      >
        <VideoPlayer />
      </CommonModal>
    </>
  );
};

export default HowItWorksButton;
