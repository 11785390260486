import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const QuitIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 9C19.4477 9 19 9.44772 19 10V18C19 18.5523 19.4477 19 20 19C20.5523 19 21 18.5523 21 18V10C21 9.44772 20.5523 9 20 9ZM15.6243 13.8459C16.0914 13.5513 16.2314 12.9338 15.9368 12.4666C15.6422 11.9995 15.0247 11.8596 14.5575 12.1541C11.8252 13.877 10 16.8732 10 20.2962C10 25.6868 14.509 30 20 30C25.491 30 30 25.6868 30 20.2962C30 16.9286 28.2331 13.9736 25.574 12.2385C25.1114 11.9367 24.4918 12.067 24.19 12.5295C23.8882 12.992 24.0185 13.6116 24.481 13.9134C26.615 15.3058 28 17.6514 28 20.2962C28 24.5196 24.4501 28 20 28C15.5499 28 12 24.5196 12 20.2962C12 17.6082 13.4308 15.229 15.6243 13.8459Z"
      fill={color}
    />
  </svg>
);

export default QuitIcon;
