import React, { MouseEventHandler } from 'react';
import { Close } from '@components/icons';
import { styles } from './styles';
import { CSSProperties } from '@material-ui/styles';

interface IProps {
  onClick: MouseEventHandler;
  style?: CSSProperties;
}

const CloseCross: React.FC<IProps> = ({ onClick, style }) => {
  const classes = styles();

  return (
    <div className={classes.closeCross} onClick={onClick} style={style}>
      <Close />
    </div>
  );
};

export default CloseCross;
