import { sendDataToServer } from '@components/VideoChat/parts/helperMethods';
import { PEER_SERVER_EVENTS, SERVER_EVENTS } from '@shared/socketEvents/eventTypes';
import io, { Socket } from 'socket.io-client';
import { buildAuthHeaders } from '@src/features/auth/auth.fetch';
import { authSignInSilent } from '@src/features/auth/auth.helper';
import { exitToHomepage } from '@src/store/thunk/room';
import { AppDispatch } from '@src/app/store';

type EmitStack = {
  action: string;
  data: object;
};

class WS {
  socket: Socket | null = null;
  handlers: {
    [eventName: string]: ((data?: any) => void)[];
  } = {};
  emitStack: EmitStack[] = [];

  async connect(url: string, playerId: string, dispatch: AppDispatch) {
    if (this.socket != null) {
      throw 'Socket is already iniated, use reconnect() instead.';
    }

    const socket = (this.socket = io(url, {
      transports: ['polling', 'websocket'],
      query: { playerId },
      auth: await buildAuthHeaders(),
      // upgrade: false,
    }));
    Object.keys(this.handlers).forEach((eventName) => {
      this.handlers[eventName].map((cb) => {
        socket.on(eventName, cb);
      });
    });
    this.emitStack.map((message) => socket.emit(message.action, message.data));
    this.emitStack = [];
    this.handlers = {};
    this.socket.io.on('reconnect_attempt', (attemptNumber) => {
      this.socket!.io.opts.query = { playerId };
    });

    /*this.socket.on('connect_timeout', (timeout) => {
       console.log('WS connect timeout');
     });

     this.socket.on('reconnect_error', (error) => {
       console.log('WS reconnect_error');
     });

     this.socket.on('reconnect_failed', (error) => {
       console.log('WS reconnect_failed');
     });*/

    this.socket.on('connect_error', (error) => {
      if (error.message === 'jwt expired') {
        authSignInSilent()
          .then(() => buildAuthHeaders())
          .then((headers) => {
            this.socket!.auth = headers;
            this.socket!.connect();
          })
          .catch((reason) => dispatch(exitToHomepage(reason)));
        return;
        // reconnect
      }

      sendDataToServer(playerId, `Socket connect_error: ${error}`);
      this.socket!.io.opts.transports = ['polling', 'websocket'];
    });

    this.socket.onAny((eventName, ...args) => {
      if (!Object.values(SERVER_EVENTS).includes(eventName) && !Object.values(PEER_SERVER_EVENTS).includes(eventName)) {
        sendDataToServer(playerId, `Socket any event: ${eventName} ${args}`);
      }
    });
  }

  reconnect() {
    if (this.socket) {
      this.socket.open();
    }
  }

  internalOn(event: string, cb: (data?: any) => void) {
    if (this.handlers[event]) {
      this.handlers[event].push(cb);
    }
    this.handlers[event] = [cb];
  }

  internalOff(event: string, callback?: (data?: any) => void) {
    if (this.handlers[event]) {
      this.handlers[event] = this.handlers[event].filter((savedCallback) => savedCallback !== callback);
    }
  }

  on(event: string, cb: (data?: any) => void) {
    if (this.socket) {
      this.socket.on(event, cb);
    }
    this.internalOn(event, cb);
  }

  off(event: string, callback?: (data?: any) => void) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
    this.internalOff(event, callback);
  }

  emitStrict<T extends object>(action: string, data: T) {
    this.emit(action, data);
  }

  emit(action: string, data: object) {
    if (this.socket) {
      return this.socket.emit(action, data);
    }
    return this.emitStack.push({ action, data });
  }

  close() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

const WSClient = new WS();

export default WSClient;
