import React, { MouseEventHandler, useCallback, useState } from 'react';
import check from '@art/icons/check.svg';
import { CommonModal } from '@components/Modals';
import { TextInput } from '@components/Inputs';
import Button from '@components/Button';
import { styles } from './styles';
import { useDispatch } from 'react-redux';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { selectPlayer } from '@store/reducers/player.reducer';
import { useAppSelector } from '@app/hooks';
import { selectRoomIcons } from '@src/store/room/roomFeaturesData.slice';
import { createRoomThunk } from '@src/store/thunk/room';

interface Props {
  isOpen: boolean;
  handleClose: MouseEventHandler;
  onSubmit: (roomId: string) => void;
}

const CreateRoomModal: React.FC<Props> = ({ isOpen, handleClose, onSubmit }) => {
  const dispatch = useDispatch();
  const player = useAppSelector(selectPlayer);
  const [isButtonClicked, setButtonClicked] = useState(false);
  const [iconIndex, setIconIndex] = useState<number | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [roomName, setSearchRoom] = useState(`${player.name}'s room`);
  const classes = styles();
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const avatars = useAppSelector(selectRoomIcons);

  const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setSearchRoom(ev.currentTarget.value);
    setButtonClicked(false);
  };

  const onReject = () => {
    setErrorMessage('Could not create room');
    setButtonClicked(false);
  };

  const onClick = useCallback(
    (roomName: string) => {
      setButtonClicked(true);
      dispatch(createRoomThunk(roomName, onSubmit, onReject, iconIndex));
    },
    [iconIndex]
  );

  const onAvatarClick = (index: number, avatar: string) => {
    setIconIndex(index);
  };

  const renderAvatars = (avatars: string[]) => {
    return avatars.map((avatar, index) => (
      <div className={classes.avatarContainer} key={index} onClick={() => onAvatarClick(index, avatar)}>
        <img className={classes.avatar} src={avatar} alt="avatar" />
        {iconIndex === index && <img className={classes.check} src={check} alt="✓" />}
        {iconIndex === index && <div className={classes.background} />}
      </div>
    ));
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isFullscreen={true} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Create your room' : 'Создать комнату'} </h3>
        <form
          id="create-room-id"
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={(ev) => {
            ev.preventDefault();
            onClick(roomName);
          }}
          method="post"
        >
          <div className={classes.formInner}>
            <TextInput
              id="room-name"
              label={isEnLocale ? 'Room name' : 'Название комнаты'}
              variant="outlined"
              type="text"
              onChange={onChange}
              value={roomName}
              helperText={errorMessage.toString()}
              error={errorMessage != ''}
            />

            <h6 id="transition-modal-title">{isEnLocale ? 'Choose room avatar' : 'Выбрать аватар комнаты'} </h6>
            <div className={classes.avatarsContainer}>{renderAvatars(avatars)}</div>
          </div>

          <Button
            text={isEnLocale ? 'Create' : 'Создать'}
            buttonType="submit"
            type="primary-green-new"
            disabled={!roomName || isButtonClicked}
          />
        </form>
      </div>
    </CommonModal>
  );
};

export default CreateRoomModal;
