import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    loginSocial: {
      fontFamily,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      minHeight: '600px',
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        background: '#F3F4F5',
      },
      '& *': {
        boxSizing: 'border-box',
      },
    },

    whiteBox: {
      display: 'block',
      width: '100%',
      minHeight: '470px',
      background: theme.palette.common.white,
      overflow: 'hidden',
      borderTop: '1px solid #E8E9EB',
      [theme.breakpoints.up('sm')]: {
        border: '1px solid #E8E9EB',
        borderRadius: '20px',
        width: '380px',
      },
    },

    socialList: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '20px 15px',
      marginLeft: '5px',
      [theme.breakpoints.up('sm')]: {
        border: 0,
        padding: '0 25px',
      },
    },
  })
);
