import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import CheckboxImg from '@art/icons/checkbox.svg';

export const styles = makeStyles((theme) => ({
  checkboxWrapper: {
    marginLeft: '3px',
    marginTop: '5px',
    marginBottom: '10px',
    // checbox label
    '& .MuiFormControlLabel-label': {
      fontFamily,
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21px',
      color: '#1B2036',
      '&::selection': {
        background: 'transparent',
      },
    },
  },

  // checkbox
  checkboxRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  checkboxIcon: {
    borderRadius: '4px',
    width: '20px',
    height: '20px',
    border: '1px solid #E8E9EB',
    backgroundColor: '#f5f8fa',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },

  checkboxCheckedIcon: {
    backgroundColor: '#2C74F3',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      top: '50%',
      display: 'block',
      marginLeft: '-6px',
      marginTop: '-4.5px',
      width: 12,
      height: 9,
      backgroundImage: `url(${CheckboxImg})`,
    },
    'input:hover ~ &': {
      backgroundColor: '#356FD6',
    },
  },
}));
