import WSClient from '@src/sockets';
import { useEffect, useRef, useState } from 'react';
import Peer from 'simple-peer';
import { PeerEvents } from './peer.types';
import { PEER_CLIENT_EVENTS, PEER_SERVER_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { ClientConnectPeerEvent, ServerPeerConnectedEvent } from '@src/shared/socketEvents/peerSocketEvents';
import { IdType } from '@src/shared/generics';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { setVideoState, setVideoStates } from '@src/store/reducers/common';
import _, { update } from 'lodash';
import { muteAll } from '@src/store/thunk/playerData.thunk';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import {
  selectCamera,
  selectMicrophone,
  selectResetAgora,
  setCameras,
  setMicrophones,
  setResetAgora,
} from '@src/store/reducers/playerData.reducer';

interface Props {
  videoProfile?: string;
}

interface IMediaState {
  hasVideo: boolean;
  hasAudio: boolean;
}

interface ICommonState {
  callId: IdType;
  isAutoPlayEnabled: boolean;
  shouldResume: boolean;
}

const isTrackPlaying = (track: MediaStreamTrack | undefined) =>
  track && track.enabled && !track.muted && track.readyState !== 'ended';

const convertDeviceInfo = (deviceInfo: MediaDeviceInfo) => ({
  deviceId: deviceInfo.deviceId,
  label: deviceInfo.label,
});

const convertDeviceInfos = (deviceInfos: MediaDeviceInfo[]) => {
  return deviceInfos.map((deviceInfo) => convertDeviceInfo(deviceInfo));
};

type TrackFieldType = 'isVideoEnabled' | 'isAudioEnabled';

export const usePeerInternal = (props: Props) => {
  const dispatch = useAppDispatch();
  const playerId = useAppSelector(selectPlayerId);
  const [localMedia, setLocalMedia] = useState<MediaStream>();
  const [remoteMedia, setRemoteMedia] = useState<MediaStream>();
  const peerOutRef = useRef<Peer.Instance>();
  const peerInRef = useRef<Peer.Instance>();
  const [callOutTargetId, setCallOutTargetId] = useState('');
  const [callInTargetId, setCallInTargetId] = useState('');
  const [isAutoPlayEnabled, setIsAutoPlayEnabled] = useState(true); // true, to eliminate the start click reset.
  const [commonState, _1] = useState<Partial<ICommonState>>({});
  const [hasConnected, setHasConnected] = useState(false);
  const [hasStream, setHasStream] = useState(false);
  const [targetId, setTargetId] = useState('');
  const [counterPartyId, setCounterPartyId] = useState('');
  const [isStreamCreating, setIsStreamCreating] = useState(false);
  const chosenMicrophone = useAppSelector(selectMicrophone);
  const chosenCamera = useAppSelector(selectCamera);
  const shouldReset = useAppSelector(selectResetAgora);

  useEffect(() => {
    WSClient.on(PEER_SERVER_EVENTS.PEER_CONNECTED, ({ data }: { data: ServerPeerConnectedEvent }) => {
      _acceptPeerConnection(data.requestId, data.signal);
    });

    WSClient.emitStrict<{}>(PEER_CLIENT_EVENTS.PEER_READY, {});

    getDevices(true);
  }, []);

  useEffect(() => {
    if (localMedia || commonState.shouldResume) {
      commonState.shouldResume = false;
      _createLocalMedia();
    }
  }, [shouldReset]);

  const onTrackEnabled = (playerId: IdType, trackField: TrackFieldType, shouldEnable: boolean) =>
    dispatch(setVideoState({ playerId, data: { [trackField]: shouldEnable } }));
  const onVideoEnabled = (playerId: string, shouldEnable: boolean) =>
    dispatch(setVideoState({ playerId, data: { isVideoEnabled: shouldEnable } }));
  const onAudioEnabled = (playerId: string, shouldEnable: boolean) =>
    dispatch(setVideoState({ playerId, data: { isAudioEnabled: shouldEnable } }));
  const setMediaStates = (playerId: string, isVideoEnabled: boolean, isAudioEnabled: boolean) =>
    dispatch(setVideoState({ playerId, data: { isVideoEnabled, isAudioEnabled } }));

  const getDevices = async (skipPermissionCheck: boolean) => {
    const devicesDirty = await navigator.mediaDevices.enumerateDevices();
    const devices = _.uniqBy(devicesDirty, 'deviceId');
    const microphones = devices.filter((device) => device.kind === 'audioinput');
    const cameras = devices.filter((device) => device.kind === 'videoinput');
    dispatch(setMicrophones(convertDeviceInfos(microphones)));
    dispatch(setCameras(convertDeviceInfos(cameras)));
  };

  const sendMediaState = () => {
    if (peerOutRef.current?.connected) {
      const hasAudio = isTrackPlaying(localMedia?.getAudioTracks()[0]);
      const hasVideo = isTrackPlaying(localMedia?.getVideoTracks()[0]);

      peerOutRef.current.send(JSON.stringify({ hasVideo, hasAudio }));
    }
  };

  const createPeerConnection = (targetId: IdType) => {
    if (!localMedia) return;

    WSClient.off(PEER_SERVER_EVENTS.PEER_ANSWERED);

    const peer = new Peer({ initiator: true, trickle: false, stream: localMedia });

    peer.on(PeerEvents.SIGNAL, (data) => {
      WSClient.emitStrict<ClientConnectPeerEvent>(PEER_CLIENT_EVENTS.CONNECT_PEER, { signal: data, targetId });
    });

    _addPeerCallbacks(peer, true, targetId);
    peerOutRef.current = peer;

    setCallOutTargetId(targetId);

    WSClient.on(PEER_SERVER_EVENTS.PEER_ANSWERED, ({ data }: { data: ServerPeerConnectedEvent }) => {
      WSClient.off(PEER_SERVER_EVENTS.PEER_ANSWERED);
      peer.signal(data.signal);

      console.log(localMedia.getTracks());
    });
  };

  const _acceptPeerConnection = (requestId: IdType, signal: Peer.SignalData) => {
    const peer = new Peer({ initiator: false, trickle: false });

    _addPeerCallbacks(peer, false, requestId);
    peerInRef.current = peer;

    peer.on(PeerEvents.SIGNAL, (data) => {
      WSClient.emitStrict<ClientConnectPeerEvent>(PEER_CLIENT_EVENTS.ANSWER_PEER, {
        signal: data,
        targetId: requestId,
      });
    });

    setCallInTargetId(requestId);
    commonState.callId = requestId;
    peer.signal(signal);
  };

  const _addPeerCallbacks = (peer: Peer.Instance, isInitiator: boolean, counterPartyId: IdType) => {
    peer.on(PeerEvents.CONNECT, () => {
      console.log('connect to peer', isInitiator);

      if (isInitiator) {
        sendMediaState();
      }
    });

    peer.on(PeerEvents.CLOSE, () => {
      console.log('Connection with peer closed :(', isInitiator);

      if (!isInitiator) {
        closeIncomingPeerConnection();
      }
    });

    peer.on(PeerEvents.STREAM, (currentStream) => {
      console.log('remote stream', currentStream?.getTracks());
      setRemoteMedia(currentStream);
      setIsAutoPlayEnabled(false);
      commonState.isAutoPlayEnabled = false;
    });

    peer.on(PeerEvents.DATA, (data: Uint8Array) => {
      const mediaState = JSON.parse(data.toString()) as IMediaState;
      console.log('data', mediaState);

      setMediaStates(counterPartyId, mediaState.hasVideo || false, mediaState.hasAudio || false);
    });

    peer.on(PeerEvents.ERROR, (error) => {
      console.log('error', error);

      if (!isInitiator) {
        closeIncomingPeerConnection();
      }
      /*if (targetId) {
        createPeerConnection(targetId);
      }*/
    });
  };

  const _createLocalMedia = async (forced = false) => {
    setIsStreamCreating(true);
    if (hasStream) {
      stopLocalTracks();
    }

    const video: MediaTrackConstraints = { deviceId: chosenCamera?.deviceId };
    const audio: MediaTrackConstraints = { deviceId: chosenMicrophone?.deviceId };

    const stream = await navigator.mediaDevices.getUserMedia({ video, audio });

    if (!stream) {
      dispatch(muteAll());
      setIsStreamCreating(false);
      return;
    }

    setLocalMedia(stream);
    stream.getTracks().forEach((track) => (track.enabled = false));
    setIsStreamCreating(false);
  };

  const _updateTrackState = (
    track: MediaStreamTrack | undefined,
    shouldEnable: boolean,
    trackField: TrackFieldType
  ) => {
    if (!track && !shouldEnable) {
      return;
    }

    if (!track || (shouldEnable && track.readyState === 'ended')) {
      _createLocalMedia();
      return;
    }

    track!.enabled = shouldEnable;

    if (!shouldEnable && track.readyState === 'live') {
      track.stop();
    }

    sendMediaState();
    onTrackEnabled(playerId, trackField, shouldEnable && !!track);
  };

  const setVideoEnabled = (shouldEnable: boolean) => {
    const track = localMedia?.getVideoTracks()[0];
    _updateTrackState(track, shouldEnable, 'isVideoEnabled');
  };

  const setAudioEnabled = (shouldEnable: boolean) => {
    const track = localMedia?.getAudioTracks()[0];
    _updateTrackState(track, shouldEnable, 'isAudioEnabled');
  };

  const closePeerConnection = () => {
    WSClient.off(PEER_SERVER_EVENTS.PEER_ANSWERED);
    peerOutRef.current?.destroy();
    peerOutRef.current = undefined;
  };

  const closeIncomingPeerConnection = () => {
    peerInRef.current?.destroy();
    peerInRef.current = undefined;
    setRemoteMedia(undefined);
    if (commonState.callId) {
      setMediaStates(commonState.callId, false, false);
      commonState.callId = undefined;
    }
  };

  const stopLocalTracks = () => {
    localMedia?.getTracks().forEach((track) => track.stop());
    setHasStream(false);
    setLocalMedia(undefined);
  };

  const stopTracks = () => {
    stopLocalTracks();
    closePeerConnection();
    closeIncomingPeerConnection();
    commonState.shouldResume = true;
  };

  const resumeTracks = () => {
    dispatch(setResetAgora({}));
  };

  // playaround browser voice autoplay restrictions.
  const setAutoPlayEnabled = () => {
    if (!commonState.isAutoPlayEnabled) {
      setIsAutoPlayEnabled(true);
      commonState.isAutoPlayEnabled = true;

      if (commonState.callId) {
        dispatch(setVideoState({ playerId: commonState.callId, data: { shouldRestart: {} } }));
      }
    }
  };

  return {
    localMedia,
    remoteMedia,
    isStreamCreating,
    createPeerConnection,
    closePeerConnection,
    hasStream,
    setVideoEnabled,
    setAudioEnabled,
    isAutoPlayEnabled,
    setAutoPlayEnabled,
    stopTracks,
    resumeTracks,
  };
};
