import { userManager } from './auth.config';

type AuthHeaders = {
  authorization?: string;
};

const buildAuthHeaders: () => Promise<AuthHeaders> = async () => {
  const user = await userManager.getUser();
  if (user) {
    return {
      authorization: `${user.token_type} ${user.access_token}`,
    };
  } else {
    return {};
  }
};

export { buildAuthHeaders };
