import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

const { black, primary } = pandaColors;

export const styles = makeStyles(() => ({
  InviteBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: '680px',
    flexBasis: '680px',
    flexGrow: 0,
    padding: '100px',
    borderRadius: '10px',
    paddingTop: '110px',
    backgroundColor: black._800,
  },

  InviteBlock__image: {
    marginBottom: '20px',
  },

  InviteBlock__title: {
    fontSize: '32px',
    fontWeight: 600,
    marginTop: 0,
    marginBottom: '10px',
    letterSpacing: '0.5px',
  },

  InviteBlock__description: {
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '25px',
  },

  InviteBlock__link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '70px',
    padding: '20px',
    backgroundColor: black._700,
    marginBottom: '22px',
    cursor: 'pointer',
    '&:hover': {
      '& rect': {
        stroke: primary.blue,
      },
      '& path': {
        fill: primary.blue,
      },
    },

    '& > div': {
      display: 'block',
      color: primary.blue,
      fontSize: '20px',
      maxWidth: '400px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 600,
      '&::selection': {
        backgroundColor: primary.gold,
        color: black._900,
      },
    },

    '& > i': {
      '& rect, & path': {
        transition: 'all 0.23s',
      },
    },
  },

  InviteBlock__button: {
    '& > button': {
      width: '328px',
    },
    marginBottom: '37px',
  },

  InviteBlock__isLinkCopied: {
    color: primary.gold,
    animationDuration: '0.23s',
    fontSize: '18px',
    fontWeight: 500,
  },
}));
