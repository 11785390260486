import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';

export const CARD_MAX_WIDTH: number = 260;

export const styles = makeStyles((theme) => ({
  Card: {
    width: '100%',
    height: '100%',
    // objectFit: 'contain',
    position: 'relative',
    zIndex: 1,
    // marginLeft: 'auto',
    // marginRight: 'auto',
    margin: 'auto',
    alignSelf: 'center',
    // overflow: 'visible',

    '& > img': {
      width: '100%',
      height: '100%',
      // maxWidth: `${CARD_MAX_WIDTH}px`,
      // maxWidth: '100%',
      // maxHeight: '100%',
      // maxWidth: '100%',
      // maxHeight: '100%',
      objectFit: 'contain',

      '&:hover': {
        cursor: 'grab',
      },
    },

    // карта-слово
    '&.isWordCard': {
      '& > span, & > input': {
        position: 'absolute',
        display: 'block',
        zIndex: 2,
        verticalAlign: 'middle',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: '20px',
        textOverflow: 'ellipsis',
        color: pandaColors.black._900,

        '&.input': {
          border: 'none',
          background: 'transparent',
          top: '5%',
          left: '5%',
          right: '5%',
        },
        // '&.top': {
        //   top: '5%',
        //   left: '5%',
        //   right: '5%',
        // },
        '&.left': {
          top: '15%',
          left: '5%',
          bottom: '15%',
          writingMode: 'vertical-lr',
          rotate: '180deg',
        },
        '&.right': {
          top: '15%',
          right: '5%',
          bottom: '15%',
          writingMode: 'vertical-lr',
        },
        '&.bottom': {
          left: '5%',
          right: '5%',
          bottom: '5%',
        },
      },
      '&.discard': {
        '& > input': {
          fontSize: '8px',
          /*top: '5px',
          left: '10px',
          right: '10px',*/
        },
        '& > span': {
          fontSize: '8px',
          // '&.top': {
          //   top: '5px',
          //   left: '10px',
          //   right: '10px',
          // },
          /*'&.left': {
            top: '10px',
            left: '5px',
            bottom: '10px',
            writingMode: 'vertical-lr',
            rotate: '180deg',
          },
          '&.right': {
            top: '10px',
            right: '5px',
            bottom: '10px',
            writingMode: 'vertical-lr',
          },
          '&.bottom': {
            left: '10px',
            right: '10px',
            bottom: '5px',
          },*/
        },
      },

      // client mobile
      [theme.breakpoints.down('xs')]: {
        '&.isClient': {
          '& > input': {
            fontSize: '16px',
          },
          '& > span': {
            fontSize: '16px',
          },
        },
      },
    },
  },

  Card__enterWordInput: {
    display: 'block',
    textAlign: 'center',
    border: 'none',
    background: 'transparent',
    // outline: `2px solid ${pandaColors.primary.gold}`,

    position: 'absolute',
    zIndex: 3,
    //top: '15px',
    //left: '10px',
    //right: '10px',

    fontSize: '20px',
    fontWeight: 600,
    color: pandaColors.black._900,
    textTransform: 'uppercase',
  },

  Card__menu: {
    width: '260px',
    listStyle: 'none',
    margin: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '10px',
    backgroundColor: pandaColors.white._0,
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 16px 0px rgba(0, 0, 0, 0.10), 0px 29px 29px 0px rgba(0, 0, 0, 0.09), 0px 64px 39px 0px rgba(0, 0, 0, 0.05), 0px 114px 46px 0px rgba(0, 0, 0, 0.01), 0px 179px 50px 0px rgba(0, 0, 0, 0.00)',

    '& > li': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '30px',
      paddingRight: '30px',
      fontFamily: 'SF Pro Display',
      color: pandaColors.black._600,
      fontSize: '16px',
      lineHeight: '16px',
      minHeight: '40px',
      fontWeight: 400,
      transition: 'all 0.23s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#E7EBFE',
        color: pandaColors.primary.blue,
      },
    },
  },

  Card__menu2: {
    position: 'absolute',
    zIndex: 2,
    top: '25%',
    left: '50%',
    width: '260px',
    marginLeft: '-130px',
    listStyle: 'none',
    margin: 0,
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: '10px',
    backgroundColor: pandaColors.white._0,
    boxShadow:
      '0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 7px 16px 0px rgba(0, 0, 0, 0.10), 0px 29px 29px 0px rgba(0, 0, 0, 0.09), 0px 64px 39px 0px rgba(0, 0, 0, 0.05), 0px 114px 46px 0px rgba(0, 0, 0, 0.01), 0px 179px 50px 0px rgba(0, 0, 0, 0.00)',

    '& > li': {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '30px',
      paddingRight: '30px',
      fontFamily: 'SF Pro Display',
      color: pandaColors.black._600,
      fontSize: '16px',
      lineHeight: '16px',
      minHeight: '40px',
      fontWeight: 400,
      transition: 'all 0.23s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#E7EBFE',
        color: pandaColors.primary.blue,
      },
    },
  },

  CardTooltip: {
    position: 'absolute',
  },
}));
