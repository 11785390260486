import { API_URL, GRAPHQL_API_URL } from '@common/constants/system';
import { ApolloClient, InMemoryCache, OperationVariables, FetchPolicy, createHttpLink } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { logger } from '@src/common/logger';
import { RequestType } from '@src/common/commonTypes';
import { ObjectType } from '@src/shared/generics';
import { SERVER_ERROR_AUTH_STATUS_CODE } from '@src/features/auth/auth.helper';
import { buildAuthHeaders } from '@src/features/auth/auth.fetch';
import { defaultObject } from '@src/common/generics';
import { userManager } from '@src/features/auth/auth.config';
import { apolloAuthLink } from '@src/features/auth/auth.graphql';

const httpLink = createHttpLink({
  uri: GRAPHQL_API_URL,
});

const gqlClient = new ApolloClient({
  link: apolloAuthLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const buildGraphQLQueryDebugString: (query: DocumentNode, variables: OperationVariables) => string = (
  query: DocumentNode,
  variables: OperationVariables
) => {
  return query.definitions
    .map((node) => {
      if (node.kind === 'OperationDefinition') {
        return (
          `${node.name?.value} (${JSON.stringify(variables)}) {\n` +
          node.selectionSet.selections.map((selection) => {
            if (selection.kind === 'Field') {
              return selection.name.value;
            } else {
              return JSON.stringify(selection);
            }
          }) +
          '\n' +
          `}`
        );
      } else {
        return JSON.stringify(node);
      }
    })
    .join('\n');
};

export const fetchGraphql = async <PARAMS = OperationVariables, RESPONSE = object>(
  query: DocumentNode,
  variables: PARAMS,
  forced = false,
  useAuth = false
): Promise<RESPONSE> => {
  // ToDo: Handle errors.
  return gqlClient
    .query<RESPONSE>({
      query: query,
      variables: variables,
      fetchPolicy: forced ? 'no-cache' : undefined, // 'network-only'
      context: { useAuth },
    })
    .then((response) => response.data as RESPONSE)
    .catch((reason) => {
      const debugQueryString = buildGraphQLQueryDebugString(query, variables);
      console.debug(`Failed GraphQLQuery:\n${debugQueryString}\nreason ${reason}`);
      return reason;
    });
};

// TODO: mutateGraphqlSafe
export const mutateGraphql = async <PARAMS = OperationVariables, RESPONSE = object>(
  query: DocumentNode,
  variables: PARAMS,
  useAuth = true
) => {
  // ToDo: Handle errors.
  // console.log(variables);
  return gqlClient
    .mutate<RESPONSE>({
      mutation: query,
      variables: variables,
      context: { useAuth },
    })
    .then((response) => response.data as RESPONSE)
    .catch((reason) => {
      const debugQueryString = buildGraphQLQueryDebugString(query, variables);
      console.log(`Failed GraphQLMutation:\n${debugQueryString}\nreason ${reason}`);
      return Promise.reject(reason);
    });
};

export const mutateGraphqlWithRetry = async <PARAMS = OperationVariables, RESPONSE = object>(
  query: DocumentNode,
  variables: PARAMS
) => {
  return mutateGraphql<PARAMS, RESPONSE>(query, variables)
    .then((response) => response)
    .catch((reason) => {
      if (reason.message === 'invalid_grant') {
        return userManager.signinSilent().then((user) => mutateGraphql<PARAMS, RESPONSE>(query, variables));
      }
      return Promise.reject(reason);
    });
};

type FetchApiRaw = (query: string, requestType: RequestType, payload?: ObjectType) => Promise<Response>;
type FetchWithRetry = (query: string, requestType: RequestType, payload?: ObjectType) => Promise<Response>;
type FetchApi<RESPONSE = any> = <RESPONSE>(
  query: string,
  requestType?: RequestType,
  payload?: ObjectType
) => Promise<RESPONSE>;

const fetchApiRaw: FetchApiRaw = async (query, requestType, payload = defaultObject) => {
  const isNoBodyRequest = RequestType.GET === requestType;
  const body = isNoBodyRequest ? {} : { body: JSON.stringify(payload) };
  const authHeaders = await buildAuthHeaders();

  return await fetch(`${API_URL}${query}`, {
    method: requestType,
    mode: 'cors',
    headers: {
      ...authHeaders,
      'Content-Type': 'application/json',
    },
    ...body,
  });
};

export const fetchWithRetry: FetchWithRetry = (query, requestType, payload) => {
  return fetchApiRaw(query, requestType, payload)
    .then((response) => {
      if (response.status === SERVER_ERROR_AUTH_STATUS_CODE) {
        return userManager.signinSilent().then((user) => {
          return fetchApiRaw(query, requestType, payload);
        });
      } else {
        return response;
      }
    })
    .then((response) => {
      if (response.status === 400) {
        return userManager.removeUser().then(() => Promise.reject(response));
      }
      return response;
    });
};

export const fetchApi: FetchApi = (query, requestType = RequestType.GET, payload = defaultObject) => {
  return fetchWithRetry(query, requestType, payload).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json().then(({ data, message }: { data: any; message?: string }) => {
        if (message) {
          return Promise.reject(message);
        } else {
          return Promise.resolve(data);
        }
      });
    } else {
      console.error(`Failed to fetch resource from ${query} with status ${response.status}`);

      return response
        .json()
        .catch((err) => {
          return Promise.reject(`ERROR: ${response.statusText}`);
        })
        .then(({ data, message }: { data: any; message?: string }) => {
          if (message) {
            return Promise.reject(message);
          } else {
            // @fixme: provide better error message
            return Promise.reject(`ERROR: ${data}`);
          }
        });
    }
  });
};

export const fetchText = async (url: string) => {
  return fetchWithRetry(url, RequestType.GET, defaultObject).then((response) => response.text());
};

export const fetchJson = async (url: string) =>
  fetchWithRetry(url, RequestType.GET, defaultObject).then((response) => response.json());

export const fetchExternal: FetchApiRaw = async (query, requestType, payload = defaultObject) => {
  const isNoBodyRequest = RequestType.GET === requestType;
  const body = isNoBodyRequest ? {} : { body: JSON.stringify(payload) };
  // const authHeaders = await buildAuthHeaders();

  return fetch(query, {
    method: requestType,
    mode: 'cors',
    headers: {
      // ...authHeaders,
      'Content-Type': 'application/json',
    },
    ...body,
  });
};

export const fetchExternalText = (url: string) =>
  fetchExternal(url, RequestType.GET, defaultObject).then((response) => response.text());
