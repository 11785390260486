import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { createPayload, simpleMergeThunk } from '@store/reduxHelpers';

export const initialState = {
  playerLabels: [] as [string, string][],
};

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;
const simpleMerge = simpleMergeThunk<StateType, PayloadType>();

export type GameState = StateType;

export const gameSlice = createSlice({
  name: 'game',
  initialState: initialState,
  reducers: {
    updateGameState: simpleMerge,
    /*setPlayerLabel: {
      reducer: (state: Draft<GameState>, action: PayloadAction<{ playerId: string; label: string }>) => {
        const payload = action.payload;
        state.playerLabels.set(payload.playerId, payload.label);
      },
      prepare: (playerId: string, label: string) => createPayload({ playerId, label }),
    },*/
  },
});

export const { updateGameState } = gameSlice.actions;

const gameState = (state: RootState) => state.game;

export const selectGameState = createSelector(gameState, (state) => state);
export const selectPlayerLabels = createSelector(selectGameState, (state) => new Map(state.playerLabels));
/*export const selectPlayerLabel = (playerId: string) =>
  createSelector(selectPlayerLabels, (labels) => labels.get(playerId));*/
