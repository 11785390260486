import { useEffect, useState, useRef, RefObject } from 'react';
import { ActionsType } from '../../standardSession.hook';
import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { TooltipMenuLink, useCardActions } from './useCardActions';
import { CardPlacement, SlotInfo } from '../../standardSession.types';
import { CardContextProps, createCardContext } from './useCardContext';

const {
  ZOOM_IN,
  ZOOM_OUT,
  SAVE,
  FLIP,
  ENTER_WORD,
  ALLOW_COVER,
  DISALLOW_COVER,
  REMOVE,
  REMOVE_ALL,
  WITHDRAW,
} = TooltipMenuLink;

const menuLinkTitles: Record<TooltipMenuLink, string> = {
  [ZOOM_IN]: 'Увеличить карту',
  [ZOOM_OUT]: 'Уменьшить карту',
  [SAVE]: 'Сохранить карту',
  [FLIP]: 'Перевернуть карту',
  [ENTER_WORD]: 'Вписать слово',
  [ALLOW_COVER]: 'Разрешить накрывание карты клиенту',
  [DISALLOW_COVER]: 'Запретить накрывание карты клиенту',
  [REMOVE]: 'Убрать карту',
  [REMOVE_ALL]: 'Убрать все карты',
  [WITHDRAW]: 'Вернуть',
};

const createMenuLinkAction: (
  card: TableCard,
  menuLink: TooltipMenuLink,
  actions: ActionsType,
  onEnterWord: () => void,
  slotInfo: SlotInfo | undefined
) => () => void = (card, menuLink, actions, onEnterWord, slotInfo) => {
  switch (menuLink) {
    case ZOOM_IN:
      return () => actions.onCardEnlarged(slotInfo!.index);
    case ZOOM_OUT:
      return () => actions.onCardEnlarged(-1);
    case SAVE:
      return () => {};
    case FLIP:
      return () => actions.onCardFlipped(card.cardId, !card.faceUp);
    case ENTER_WORD:
      return () => onEnterWord();
    case ALLOW_COVER:
      return () => actions.onSlotDragEnabled(slotInfo!.index, !slotInfo!.dragEnabled);
    case DISALLOW_COVER:
      return () => actions.onSlotDragEnabled(slotInfo!.index, !slotInfo!.dragEnabled);
    case REMOVE:
      return () => actions.onCardDiscarded(slotInfo!.index);
    case REMOVE_ALL:
      return () => actions.onAllDiscarded();
    case WITHDRAW:
      return () => actions.onCardWithdrawed(card.cardId);
    default:
      return () => {};
  }
};

export interface CardTooltipLink {
  title: string;
  action: () => void;
}

const useCardTooltip = (
  card: TableCard,
  actions: ActionsType | undefined,
  placement: CardPlacement,
  slotInfo: SlotInfo | undefined
) => {
  const [links, setLinks] = useState<CardTooltipLink[]>([]);
  const positionRef = useRef<{ x: number; y: number; element?: RefObject<HTMLDivElement> }>({
    x: 0,
    y: 0,
    element: undefined,
  });
  const [canEnterWord, setCanEnterWord] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const actionLinks = useCardActions(card, placement, slotInfo);

  const onWordChanged = (word: string) => {
    actions?.onCardWordChanged(card.cardId, word);
    setCanEnterWord(false);
  };

  const setTooltipOpen = (shouldOpen: boolean) => {
    canEnterWord || links.length == 0 ? undefined : setIsTooltipOpen(shouldOpen);
  };

  const openTooltip = (x: number, y: number, element: RefObject<HTMLDivElement>) => {
    if (!isTooltipOpen) {
      const boundingRect = element.current!.getBoundingClientRect();
      positionRef.current = { x: x - boundingRect.x, y: y - boundingRect.y, element };

      // const parent = document.getElementById('cardFieldId');
      // console.log(parent, parent?.getBoundingClientRect());

      setTooltipOpen(true);
    }
  };

  const getBoundingRect = () => {
    const boundingRect = positionRef.current.element!.current!.getBoundingClientRect();
    return new DOMRect(positionRef.current.x + boundingRect.x, positionRef.current.y + boundingRect.y, 0, 0);
  };

  // save to state?
  const cardContext = { isTooltipOpen, canEnterWord, getBoundingRect, onWordChanged, setTooltipOpen, openTooltip };

  useEffect(() => {
    const onEnterWord = () => {
      setIsTooltipOpen(false);
      setCanEnterWord(true);
    };

    const newLinks = actionLinks.map((link) => ({
      title: menuLinkTitles[link],
      action: createMenuLinkAction(card, link, actions!, onEnterWord, slotInfo),
    }));
    setLinks(newLinks);
  }, [actionLinks]);

  return [links, cardContext] as const;
};

export { useCardTooltip };
