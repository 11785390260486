import { Button, CommonModal } from '@pc/components/ui';
import { useEffect, useState } from 'react';
import { CardDeck } from '@src/graphql/queries';
import { CloseIcon, PenIcon } from '@pc/components/icons';
import { selectPlayerDecksExtended } from '@src/store/reducers/common';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { getAvatarLink } from '@src/_pandacards/helpers/deckDataMethods';
import { MAX_DECKS } from '@src/common/constants/system';
import { setSidebarModals } from '@src/store/room/roomSlice';
import { IdType } from '@src/shared/generics';
import pandaColors from '@src/_pandacards/helpers/colors';
import { styles } from './GiveCardModal.styles';

type TProps = {
  isOpen: boolean;
  sessionQuestion: string;
  closeModal: () => void;
  onCardsDealed: (question: string, deckId: IdType, amount: number, faceUp: boolean) => void;
};

const GiveCardModal: React.FC<TProps> = ({ isOpen, sessionQuestion, closeModal, onCardsDealed }) => {
  const classes = styles();
  const dispatch = useAppDispatch();

  // Выбранные колоды с модалки "Выбрать колоду"
  const playerDecks = useAppSelector(selectPlayerDecksExtended);

  // Вопрос
  const [question, setQuestion] = useState<string>('');
  const [isQuestionLimit, setQuestionLimit] = useState<boolean>(false);
  useEffect(() => setQuestion(sessionQuestion), [sessionQuestion]);
  const onChangeTextarea = (value: string) => {
    if (value.length >= 107) {
      setQuestionLimit(true);
    } else {
      setQuestion(value);
      setQuestionLimit(false);
    }
  };

  // Выбранные колоды
  const [selectedGameDeck, setSelectedGameDeck] = useState<CardDeck | null>(null);
  const onSelectGameDeck = (clickedDeck: CardDeck) => {
    const isAlreadySelected = selectedGameDeck?.id === clickedDeck.id;
    setSelectedGameDeck(isAlreadySelected ? null : clickedDeck);
  };

  // Количество карт (по умолчанию 3 карты)
  const [cardsCount, setCardsCount] = useState<number>(3);

  // Раздача (по умолчанию в открытую)
  const [cardFaceUp, setCardOpenFaceUp] = useState<boolean>(true);

  const onTakeCardsClick = () => {
    onCardsDealed(question, selectedGameDeck?.id || '', cardsCount, cardFaceUp);
  };

  // клик по "Добавить колоду"
  const openChooseDecksModal = () => dispatch(setSidebarModals('choose-deck'));

  return (
    <CommonModal isOpen={isOpen} handleClose={closeModal} clickOutsideClose={false}>
      <div className={classes.GiveCardModal}>
        {/* close */}
        <i className={classes.GiveCardModal__close} onClick={closeModal}>
          <CloseIcon color={pandaColors.black._500} />
        </i>

        <h3>Дать карту</h3>
        {/* Вопрос */}
        <div className={classes.GiveCardModal__question}>
          <h4>Вопрос</h4>

          <div>
            <textarea onChange={(e) => onChangeTextarea(e.target.value)} value={question} rows={3} />
            <PenIcon />

            {isQuestionLimit && <span>Превышено количество символов</span>}
          </div>
        </div>
        {/* Выбранная колода */}
        <div className={classes.GiveCardModal__gameDeck}>
          <div>
            <h4>Выбранная колода</h4>
            <span onClick={openChooseDecksModal}>Добавить колоду</span>
          </div>

          <ul className={classes.GiveCardModal__slots}>
            {/* selected decks in slots */}
            {playerDecks.map((deck) => {
              const isSelected = selectedGameDeck?.id === deck.id;

              return (
                <li key={deck.id} className="filledSlot" title={deck.name}>
                  <i
                    className={`${isSelected ? 'isSelected' : ''}`}
                    style={{ backgroundImage: `url(${getAvatarLink(deck.dataLink)})` }}
                    onClick={() => onSelectGameDeck(deck)}
                  />
                  <span>{deck.name}</span>
                </li>
              );
            })}

            {/* fill empty slots */}
            {new Array(MAX_DECKS - playerDecks.length).fill('').map((_item, index) => {
              return (
                <li key={index} className="emptySlot">
                  <i />
                </li>
              );
            })}
          </ul>
        </div>
        {/* Количество карт */}
        <div className={classes.GiveCardModal__cardsCount}>
          <h4>Количество карт</h4>

          <ul>
            {[1, 2, 3, 4, 5, 6].map((item, index) => {
              return (
                <li
                  key={index}
                  className={`${cardsCount === item ? 'isSelected' : ''}`}
                  onClick={() => setCardsCount(item)}
                >
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
        {/* Раздача */}
        <div className={classes.GiveCardModal__dealingCard}>
          <h4>Раздача</h4>

          <ul>
            <li className={`${!cardFaceUp ? 'isSelected' : ''}`} onClick={() => setCardOpenFaceUp(false)}>
              В закрытую
            </li>

            <li className={`${cardFaceUp ? 'isSelected' : ''}`} onClick={() => setCardOpenFaceUp(true)}>
              В открытую
            </li>
          </ul>
        </div>

        {/* Дать карту */}
        <Button
          type="blue"
          onClick={onTakeCardsClick}
          style={{ width: '280px', height: '50px' }}
          disabled={selectedGameDeck === null}
        >
          Дать карту
        </Button>
      </div>
    </CommonModal>
  );
};

export default GiveCardModal;
