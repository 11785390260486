import React, { useEffect } from 'react';
import { selectClient, selectDoctor, selectIsDoctor } from '@store/room/roomSlice';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { VideoPlacement, setVideoState } from '@src/store/reducers/common';

interface InitialProps {
  doctor: VideoPlacement;
  client: VideoPlacement;
}

const VideoChatControlInternal: React.FC<InitialProps> = (props) => {
  const dispatch = useAppDispatch();
  const doctor = useAppSelector(selectDoctor);
  const client = useAppSelector(selectClient);

  useEffect(() => {
    if (doctor) {
      dispatch(setVideoState({ playerId: doctor.playerId, data: { placement: props.doctor } }));
    }

    if (client) {
      dispatch(setVideoState({ playerId: client.playerId, data: { placement: props.client } }));
    }
  }, [doctor, client]);

  return null;
};

export const VideoChatControl: React.FC = () => {
  const isDoctor = useAppSelector(selectIsDoctor);
  return isDoctor ? (
    <VideoChatControlInternal doctor={VideoPlacement.SMALL} client={VideoPlacement.LARGE} />
  ) : (
    <VideoChatControlInternal doctor={VideoPlacement.SMALL} client={VideoPlacement.SMALL} />
  );
};
