import { defaultObject } from '@src/common/generics';
declare let fbq: Function;

export enum FACEBOOK_EVENT {
  ROOM_CREATED = 'RoomCreated',
  ROOM_LINK_COPIED = 'RoomLinkCopied',
  GAME_ENTERED = 'GameEntered',
}

export const sendFacebookEvent = (event: FACEBOOK_EVENT, params = defaultObject) => {
  fbq('trackCustom', event, params);
};
