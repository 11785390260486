import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const MicrophoneOffIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M15.8296 17.6766L12.4257 14.2728C12.1914 14.0385 12.1914 13.6586 12.4257 13.4242C12.6601 13.1899 13.0399 13.1899 13.2743 13.4242L26.3963 26.5463C26.6306 26.7806 26.6306 27.1605 26.3963 27.3948C26.162 27.6291 25.7821 27.6291 25.5478 27.3948L23.7377 25.5847C22.7679 26.2403 21.6356 26.6514 20.4211 26.7374L20.3812 28.6865L21.9764 28.7289C22.3354 28.7289 22.5747 29.0255 22.5747 29.3644C22.5747 29.7458 22.2955 30 21.9764 30L17.5895 29.8729C17.2306 29.8729 16.9913 29.5763 16.9913 29.2373C16.9913 28.856 17.2705 28.6017 17.5895 28.6017L19.1848 28.6441L19.2246 26.695C15.6752 26.2713 12.9234 23.0087 13.0032 19.1529L13.043 17.7123C13.043 17.331 13.2823 17.0767 13.6413 17.0767C14.0002 17.0767 14.2395 17.3733 14.2395 17.7123L14.1996 19.1953C14.1198 22.585 16.6722 25.4239 19.8627 25.4663C20.936 25.4805 21.9461 25.1879 22.8171 24.6642L21.4364 23.2834C20.9593 23.4711 20.4418 23.5694 19.9027 23.5595C17.5896 23.5172 15.7551 21.4833 15.795 19.0258L15.8296 17.6766Z"
      fill={color}
    />
    <path
      d="M16.0984 13.1344L23.8328 20.8688C24.041 20.3547 24.1604 19.7899 24.17 19.1953L24.2897 14.5344C24.3295 12.0769 22.495 10.043 20.1819 10.0007C18.2719 9.96569 16.6337 11.2886 16.0984 13.1344Z"
      fill={color}
    />
    <path
      d="M25.111 22.147L26.0263 23.0623C26.5975 22.0226 26.935 20.8209 26.9616 19.5343L27.0015 18.0513C27.0015 17.6699 26.7223 17.3733 26.4033 17.3733C26.0842 17.3733 25.805 17.6276 25.805 18.0089L25.7652 19.4495C25.7422 20.424 25.5084 21.3389 25.111 22.147Z"
      fill={color}
    />
  </svg>
);

export default MicrophoneOffIcon;
