import React from 'react';
import { styles } from './Button.styles';

type TProps = {
  children: React.ReactNode | string;
  type?: 'gold' | 'blue' | 'white';
  style?: React.CSSProperties;
  onClick: () => void;
  mode?: 'fill' | 'outlined';
  disabled?: boolean;
};

const Button = ({ children, style, type = 'gold', mode = 'fill', disabled = false, onClick }: TProps) => {
  const classes = styles();

  return (
    <button disabled={disabled} onClick={onClick} className={`${classes.Button} ${type} ${mode}`} style={{ ...style }}>
      {children}
    </button>
  );
};

export default Button;
