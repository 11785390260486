// import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const PenIcon = ({ color = '#CFD7FC' }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      d="M15.1009 0.612255C15.4054 0.30769 15.8992 0.30769 16.2038 0.612255L18.9611 3.36958C19.2657 3.67414 19.2657 4.16794 18.9611 4.4725L17.3067 6.1269C17.0022 6.43146 16.5084 6.43146 16.2038 6.1269L13.4465 3.36958C13.1419 3.06501 13.1419 2.57121 13.4465 2.26665L15.1009 0.612255Z"
      fill={color}
    />
    <path
      d="M11.2406 4.4725C11.5452 4.16794 12.039 4.16794 12.3436 4.4725L15.1009 7.22982C15.4054 7.53439 15.4054 8.02819 15.1009 8.33275L6.04518 17.3885C5.96317 17.4705 5.86392 17.5331 5.75463 17.5719L1.47963 19.0896C0.860791 19.3093 0.264067 18.7126 0.483761 18.0938L2.00145 13.8188C2.04025 13.7095 2.10293 13.6102 2.18493 13.5282L11.2406 4.4725Z"
      fill={color}
    />
  </svg>
);

export default PenIcon;
