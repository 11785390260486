import { getDefaultLocale } from '@common/misc';
import { DEVICE_SIZE } from '@common/screenSizes';
import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { LOCALE } from '@shared/common';
import { ObjectType } from '@shared/generics';
import { RootState } from '@src/app/store';
import {
  createMergeDraftReducer,
  createPayload,
  createSimpleDraftReducer,
  simpleMergeThunk,
} from '@store/reduxHelpers';

const defaultDeviceInfo = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
};

const defaultWrapperOffsets = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const initialState = {
  preventShowLogin: false,
  isLoginShown: false, //showed login in this session
  isBannerVisible: true,
  homepageScroll: 0,
  history: {
    current: '',
    previous: '',
  },
  locale: getDefaultLocale(),
  wrapperOffsets: defaultWrapperOffsets,
  shouldUpdateOffsets: {} as ObjectType,
  deviceSize: DEVICE_SIZE.UNDEFINED,
  serverDiff: 0,
};

export type WrapperOffsets = typeof defaultWrapperOffsets;

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;
const simpleMerge = simpleMergeThunk<StateType, PayloadType>();

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionState: simpleMerge,
    setPreventShowLogin: (state, action: PayloadAction<boolean>) => {
      state.preventShowLogin = action.payload;
      state.isLoginShown = true;
    },
    setCurrentLocation: (state, action: PayloadAction<string>) => {
      if (state.history.current === action.payload) return state;
      // console.log(action.payload);
      return {
        ...state,
        history: {
          current: action.payload,
          previous: state.history.current,
        },
      };
    },
    setLocale: (state, action: PayloadAction<LOCALE>) => {
      return {
        ...state,
        locale: action.payload,
      };
    },
    setWrapperOffsets: (state, action: PayloadAction<Partial<WrapperOffsets>>) => {
      return {
        ...state,
        wrapperOffsets: {
          ...defaultWrapperOffsets,
          ...action.payload,
        },
      };
    },
    setShouldUpdateOffsets: (state, action: PayloadAction<ObjectType>) => {
      return {
        ...state,
        shouldUpdateOffsets: action.payload,
      };
    },
    setDeviceSize: (state, action: PayloadAction<DEVICE_SIZE>) => {
      return {
        ...state,
        deviceSize: action.payload,
      };
    },
    setServerDiff: createSimpleDraftReducer('serverDiff'),
  },
});

export const selectState = (state: RootState) => state.session;
export const selectPreventShowLogin = createSelector(selectState, (state) => state.preventShowLogin);
export const selectIsBannerVisible = createSelector(selectState, (state) => state.isBannerVisible);
export const selectHomepageScroll = createSelector(selectState, (state) => state.homepageScroll);
export const selectLocale = createSelector(selectState, (state) => state.locale);
export const selectIsEnLocale = createSelector(selectLocale, (locale) => locale === LOCALE.EN);
export const selectWrapperOffsets = createSelector(selectState, (state) => state.wrapperOffsets);
export const selectShouldUpdateOffsets = createSelector(selectState, (state) => state.shouldUpdateOffsets);

export const selectPreviousLocation = createSelector(selectState, (state) => state.history.previous);
export const selectCurrentLocation = createSelector(selectState, (state) => state.history.current);

export const selectDeviceSize = createSelector(selectState, (state) => state.deviceSize);
export const selectDeviceInfo = createSelector(selectDeviceSize, (deviceSize) => ({
  ...defaultDeviceInfo,
  [deviceSize]: true,
}));
export const selectServerDiff = createSelector(selectState, (state) => state.serverDiff);

// FOR COMPATIBILITY
export const getPreventShowLogin = selectPreventShowLogin;
export const getIsBannerVisible = selectIsBannerVisible;
export const getHomepageScroll = selectHomepageScroll;

export const {
  setSessionState,
  setPreventShowLogin,
  setCurrentLocation,
  setLocale,
  setWrapperOffsets,
  setShouldUpdateOffsets,
  setDeviceSize,
  setServerDiff,
} = sessionSlice.actions;
