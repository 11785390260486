import { makeStyles } from '@material-ui/core/styles';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles(() => ({
  ScrollContainerY: {
    position: 'relative',

    // если поставить flex будут проблемы со скроллом
    // если поставить block, то не будет растяжения во всю высоту
    display: 'grid',
    flexGrow: 1,
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    // y-scrollbar track width / height
    '&::-webkit-scrollbar': {
      width: 0,
      height: '4px',
    },

    // scrollbar track background (полоска)
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    // scrollbar thumb (бегунок)
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: pandaColors.black._600,
      borderRadius: '8px',
    },
  },

  ScrollContainerX: {
    display: 'flex',
  },
}));
