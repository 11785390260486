import googleIcon from '@art/icons/Social/google.svg';
import facebookIcon from '@art/icons/Social/facebook.svg';
import twitterIcon from '@art/icons/Social/twitter.svg';
import instagramIcon from '@art/icons/Social/instagram.svg';
import discordIcon from '@art/icons/Social/discord.svg';
import twitchIcon from '@art/icons/Social/twitch.svg';

export const loginSocialItems = [
  {
    type: 'Goggle',
    icon: googleIcon,
    iconSize: '24px 24px',
    iconPosition: '50% 50%',
  },
  {
    type: 'Facebook',
    icon: facebookIcon,
    iconSize: '22px 22px',
    iconPosition: '50% 50%',
  },
  {
    type: 'Twitter',
    icon: twitterIcon,
    iconSize: '24px 24px',
    iconPosition: '50% 50%',
  },
  {
    type: 'Instagram',
    icon: instagramIcon,
    iconSize: '25px 25px',
    iconPosition: '50% 57%',
  },
  {
    type: 'Discord',
    icon: discordIcon,
    iconSize: '25px 25px',
    iconPosition: '50% 57%',
  },
  {
    type: 'Twitch',
    icon: twitchIcon,
    iconSize: '24px 24px',
    iconPosition: '54% 57%',
  },
];
