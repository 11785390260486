import { Button, CommonModal } from '@pc/components/ui';
import { IdType } from '@src/shared/generics';
import pandaColors from '@src/_pandacards/helpers/colors';
import { CloseIcon } from '@src/_pandacards/components/icons';
import { useAppSelector } from '@src/app/hooks';
import { selectCardBack } from '@src/store/room/roomFeaturesData.slice';
import { ICard, TableCard, TakeCardData } from '@src/shared/sessionInterfaces/standardSession.socket';
import { styles } from './TakeCardsClosedModal.styles';
import { SimpleCard } from '../../../parts';
import { CardPlacement } from '../../../standardSession.types';

type TProps = {
  isOpen: boolean;
  takeCard: TakeCardData;
  onCardTaken: (deckId: IdType | undefined, index: number) => void;
};

const TakeCardsClosedModal: React.FC<TProps> = ({ isOpen, takeCard, onCardTaken }) => {
  const classes = styles();
  const cardBack = useAppSelector(selectCardBack);
  const cardData: TableCard = { cardId: '', cardBack, cardFace: '', faceUp: false };

  // клик на "Взять"
  const onCardTakenClick = () => {
    onCardTaken(takeCard.deckId, 0);
  };

  // клик на крестик
  const onCardNotTakenClick = () => {
    onCardTaken(undefined, 0);
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={() => null}>
      <div className={classes.TakeCardsClosedModal}>
        {/* close */}
        <i className={classes.TakeCardsClosedModal__close} onClick={onCardNotTakenClick}>
          <CloseIcon color={pandaColors.black._500} />
        </i>

        <h3>
          Выберите карту <span>{takeCard.takenAmount + 1}</span>/<span>{takeCard.amount}</span>
        </h3>

        <div style={{ height: '480px', maxWidth: '480px', margin: 'auto', marginBottom: '30px' }}>
          <SimpleCard data={cardData} placement={CardPlacement.MODAL} slotAspect={{ height: 480, width: 480 }} />
        </div>

        <Button type="blue" onClick={onCardTakenClick}>
          Взять
        </Button>
      </div>
    </CommonModal>
  );
};

export default TakeCardsClosedModal;
