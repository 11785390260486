import { useParams } from 'react-router-dom';
import { CommonModal, Button } from '@pc/components/ui';
import WSClient from '@src/sockets';
import { useAuth } from 'oidc-react';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import { useAppSelector } from '@src/app/hooks';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
import { styles } from './LeaveModal.styles';
import { selectIsDoctor } from '@src/store/room/roomSlice';

type TProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const LeaveModal = ({ isOpen, closeModal }: TProps) => {
  const classes = styles();
  const auth = useAuth();
  const isDoctor = useAppSelector(selectIsDoctor);
  const { roomId } = useParams<{ roomId: string }>();
  const playerId = useAppSelector(selectPlayerId);

  const handleLeaveRoom = () => {
    WSClient.emit(CLIENT_EVENTS.PLAYER_LEFT_ROOM, {
      playerId,
      roomId,
    });

    auth.signOutRedirect({ id_token_hint: auth.userData?.id_token });
  };

  return (
    <CommonModal isOpen={isOpen} handleClose={closeModal}>
      <div className={`${classes.LeaveModal} ${!isDoctor ? 'isClient' : ''}`}>
        <h3>Выйти из кабинета</h3>
        <p>
          Вы уверены, что хотите звершить сеанс
          <br /> и покинуть кабинет?
        </p>

        <div>
          <Button type="blue" onClick={closeModal}>
            Остаться
          </Button>

          <Button type="white" onClick={handleLeaveRoom}>
            Выйти
          </Button>
        </div>
      </div>
    </CommonModal>
  );
};

export default LeaveModal;
