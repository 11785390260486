import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    socialList: {
      display: 'flex',
      width: '290px',
      justifyContent: 'space-between',
      padding: 0,
      margin: 0,
      marginBottom: '41px',
      listStyle: 'none',
    },
  })
);
