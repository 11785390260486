import React from 'react';
import { useAuth } from 'oidc-react';
import { useAppSelector } from '@src/app/hooks';
import { selectPlayerWeak } from '@src/store/reducers/player.reducer';
import { PeriodicUpdate } from '@src/library/debug/PeriodicUpdate';
import { RenderPromise } from '@src/library/debug/RenderPromise';
import { fetchApi } from '@library/fetch';
import { Canvas } from '@src/components/common/canvas';
import CopyToClipboard from 'react-copy-to-clipboard';

function timeToExpire(now: number, target: number | undefined) {
  if (!target) {
    return 'N/A';
  }
  return target - now / 1000;
}

export const BackendAuth: React.FC = () => {
  const authPlayer = useAppSelector(selectPlayerWeak);
  const auth = useAuth();

  const copiedText = `{\n\t"authorization": "${auth.userData?.token_type} ${auth.userData?.access_token}"\n}`;

  return (
    // @ts-ignore
    <Canvas>
      <ul style={{ display: 'flex', flexDirection: 'column', width: '80vw', padding: '50px' }}>
        <li style={{ wordBreak: 'break-all' }}>User: {authPlayer?.name}</li>
        <li style={{ wordBreak: 'break-all' }}>User ID: {authPlayer?.publicId}</li>
        <li style={{ wordBreak: 'break-all' }}>
          Expired?:
          <PeriodicUpdate
            callback={(time) => {
              return (
                <>
                  {time} - {auth.userData?.expired ? 'yes' : 'no'}
                </>
              );
            }}
          />
        </li>
        <li style={{ wordBreak: 'break-all' }}>
          Expires At:
          <PeriodicUpdate callback={() => auth.userData?.expires_at} />
        </li>
        <li style={{ wordBreak: 'break-all' }}>
          Expires In:
          <PeriodicUpdate
            callback={(now) => {
              if (now) {
                return timeToExpire(now, auth.userData?.expires_at);
              }
            }}
          />
        </li>

        <CopyToClipboard text={copiedText}>
          <li style={{ wordBreak: 'break-all', cursor: 'pointer' }}>
            AccessToken: {auth.userData?.token_type} {auth.userData?.access_token}
          </li>
        </CopyToClipboard>
        <li style={{ wordBreak: 'break-all' }}>RefreshToken: {auth.userData?.refresh_token}</li>
        <li style={{ wordBreak: 'break-all' }}>
          Secured:{' '}
          <PeriodicUpdate
            callback={(time) => {
              const rs = fetchApi('/secured');
              return (
                <>
                  {time} - <RenderPromise promise={rs} />
                </>
              );
            }}
          />
        </li>
      </ul>
      <br />
      <br />
      <br />
      <br />
      <br />
      {/*<h2>Frontend:</h2>
      <Checkbox checked /> Move auth above everything <br />
      <Checkbox checked /> Configure Apollo client with auth headers <br />
      <Checkbox checked /> Configure fetch with auth headers <br />
      <Checkbox checked /> Handle 403 errors <br />
      <Checkbox checked /> Automatically refresh token <br />
      <Checkbox checked /> Add logger for debug purposes <br />
      <Checkbox /> Test GraphQL for 401/403 <br />
      <Checkbox /> Test Socket.io when token expires <br />
      <Checkbox /> Remove all requests to backend w/o auth <br />
      <Checkbox /> Replace login window with new auth process <br />
      <h2>Backend</h2>
      <Checkbox checked /> Middle ware to verify JWT <br />
      <Checkbox checked /> Auth existing user with JWT <br />
      <Checkbox checked /> Read JWT data from request <br />
      <Checkbox /> Create public id for user <br />
      <Checkbox /> Auth by sub from JWT <br />*/}
    </Canvas>
  );
};
