import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const ToggleVideoToBig = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <rect width="36" height="36" rx="2" fill="black" fillOpacity="0.6" />
    <path
      d="M6 5C5.44772 5 5 5.44772 5 6V12.5C5 13.3284 5.67157 14 6.5 14C7.32843 14 8 13.3284 8 12.5V8H12.5C13.3284 8 14 7.32843 14 6.5C14 5.67157 13.3284 5 12.5 5H6Z"
      fill={color}
    />
    <path
      d="M8 28H12.5C13.3284 28 14 28.6716 14 29.5C14 30.3284 13.3284 31 12.5 31H6C5.44772 31 5 30.5523 5 30V23.5C5 22.6716 5.67157 22 6.5 22C7.32843 22 8 22.6716 8 23.5V28Z"
      fill={color}
    />
    <path
      d="M23.5 5C22.6716 5 22 5.67157 22 6.5C22 7.32843 22.6716 8 23.5 8H28V12.5C28 13.3284 28.6716 14 29.5 14C30.3284 14 31 13.3284 31 12.5V6C31 5.44772 30.5523 5 30 5H23.5Z"
      fill={color}
    />
    <path
      d="M22 29.5C22 28.6716 22.6716 28 23.5 28H28V23.5C28 22.6716 28.6716 22 29.5 22C30.3284 22 31 22.6716 31 23.5V30C31 30.5523 30.5523 31 30 31H23.5C22.6716 31 22 30.3284 22 29.5Z"
      fill={color}
    />
  </svg>
);

export default ToggleVideoToBig;
