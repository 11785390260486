import { analytics } from '@src/services/amplitude';
import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

interface RouterProps {
  link: string;
}

interface Props extends RouteComponentProps<RouterProps> {}

const Link: React.FC<Props> = ({ match, location }) => {
  useEffect(() => {
    analytics.logEvent(analytics.EVENTS.LINK, { link: match.params.link });
  }, []);

  return <Redirect to="/" />;
};

export { Link };
