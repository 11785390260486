import { PayloadAction } from '@reduxjs/toolkit';
import { ObjectType } from '@src/common/generics';

export const simpleMerge = <State, Payload>(state: State, action: PayloadAction<Payload>): State => ({
  ...state,
  ...action.payload,
});

export const simpleMergeThunk = <State, Payload>() => (state: State, action: PayloadAction<Payload>): State => ({
  ...state,
  ...action.payload,
});

export const createPayload = <Payload extends ObjectType>(payload: Payload) => ({
  payload: payload,
});

export const createSimpleReducer = <State, K extends keyof State>(key: K) => (
  state: State,
  action: PayloadAction<State[K]>
) => ({
  ...state,
  [key]: action.payload,
});

export const createSimpleDraftReducer = <State, K extends keyof State>(key: K) => (
  state: State,
  action: PayloadAction<State[K]>
) => {
  state[key] = action.payload;
};

export const createMergeReducer = <State>() => simpleMergeThunk<State, Partial<State>>();

export const createMergeDraftReducer = <State, K extends keyof State>(key: K) => (
  state: State,
  action: PayloadAction<State[K]>
) => {
  state[key] = {
    ...state[key],
    ...action.payload,
  };
};

// Should be Pick<State, K>
/*export const createSimpleReducerDeprecated = <State, K extends keyof State, T>(key: K) => ({
  reducer: simpleMergeThunk<State, Partial<State>>(),
  prepare: (value: State[K]) => createPayload({ [key]: value }),
});*/
