import React, { useEffect, useState } from 'react';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { selectPlayerRoomId } from '@src/store/reducers/player.reducer';
import { LoaderWsConnect } from '@src/_pandacards/components/ui';
import { getIsPlayerLoading } from '@src/store/reducers/loadActions';
import { explicitLoginThunkPanda } from '@src/store/thunk/login.thunk';
import { LoginType } from '@src/shared/common';

export const Cabinet: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isPlayerLoading = useAppSelector(getIsPlayerLoading);
  const playerRoomId = useAppSelector(selectPlayerRoomId);
  const [initialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (playerRoomId && !isPlayerLoading) {
      history.push(`/room/${playerRoomId}`, { from: FROM_LOCATIONS.HOME_PAGE });
    }
  }, [playerRoomId, isPlayerLoading]);

  useEffect(() => {
    if (!playerRoomId && !initialized) {
      dispatch(explicitLoginThunkPanda(LoginType.CREATE_ROOM));
      setIsInitialized(true);
    }
  }, [playerRoomId, initialized]);

  return <LoaderWsConnect />;
};
