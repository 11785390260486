import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

const { black, primary } = pandaColors;

export const styles = makeStyles(() => ({
  CopyRoomLink: {
    flexDirection: 'column',
    alignItems: 'center',
  },

  CopyRoomLink__link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '70px',
    padding: '20px',
    backgroundColor: black._700,
    marginBottom: '22px',
    cursor: 'pointer',
    '&:hover': {
      '& rect': {
        stroke: primary.blue,
      },
      '& path': {
        fill: primary.blue,
      },
    },

    '& > div': {
      display: 'block',
      color: primary.blue,
      fontSize: '20px',
      maxWidth: '400px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 600,
      '&::selection': {
        backgroundColor: primary.gold,
        color: black._900,
      },
    },

    '& > i': {
      '& rect, & path': {
        transition: 'all 0.23s',
      },
    },
  },

  CopyRoomLink__button: {
    '& > button': {
      width: '328px',
    },
  },

  CopyRoomLink__isLinkCopied: {
    position: 'absolute',
    bottom: '30px',
    color: primary.gold,
    animationDuration: '0.23s',
    fontSize: '18px',
    fontWeight: 500,
  },
}));
