import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const CopyIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg width="26" height="31" viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_128_15209" fill="white">
      <rect x="6" y="0.5" width="20" height="24" rx="2" />
    </mask>
    <rect
      x="6"
      y="0.5"
      width="20"
      height="24"
      rx="2"
      stroke={color}
      strokeWidth="6"
      mask="url(#path-1-inside-1_128_15209)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6.5H2C0.895431 6.5 0 7.39543 0 8.5V28.5C0 29.6046 0.895431 30.5 2 30.5H18C19.1046 30.5 20 29.6046 20 28.5V26.5H17V27.5H3V9.5H4V6.5Z"
      fill={color}
    />
  </svg>
);

export default CopyIcon;
