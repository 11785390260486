import { AMPLITUDE_API_KEY } from '@src/common/constants/system';
import amplitude from 'amplitude-js';
import { defaultObject, ObjectType } from '@src/common/generics';

// const API_KEY = '035b1897f28fad2cbf938f5bb04e4bf0';

namespace analytics {
  export enum EVENTS {
    FIRST_LAUNCH = 'FIRST_LAUNCH',
    SESSION_STARTED = 'SESSION_STARTED',
    ROOM_CREATED = 'ROOM_CREATED',
    PLAYER_JOINED_ROOM = 'PLAYER_JOINED_ROOM',
    PLAYER_JOINED_GAME = 'PLAYER_JOINED_GAME',
    PLAYER_FINISHED_GAME = 'PLAYER_FINISHED_GAME',
    PARTY_STARTED = 'PARTY_STARTED',
    PARTY_ENDED = 'PARTY_ENDED',
    ROUND_STARTED = 'ROUND_STARTED',
    UNITY_STARTED = 'UNITY_STARTED',
    UNITY_ENDED = 'UNITY_ENDED',
    PARTY_RATED = 'PLAYER_RATED',
    SOCIAL_LINK_CLICKED = 'SOCIAL_LINK_CLICKED',
    PLAYER_LEFT_GAME = 'PLAYER_LEFT_GAME',
    PLAYER_LEFT_ROOM = 'PLAYER_LEFT_ROOM',
    EXIT_TO_HOMEPAGE = 'EXIT_TO_HOMEPAGE',
    ROOM_LINK_COPIED = 'ROOM_LINK_COPIED',

    GAME_CLICKED = 'GAME_CLICKED',
    VIEW_ALL_CLICKED = 'VIEW_ALL_CLICKED',
    BANNER_CLICKED = 'BANNER_CLICKED',
    PARTY_CLICKED = 'PARTY_CLICKED',
    CREATE_ROOM_CLICKED = 'CREATE_ROOM_CLICKED',
    ENTER_ROOM_CLICKED = 'ENTER_ROOM_CLICKED',
    INVITE_CLICKED = 'INVITE_CLICKED',
    STICKER_SEND_CLICKED = 'STICKER_SEND_CLICKED',

    MONEY_SENT = 'MONEY_SENT',
    MONEY_RECIEVED = 'MONEY_RECIEVED',

    APP_QUIT = 'APP_QUIT',
    PAGE_SHOWN = 'PAGE_SHOWN',
    HOMEPAGE_SHOWN = 'HOMEPAGE_SHOWN',
    GAME_SET_SHOWN = 'GAME_SET_SHOWN',
    GAME_INTRO_SHOWN = 'GAME_INTRO_SHOWN',
    INVITE_POPUP_SHOWN = 'INVITE_POPUP_SHOWN',

    AGORA_JOINED = 'AGORA_JOINED',
    AGORA_PERMISSION = 'AGORA_PERMISSION',

    VIDEO_STARTED = 'VIDEO_STARTED',
    VIDEO_FINISHED = 'VIDEO_FINISHED',

    HITS_CLAIMED = 'HITS_CLAIMED',
    HITS_RECEIVED = 'HITS_RECEIVED',

    INTRO_SHOWN = 'INTRO_SHOWN',
    INTRO_GO = 'INTRO_GO',
    INTRO_ANSWER_CLICKED = 'INTRO_ANSWER_CLICKED',
    INTRO_RESULT_CLICKED = 'INTRO_RESULT_CLICKED',
    TAP = 'TAP',

    LINK = 'LINK',

    CLAIM_SHOWN = 'CLAIM_SHOWN',
    CLAIM_CLOSED = 'CLAIM_CLOSED',
    CLAIM_CLAIMED = 'CLAIM_CLAIMED',

    QUEST_DONE = 'QUEST_DONE',
    QUEST_CLAIMED = 'QUEST_CLAIMED',

    SHARE_QUESTION = 'SHARE_QUESTION',
  }

  export enum ROOM_JOIN_REASON {
    TOOLTIP = 'TOOLTIP',
    LOGIN = 'LOGIN',
    BANNER = 'BANNER',
    GAME_BANNER = 'GAME_BANNER',
    WELCOME_BANNER = 'WELCOME_BANNER',
    ROOM_LINK = 'ROOM_LINK',
    CREATE_ROOM = 'ENTER_ROOM',
  }

  export enum INVITE_FROM {
    BANNER = 'BANNER',
    TOOLTIP = 'TOOLTIP',
    VIDEO = 'VIDEO',
    AUTO = 'AUTO',
    PARTY_CARD = 'PARTY_CARD',
  }

  export const initialize = () => {
    if (AMPLITUDE_API_KEY) {
      amplitude.getInstance().init(AMPLITUDE_API_KEY);
      // amplitude.getInstance().setOptOut(true);
    }
  };

  export const setUserId = (playerId: string) => {
    if (AMPLITUDE_API_KEY) {
      amplitude.getInstance().setUserId(playerId);
    }
  };

  export const setUserProperties = (params: ObjectType) => {
    if (AMPLITUDE_API_KEY) {
      amplitude.getInstance().setUserProperties(params);
    }
  };

  export const startSession = (playerId: string, isNewPlayer: boolean) => {
    setUserId(playerId);
    logEvent(EVENTS.SESSION_STARTED, { isNewPlayer });
  };

  export const logEvent = (event: EVENTS, params = defaultObject) => {
    if (AMPLITUDE_API_KEY) {
      amplitude.getInstance().logEvent(event, params);
    }
  };
}

export { analytics };
