import React from 'react';

import TextField from '@material-ui/core/TextField';
import { useStyles } from './styles';

const TextInput = ({ ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      InputLabelProps={{
        classes: {
          outlined: classes.lable,
        },
      }}
      InputProps={{
        classes: {
          input: classes.input,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.formHelperEmail,
        },
      }}
      className={classes.root}
      {...props}
    />
  );
};

export default TextInput;

// USE
// import TextInput from '../../../components/Inputs/TextInput';
// <InputEmail id="..." label="Label" variant="outlined" type="..." />
