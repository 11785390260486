import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { fontFamily } from '../../art/theme/textStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    socialItem: {
      fontFamily,
      display: 'flex',
      height: '48px',
      width: '100%',
      alignItems: 'center',
      marginBottom: '10px',
      borderRadius: '4px',
      overflow: 'hidden',
      textDecoration: 'none',
      transition: 'all .23s',
      boxSizing: 'border-box',

      [theme.breakpoints.up('sm')]: {
        height: '44px',
        marginBottom: '20px',
      },

      '&.Goggle': {
        backgroundColor: '#DC4E41',
        '&:hover': { backgroundColor: '#CA4539' },
        '&:active': { backgroundColor: '#BD3B2F' },
        '& .color-box': { backgroundColor: '#BD3B2F' },
      },

      '&.Facebook': {
        backgroundColor: '#5180E4',
        '&:hover': { backgroundColor: '#4C77D0' },
        '&:active': { backgroundColor: '#4870C6' },
        '& .color-box': { backgroundColor: '#4870C6' },
      },

      '&.Twitter': {
        backgroundColor: '#20ACFB',
        '&:hover': { backgroundColor: '#23A2EA' },
        '&:active': { backgroundColor: '#229CE1' },
        '& .color-box': { backgroundColor: '#229CE1' },
      },

      '&.Instagram': {
        backgroundColor: '#EA4C89',
        '&:hover': { backgroundColor: '#DA447E' },
        '&:active': { backgroundColor: '#D33A73' },
        '& .color-box': { backgroundColor: '#D33A73' },
      },

      '&.Discord': {
        backgroundColor: '#827DDC',
        '&:hover': { backgroundColor: '#655BBE' },
        '&:active': { backgroundColor: '#5046AF' },
        '& .color-box': { backgroundColor: '#5046AF' },
      },

      '&.Twitch': {
        backgroundColor: '#9146FF',
        '&:hover': { backgroundColor: '#7836D8' },
        '&:active': { backgroundColor: '#6441A5' },
        '& .color-box': { backgroundColor: '#6441A5' },
      },

      '& .color-box': {
        width: '48px',
        height: '48px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '22px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        boxSizing: 'border-box',

        [theme.breakpoints.up('sm')]: {
          width: '44px',
          height: '44px',
          marginRight: '12px',
        },
      },

      '& p': {
        fontSize: '15px',
        lineHeight: '18px',
        color: 'white',

        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '16px',
        },
      },
    },
  })
);
