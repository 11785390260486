import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';
import cardBackSideImage from '@pc/assets/images/card-backside.svg';
import checkedSlotImage from '@pc/assets/icons/checked-slot.svg';

export const styles = makeStyles(() => ({
  GiveCardModal: {
    position: 'relative',
    zIndex: 1,
    fontFamily: 'SF Pro Display',
    width: '990px',
    height: '700px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    color: pandaColors.black._900,
    '& h3': {
      fontSize: '32px',
      fontStyle: 'normal',
      fontWeight: 600,
      marginTop: 0,
      marginBottom: '10px',
    },
    '& h4': {
      marginTop: 0,
      marginBottom: '5px',
      fontSize: '20px',
      fontWeight: 600,
      letterSpacing: '0.5px',
    },
  },

  GiveCardModal__close: {
    position: 'absolute',
    zIndex: 2,
    right: '5px',
    top: '5px',
    display: 'block',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    '& path': {
      transition: 'all 0.23s',
    },
    '&:hover path': {
      fill: pandaColors.black._900,
    },
  },

  GiveCardModal__question: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '22px',

    '& > div': {
      position: 'relative',
      zIndex: 1,
      '& textarea': {
        width: '100%',
        padding: '12px 20px',
        fontSize: '18px',
        fontWeight: 400,
        borderRadius: '10px',
        border: '1px solid #E7EBFE',
        fontFamily: 'SF Pro Display',
        backgroundColor: pandaColors.white._25,
        letterSpacing: '0.25px',
        resize: 'none',
        '& + svg path': {
          transition: 'all 0.23s',
        },

        '&:focus': {
          '& + svg path': {
            fill: pandaColors.primary.blue,
          },
        },
      },

      '& svg': {
        position: 'absolute',
        zIndex: 2,
        display: 'block',
        width: '20px',
        height: '20px',
        right: 10,
        bottom: 10,
      },

      '& > span': {
        position: 'absolute',
        left: 20,
        bottom: 12,
        paddingTop: '10px',
        color: pandaColors.red,
      },
    },
  },

  GiveCardModal__gameDeck: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',

    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      '& > span': {
        fontSize: '20px',
        fontWeight: 500,
        letterSpacing: '0.5px',
        transition: 'all 0.23s',
        color: pandaColors.primary.blue,
        '&:hover': {
          cursor: 'pointer',
          color: pandaColors.primary.blueHover,
        },
      },
    },
  },

  GiveCardModal__slots: {
    display: 'flex',
    listStyle: 'none',
    maxWidth: '100%',
    margin: 0,
    paddingLeft: 0,
    '& > li': {
      width: '84px',
      marginRight: '10px',
      flexShrink: 0,
      flexGrow: 0,
      minHeight: '150px',
      textAlign: 'center',
      '& > i': {
        display: 'block',
        height: '120px',
        borderRadius: '5px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      },

      // empty slot
      '&.emptySlot': {
        '& > i': {
          border: '1px solid #E7EBFE',
          backgroundColor: pandaColors.white._25,
          backgroundImage: `url(${cardBackSideImage})`,
        },
      },

      // filled slot
      '&.filledSlot': {
        zIndex: 1,

        '& > i': {
          position: 'relative',
          borderRadius: '5px',
          marginBottom: '9px',
          transition: 'all 0.23s',
          '&.isSelected': {
            '&::after': {
              content: '""',
              position: 'absolute',
              zIndex: 2,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '5px',
              border: `4px solid ${pandaColors.primary.blue}`,
              backgroundImage: `url(${checkedSlotImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.40)',
            },
          },
          '&:hover': {
            cursor: 'pointer',
          },
        },

        '& > span': {
          display: 'block',
          fontSize: '16px',
          fontWeight: 400,
          color: pandaColors.black._900,
          transition: 'all 0.23s',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'default',
        },
      },

      '&:last-child': {
        marginRight: 0,
      },
    },
  },

  GiveCardModal__cardsCount: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: '20px',
    '& > ul': {
      margin: 0,
      marginTop: '5px',
      paddingLeft: 0,
      display: 'inline-flex',
      listStyle: 'none',
      // borderRadius: '5px',
      '& > li': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50px',
        height: '50px',
        fontSize: '18px',
        fontWeight: 500,
        color: '#9EAFFA',
        borderTop: '1px solid #E7EBFE',
        borderBottom: '1px solid #E7EBFE',
        backgroundColor: pandaColors.white._25,
        transition: 'all 0.1s',

        '&:first-child': {
          borderLeft: '1px solid #E7EBFE',
          borderRadius: '5px 0 0 5px',
        },

        '&:last-child': {
          borderRight: '1px solid #E7EBFE',
          borderRadius: '0 5px 5px 0',
        },

        '&.isSelected': {
          color: 'white',
          backgroundColor: pandaColors.primary.blue,
          border: `1px solid ${pandaColors.primary.blue}`,
          '&:hover': {
            color: 'white',
          },
        },
        '&:hover': {
          cursor: 'pointer',
          color: pandaColors.primary.blueHover,
        },
      },
    },
  },

  GiveCardModal__dealingCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '30px',

    '& > ul': {
      display: 'flex',
      margin: 0,
      paddingLeft: 0,
      listStyle: 'none',
      '& > li': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '225px',
        height: '50px',
        fontSize: '18px',
        fontWeight: 500,
        color: '#9EAFFA',
        backgroundColor: pandaColors.white._25,
        transition: 'all 0.1s',
        border: '1px solid #E7EBFE',

        '&:first-child': {
          borderRadius: '5px 0 0 5px',
        },

        '&:last-child': {
          borderRadius: '0 5px 5px 0',
        },
        '&.isSelected': {
          color: 'white',
          backgroundColor: pandaColors.primary.blue,
          border: `1px solid ${pandaColors.primary.blue}`,
          '&:hover': {
            color: 'white',
          },
        },
        '&:hover': {
          cursor: 'pointer',
          color: pandaColors.primary.blueHover,
        },
      },
    },
  },
}));
