import { makeStyles } from '@material-ui/core/styles';

export const textStyles = makeStyles((theme) => ({
  textFS14: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: '17px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      lineHeight: '22px',
    },
  },
}));

export const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');
