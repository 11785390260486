import { MixProps } from '@src/common/generics';
import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { ActionsType, HookType } from '../../standardSession.hook';
import { Aspect } from '../cardMath';
import { SimpleCard } from '../Card/Card';
import _, { first } from 'lodash';
import { CardPlacement } from '../../standardSession.types';

interface Props {
  hook: HookType;
  slotIndex: number;
  slotAspect: Aspect;
  placement?: CardPlacement;
}

const EnlargedSlot: React.FC<MixProps<Props>> = ({
  hook,
  slotIndex,
  slotAspect,
  className,
  placement = CardPlacement.ENLARGED,
}) => {
  const session = hook.session;
  const cards: TableCard[] = session.slots[slotIndex].cards.map(
    (cardId) => session.cards.find((card) => card.cardId === cardId)!
  );
  const isFirstCardWord = Boolean(_.first(cards)?.word);
  const firstCard = _.first(cards);
  const lastCard = !isFirstCardWord || cards.length > 1 ? _.last(cards) : undefined;
  const innerAspect = { height: slotAspect.height * 0.7, width: slotAspect.width * 0.7 };
  const realAspect = isFirstCardWord ? innerAspect : slotAspect;

  return (
    <div className={className} style={{ height: '100%', width: '100%', display: 'grid' }}>
      {firstCard && (
        <SimpleCard data={firstCard} actions={hook.actions} placement={placement} slotAspect={slotAspect} />
      )}
      {lastCard && <SimpleCard data={lastCard} actions={hook.actions} placement={placement} slotAspect={realAspect} />}
    </div>
  );
};

export { EnlargedSlot };
