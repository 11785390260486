import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    flexDirection: 'column',
    margin: 'auto',
    '& > h3': {
      position: 'relative',
      fontWeight: 800,
      textTransform: 'uppercase',
      fontSize: '32px',
      lineHeight: '42px',
      marginBottom: '12px',
      width: '100%',
      borderBottom: '1px solid #E8E9EB',
      paddingBottom: '10px',
      '&::before': {
        position: 'absolute',
        content: '""',
        display: 'block',
        height: '1px',
        width: '200px',
        left: '-200px',
        bottom: '-1px',
        backgroundColor: '#E8E9EB',
        [theme.breakpoints.up('sm')]: {
          width: '35px',
          left: '-35px',
        },
      },
      '&::after': {
        position: 'absolute',
        content: '""',
        display: 'block',
        height: '1px',
        width: '200px',
        right: '-200px',
        bottom: '-1px',
        backgroundColor: '#E8E9EB',
        [theme.breakpoints.up('sm')]: {
          width: '35px',
          right: '-35px',
        },
      },
    },
  },

  backgroundsList: {
    flexWrap: 'wrap',
    maxHeight: '300px',
    overflowY: 'scroll',
    paddingTop: '5px',
    paddingBottom: '75px',
    width: '350px',
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    ['@media (min-width: 420px)']: {
      width: '367px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '490px',
      maxHeight: '490px',
    },
    [theme.breakpoints.up('md')]: {
      width: '625px',
      maxHeight: '470px',
    },
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
    '& > div': {
      flexGrow: 0,
      height: '107px',
      flexBasis: '107px',
      maxWidth: '107px',
      marginBottom: '16px',
      marginRight: '13px',
      borderRadius: '4px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      border: '2px solid transparent',
      '&:nth-child(3n)': {
        marginRight: 0,
      },
      [theme.breakpoints.up('sm')]: {
        marginBottom: '15px',
        '&:nth-child(3n)': {
          marginRight: '15px',
        },
        '&:nth-child(4n)': {
          marginRight: 0,
        },
      },
      [theme.breakpoints.up('md')]: {
        '&:nth-child(3n)': {
          marginRight: '15px',
        },
        '&:nth-child(4n)': {
          marginRight: '15px',
        },
        '&:nth-child(5n)': {
          marginRight: 0,
        },
      },
      '&.active': {
        border: '2px solid #2C74F3 !important',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  btnContainer: {
    position: 'absolute',
    height: '96px',
    backgroundImage: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)',
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    '& > button': {
      marginTop: 'auto',
      '&.secondary': {
        backgroundColor: 'transparent',
      },
    },
  },
}));
