import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { useStyles } from './styles';
import helloLogoImg from '@art/icons/heloLogo.svg';
import SocialList from './parts/SocialList/SocialList';
import LoginForm from './parts/LoginForm/LoginForm';

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const { loginPage, whiteBox, helloLogo, title, divider, haveAcount } = classes;

  return (
    <div className={loginPage}>
      <div className={whiteBox}>
        <img src={helloLogoImg} className={helloLogo} />
        <Typography className={title}>Sign in with your social account</Typography>
        <SocialList />
        <div className={divider}>
          <span>or</span>
        </div>
        <LoginForm />
        <Typography className={haveAcount}>
          Don’t have an account yet? <Link href="#">Sign up</Link>
        </Typography>
      </div>
    </div>
  );
};

export default LoginPage;
