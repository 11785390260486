import { AUTH_CALLBACK_LOCATION, LOCATION_PATH_PARAM, authProps } from '../auth.config';
import { AuthProvider, AuthProviderProps, hasCodeInUrl, useAuth, User, UserManager } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Route, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectPlayerWeak, setLocalPlayer } from '@src/store/reducers/player.reducer';
import { authLoginThunk, autoLoginThunkPanda, loginThunk } from '@src/store/thunk/login.thunk';
import { AUTH_TYPE } from '@src/shared/common';
import ConnectToServerLoader from '@src/components/ConnectToServerLoader';
import { authSignInSilent } from '../auth.helper';

export const SecuredLogic: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const auth = useAuth();
  const authPlayer = useAppSelector(selectPlayerWeak);
  const sub = auth.userData?.profile.sub;

  const onSignedIn = () => {
    const location = localStorage.getItem(LOCATION_PATH_PARAM) || '/';
    localStorage.removeItem(LOCATION_PATH_PARAM);
    console.log(location);
    history.replace(location);
  };

  useEffect(() => {
    if (sub && sub !== authPlayer?.publicId) {
      console.log('secured', sub, authPlayer?.publicId);
      onSignedIn();
      // dispatch(loginThunk('', onSignedIn, onSignedIn, true, AUTH_TYPE.AUTH));
    }
  }, [sub, authPlayer]);

  return auth.isLoading ? <ConnectToServerLoader /> : <></>;
};

export const SecurityCookie: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    console.log('NEW APP RENDER');
    const publicId = localStorage.getItem('publicId') || localStorage.getItem('id') || '';
    const name = localStorage.getItem('name') || '';
    const authType = (localStorage.getItem('authType') as AUTH_TYPE) || AUTH_TYPE.NONE;

    dispatch(setLocalPlayer({ publicId, name, authType }));
  }, []);

  useEffect(() => {
    if (isInitialized || auth.isLoading) return;

    const shouldAutologin = location.pathname === '/deeplink';

    authSignInSilent()
      .then(() => {
        dispatch(autoLoginThunkPanda());
      })
      .catch(() => {
        // dispatch(oldLoginThunk());
        //return wasLogged ? auth.signIn() : auth.signOut();
        return auth.signOut();
        // return auth.signOutRedirect({ id_token_hint: auth.userData?.id_token });
      })
      .finally(() => setIsInitialized(true))
      .then(() => auth.userManager.clearStaleState());
  }, [auth.isLoading]);

  return isInitialized ? <>{children}</> : <></>;
};

export const SecurityProvider: React.FC = ({ children }) => {
  const beforeRedirect = () => {
    // DOES NOT WORK
    console.log('BEFORE REDIRECT', location.pathname);
    // localStorage.setItem(LOCATION_PATH_PARAM, location.pathname);
  };

  const onSignIn: AuthProviderProps['onSignIn'] = (userData) => {
    console.log('ON SIGN IN', userData);
  };

  const onSignOut: AuthProviderProps['onSignOut'] = (options) => {
    // console.log('ON SIGN OUT');
    // window.location.assign('/');
  };

  // {...authProps}
  return (
    <AuthProvider {...authProps} onBeforeSignIn={beforeRedirect} onSignIn={onSignIn} onSignOut={onSignOut}>
      <SecurityCookie>{children}</SecurityCookie>
    </AuthProvider>
  );
};
