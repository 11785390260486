import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const MicrophoneOnIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M19.9013 23.5595C22.2144 23.6019 24.1287 21.6528 24.1685 19.1953L24.2882 14.5344C24.3281 12.0769 22.4935 10.043 20.1804 10.0007C17.8673 9.95831 15.953 11.9074 15.9131 14.3649L15.7935 19.0258C15.7536 21.4833 17.5881 23.5172 19.9013 23.5595Z"
      fill={color}
    />
    <path
      d="M26.4018 17.3733C26.0827 17.3733 25.8036 17.6276 25.8036 18.0089L25.7637 19.4495C25.6839 22.8392 23.0518 25.5086 19.8613 25.4663C16.6708 25.4239 14.1184 22.585 14.1981 19.1953L14.238 17.7123C14.238 17.3733 13.9987 17.0767 13.6398 17.0767C13.2809 17.0767 13.0416 17.331 13.0416 17.7123L13.0017 19.1529C12.9219 23.0087 15.6737 26.2713 19.2232 26.695L19.1833 28.6441L17.588 28.6017C17.269 28.6017 16.9898 28.856 16.9898 29.2373C16.9898 29.5763 17.2291 29.8729 17.588 29.8729L21.975 30C22.294 30 22.5732 29.7458 22.5732 29.3644C22.5732 29.0255 22.3339 28.7289 21.975 28.7289L20.3797 28.6865L20.4196 26.7374C24.0089 26.4832 26.8804 23.3901 26.9601 19.5343L27 18.0513C27 17.6699 26.7208 17.3733 26.4018 17.3733Z"
      fill={color}
    />
  </svg>
);

export default MicrophoneOnIcon;
