import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
  direction?: 'left' | 'right';
};

const Arrow = ({ color = pandaColors.white._50, direction = 'left' }: TProps) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ rotate: direction === 'left' ? '0deg' : '180deg' }}
  >
    <path
      d="M27.7322 37.2678C28.7085 38.2441 30.2915 38.2441 31.2678 37.2678C32.2441 36.2915 32.2441 34.7085 31.2678 33.7322L20.3076 22.7721L31.2678 11.8119C32.2441 10.8356 32.2441 9.2527 31.2678 8.27639C30.2915 7.30008 28.7085 7.30008 27.7322 8.27639L13.9437 22.065C13.5531 22.4555 13.5531 23.0887 13.9437 23.4792L27.7322 37.2678Z"
      fill={color}
    />
  </svg>
);

export default Arrow;
