import React, { useEffect, useState } from 'react';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginRenameModal } from '@components/Modals';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { selectIsAuthenticated } from '@src/store/reducers/player.reducer';
import { getIsPlayerLoading } from '@src/store/reducers/loadActions';
import { useAuth } from 'oidc-react';
import { LOCATION_PATH_PARAM } from '@src/features/auth/auth.config';
import { LoaderWsConnect } from '@src/_pandacards/components/ui';
import { explicitLoginThunkPanda, loginThunkPanda } from '@src/store/thunk/login.thunk';
import { LoginType } from '@src/shared/common';

const getStoredLocation = () => {
  const storedString = localStorage.getItem(LOCATION_PATH_PARAM);
  return storedString ? JSON.parse(storedString) : undefined;
};

const getTargetLocation = (location: unknown): { target: any; autologin?: boolean; loginType?: LoginType } => {
  return location || getStoredLocation() || { target: { pathname: '/' } };
};

export const PandaLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const isLoading = useAppSelector(getIsPlayerLoading);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [initialized, setIsInitialized] = useState(false);
  const auth = useAuth();
  const showLoading = !initialized || isAuthenticated || isLoading;
  const targetLocation = getTargetLocation(location.state);

  const onLogin = () => {
    localStorage.removeItem(LOCATION_PATH_PARAM);

    console.log(targetLocation, location);
    history.push(targetLocation.target, { from: FROM_LOCATIONS.LOGIN });
  };

  useEffect(() => {
    if (!auth.isLoading && !auth.userData) {
      console.log(location);
      localStorage.setItem(LOCATION_PATH_PARAM, JSON.stringify(targetLocation));
      console.log(JSON.stringify(targetLocation));
      auth.signIn();
    }

    if (auth.userData) {
      setIsInitialized(true);
    }
  }, [auth]);

  useEffect(() => {
    if (isAuthenticated) onLogin();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!showLoading) {
      dispatch(explicitLoginThunkPanda(targetLocation.loginType || LoginType.CREATE_PLAYER));
    }
  }, [showLoading]);

  // return showLoading ? <LoaderWsConnect /> : <LoginRenameModal onSubmit={() => {}} handleClose={() => {}} />;
  return <LoaderWsConnect />;
};
