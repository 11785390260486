import { makeStyles } from '@material-ui/core';

import FacebookLogo from '@art/images/social/facebook-logo.png';
import TelegramLogo from '@art/images/social/telegram.png';
import TwitterLogo from '@art/images/social/twitter.png';
import WhatsappLogo from '@art/images/social/whatsapp.png';

export const styles = makeStyles((theme) => ({
  inviteWrapper: {
    position: 'relative',
    flexDirection: 'column',
    color: '#1B2036',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '12px',
    '& > *': {
      boxSizing: 'border-box',
    },
    '&::after': {
      content: '""',
      display: 'block',
      width: '100vw',
      position: 'fixed',
      bottom: '48px',
      height: '1px',
      backgroundColor: 'white',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    '& > p': {
      fontSize: '17px',
      lineHeight: '22px',
      marginBottom: '5px',
      letterSpacing: '-0.5px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        lineHeight: '21px',
      },
    },
  },

  inviteTitle: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2px',
    '& > img': { marginRight: '6px' },
    '& > span': {
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '25px',
      lineHeight: '24px',
      letterSpacing: '-0.75px',
      textTransform: 'uppercase',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        lineHeight: '36px',
        letterSpacing: 0,
      },
    },
  },

  inviteSubTitle: {
    fontFamily: 'Nunito',
    fontWeight: 900,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.75px',
    textTransform: 'uppercase',
    marginTop: 0,
    textAlign: 'center',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      letterSpacing: 0,
    },
  },

  closeContainer: {
    position: 'absolute',
    display: 'inlie-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    top: '-60px',
    right: '-10px',
    backgroundColor: 'rgba(255,255,255, 0.75)',
    '& svg path': { fill: 'black' },
    [theme.breakpoints.up('sm')]: {
      top: '-47px',
      right: '-60px',
    },
    [theme.breakpoints.up('md')]: {
      top: '-40px',
      right: '-40px',
    },
  },

  linkContainer: {
    position: 'relative',
    paddingLeft: '20px',
    paddingRight: '15px',
    alignItems: 'center',
    backgroundColor: '#F3F4F5',
    width: '100%',
    margin: 'auto',
    minHeight: '44px',
    border: '1px solid #E8E9EB',
    borderRadius: '22px',

    // url text
    '& > span': {
      display: 'block',
      width: 'calc(100% - 130px)',
      color: '#5F6372',
      fontSize: '15px',
      lineHeight: '18px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
  },

  button: {
    position: 'absolute',
    top: '-1px',
    right: 0,
    width: '131px !important',
    height: '44px !important',
    borderRadius: '22px',
    '& > span': {
      fontWeight: '500 !important',
      fontSize: '15px !important',
      lineHeight: '18px !important',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
    },
  },

  shareList: {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    marginBottom: '18px',
    '& > li': {
      marginRight: '10px',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },

  shareItem: {
    width: '55px',
    height: '55px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundColor: 'white',
    '&.telegram': {
      backgroundImage: `url(${TelegramLogo})`,
    },
    '&.whatsapp': {
      backgroundImage: `url(${WhatsappLogo})`,
    },
    '&.facebook': {
      backgroundImage: `url(${FacebookLogo})`,
    },
    '&.twitter': {
      backgroundImage: `url(${TwitterLogo})`,
    },
  },
}));
