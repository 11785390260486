import React, { RefObject, useContext, useState } from 'react';

type ChangeWordType = (word: string) => void;

export interface CardContextProps {
  isTooltipOpen: boolean;
  canEnterWord: boolean;
  getBoundingRect: () => DOMRect;
  setTooltipOpen: (shouldOpen: boolean) => void;
  openTooltip: (x: number, y: number, element: RefObject<HTMLDivElement>) => void;
  onWordChanged: ChangeWordType;
}

export const CardContext = React.createContext<CardContextProps | undefined>(undefined);
export const useCardContext = (): CardContextProps => {
  const context = useContext<CardContextProps | undefined>(CardContext);

  if (!context) {
    throw new Error('Attempt to use CardContext out of CardProvider');
  }

  return context;
};

export const createCardContext = (canEnterWord: boolean, onWordChanged: ChangeWordType) => {
  //const [context, setContext] = useState<CardContextProps>(() => createContext(canEnterWord, onWordChanged));

  //return context;

  return {
    canEnterWord,
    onWordChanged,
  };
};
