import { styles } from './ImagineQuestion.styles';

type TProps = {
  sessionQuestion: string;
};

const ImagineQuestion = ({ sessionQuestion }: TProps) => {
  const classes = styles();

  return (
    <div className={classes.ImagineQuestion}>
      {sessionQuestion ? <p>{sessionQuestion}</p> : <span>Придумайте вопрос...</span>}
    </div>
  );
};

export default ImagineQuestion;
