import React, { useState, useEffect, Fragment, useRef, useImperativeHandle } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import { ICard, TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { styles } from './Card.styles';
import { MixProps } from '@src/common/generics';
import { CardPlacement } from '../../standardSession.types';
import { ActionsType } from '../../standardSession.hook';
import { useCardContext } from './useCardContext';
import { Aspect, defaultAspect } from '../cardMath';
import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';

interface WordProps {
  word: string;
}

const WordInternal: React.FC<WordProps> = (data) => {
  const [word, setWord] = useState<string>('');
  const context = useCardContext();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setWord(data.word || '');
  }, [data.word]);

  useEffect(() => {
    if (context.canEnterWord) {
      inputRef.current?.focus();
    }
  }, [context.canEnterWord]);

  const onWordChange: React.ChangeEventHandler<HTMLInputElement> = (evt) => {
    evt.stopPropagation();
    setWord(evt.target.value);
  };

  const onBlur = () => {
    context.onWordChanged(word);
  };

  return (
    <Fragment>
      <input
        type="text"
        value={word}
        className="input"
        onChange={onWordChange}
        onBlur={onBlur}
        readOnly={!context.canEnterWord}
        ref={inputRef}
      />
      <span className="left">{word}</span>
      <span className="right">{word}</span>
      <span className="bottom">{word}</span>
    </Fragment>
  );
};

interface Props {
  data: TableCard;
  placement: CardPlacement;
  slotAspect?: Aspect;
}

const CardInternal = React.forwardRef<HTMLDivElement, MixProps<Props>>(function CardInternal(
  { data, placement, slotAspect },
  ref
) {
  const classes = styles();
  const isFaceUp = data.faceUp === undefined || data.faceUp;
  const image = isFaceUp ? data.cardFace : data.cardBack;
  const isWordCard = isFaceUp && Boolean(data.word);
  const context = useCardContext();
  const innerRef = useRef<HTMLDivElement>(null);
  const [aspect, setAspect] = useState<Aspect>(defaultAspect);
  const [layout, setLayout] = useState<{ width?: string; height?: string }>({ width: '0px', height: '0px' });
  const isDoctor = useAppSelector(selectIsDoctor);

  useImperativeHandle(ref, () => innerRef.current!);

  useEffect(() => {
    // Safari specific. For chrome its enough to write height: 100% or width: 100% based on aspect.
    if (slotAspect && slotAspect.height > 0 && aspect.height > 0) {
      const slotAspectDiff = slotAspect.height / slotAspect.width;
      const aspectDiff = aspect.height / aspect.width;

      const layout =
        aspectDiff > slotAspectDiff
          ? { height: `${slotAspect.height}px`, width: 'auto' }
          : { width: `${slotAspect.width}px`, height: 'auto' };
      setLayout(layout);
    }
  }, [slotAspect, aspect]);

  const onLoad = (evt: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = evt.currentTarget;
    setAspect({ height: target.naturalHeight, width: target.naturalWidth });
  };

  return (
    <div
      ref={innerRef}
      onClick={(evt) => context.openTooltip(evt.clientX, evt.clientY, innerRef)}
      className={`${classes.Card} ${isWordCard ? 'isWordCard' : ''} ${!isDoctor ? 'isClient' : ''} ${placement}`}
      style={layout}
    >
      <img src={image} alt={image} onLoad={onLoad} style={layout} />
      {isWordCard && <WordInternal word={data.word!} />}
    </div>
  );
});

export { CardInternal };
