import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles((theme) => ({
  TakeCardsClosedModal: {
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '520px',
    padding: '25px 25px 30px',
    fontFamily: 'SF Pro Display',
    boxSizing: 'border-box',

    // client mobile
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
    '& > *': {
      boxSizing: 'border-box',
    },

    '& > h3': {
      fontSize: '32px',
      fontWeight: 600,
      marginTop: 0,
      marginBottom: '25px',
      textAlign: 'center',

      // client mobile
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '31px',
        marginBottom: '14px',
      },
    },

    // card container
    '& > div': {
      flexGrow: 1,
      minHeight: '360px',
      marginBottom: '30px',
    },

    '& > button': {
      height: '50px',
      width: '280px',
    },
  },

  TakeCardsClosedModal__close: {
    position: 'absolute',
    zIndex: 2,
    right: '5px',
    top: '5px',
    display: 'block',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    '& path': {
      transition: 'all 0.23s',
    },
    '&:hover path': {
      fill: pandaColors.black._900,
    },
  },
}));
