import { ClickAwayListener } from '@material-ui/core';
import Modal from '@material-ui/core/Modal/Modal';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';
import Fade from '@material-ui/core/Fade/Fade';
import { MixProps } from '@src/common/generics';
import { styles } from './CommonModal.styles';
import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';
import { selectWrapperOffsets } from '@src/store/reducers/session.reducer';

type TProps = {
  isOpen: boolean;
  handleClose: () => void;
  isFullscreen?: boolean;
  clickOutsideClose?: boolean;
  children: React.ReactNode;
};

const CommonModal: React.FC<MixProps<TProps>> = ({
  isOpen,
  handleClose,
  isFullscreen = false,
  clickOutsideClose = true,
  style,
  className = '',
  children,
}) => {
  const isDoctor = useAppSelector(selectIsDoctor);
  const wrapperOffsets = useAppSelector(selectWrapperOffsets);
  const classes = styles(wrapperOffsets);

  if (!isOpen) return <></>;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      className={`
        ${classes.CommonModal}
        ${!isDoctor ? 'isClient' : ''}
        ${isFullscreen ? 'isFullscreen' : ''}
      `}
      BackdropProps={{ timeout: 500 }}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      style={isFullscreen ? {} : { ...wrapperOffsets }}
    >
      <Fade in={isOpen}>
        <div className={`${classes.CommonModal__backdrop} CommonModal__backdrop`}>
          <ClickAwayListener onClickAway={clickOutsideClose ? handleClose : () => null}>
            <div
              style={style}
              className={`
                ${classes.CommonModal__modal}
                ${className}
                CommonModal__modal
              `}
            >
              {children}
            </div>
          </ClickAwayListener>
        </div>
      </Fade>
    </Modal>
  );
};

export default CommonModal;
