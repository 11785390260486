import { useEffect, useState } from 'react';
import { PenIcon } from '@pc/components/icons';
import { styles } from './QuestionInput.styles';

type TProps = {
  sessionQuestion: string;
  onQuestionMade: (val: string) => void;
};

const QuestionInput: React.FC<TProps> = ({ sessionQuestion, onQuestionMade }) => {
  const classes = styles();
  const [question, setQuestion] = useState<string>('');
  const [isQuestionLimit, setQuestionLimit] = useState<boolean>(false);
  const [textAreaRows, setTextAreaRows] = useState(1);

  function calculateTextareaRows(value: string): void {
    if (value.length < 35) {
      setTextAreaRows(1);
    }

    if (value.length > 35) {
      setTextAreaRows(2);
    }

    if (value.length > 75) {
      setTextAreaRows(3);
    }
  }

  useEffect(() => {
    // вычисление кол-ва строк textarea при загрузке
    calculateTextareaRows(sessionQuestion);

    // подставление вопроса в textarea
    setQuestion(sessionQuestion);
  }, [sessionQuestion]);

  function onChangeTextarea(value: string) {
    calculateTextareaRows(value);

    if (value.length >= 107) {
      setQuestionLimit(true);
    } else {
      setQuestion(value);
      setQuestionLimit(false);
    }
  }

  return (
    <div className={classes.QuestionInput}>
      <h3>Вопрос:</h3>

      <textarea
        rows={textAreaRows}
        value={question}
        placeholder="Впишите вопрос.."
        onChange={(e) => onChangeTextarea(e.target.value)}
        onBlur={() => onQuestionMade(question)}
      />

      <PenIcon />

      {isQuestionLimit && <span>Превышено количество символов</span>}
    </div>
  );
};

export default QuestionInput;
