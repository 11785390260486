import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import { LoginModal, CreateRoomModal } from '@components/Modals';
import Button from '@components/Button';
import FullRoomModal from '@components/Modals/FullRoomModal';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  debugPage: {
    fontFamily,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      background: '#F3F4F5',
    },
    '& *': {
      boxSizing: 'border-box',
    },
  },
}));

const DebugPage: React.FC = () => {
  const classes = useStyles();

  // create room modal
  const [isCreateRoomModalOpen, setCreateRoomModalOpen] = useState(false);
  const handleCreateRoomModal = () => {
    setCreateRoomModalOpen(true);
  };

  // login modal
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const handleLoginModal = () => {
    setLoginModalOpen(true);
  };

  // full room modal
  const [isFoolRoomModalOpen, setFullRoomModalOpen] = useState(false);
  const handleFullRoomModal = () => setFullRoomModalOpen(true);

  const handleCloseModal = () => {
    setLoginModalOpen(false);
    setCreateRoomModalOpen(false);
    setFullRoomModalOpen(false);
  };

  const onSubmitCreateRoom = (event: any) => {
    event.preventDefault();
    setCreateRoomModalOpen(false);
    alert('Create room submit');
  };

  const onSubmitEnterName = () => {
    // event:any
    //event.preventDefault();
    setLoginModalOpen(false);
    alert('Enter name submited');
  };

  return (
    <section className={classes.debugPage}>
      <h2 style={{ marginBottom: '50px' }}>Debug page</h2>
      <Button text="Create room" onClick={handleCreateRoomModal} type="primary-blue" />
      <br />
      <Button text="Enter name" onClick={handleLoginModal} type="primary-blue" />
      <br />
      <Button text="Full Room" onClick={handleFullRoomModal} type="primary-blue" />

      <CreateRoomModal isOpen={isCreateRoomModalOpen} handleClose={handleCloseModal} onSubmit={onSubmitCreateRoom} />

      <LoginModal isOpen={isLoginModalOpen} handleClose={handleCloseModal} onSubmit={onSubmitEnterName} />

      <FullRoomModal isOpen={isFoolRoomModalOpen} />
    </section>
  );
};

export default DebugPage;
