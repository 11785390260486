import { gql } from '@apollo/client';
import { IdType } from '@shared/generics';
import { GameTypes } from '@src/common/constants/common';
import { AUTH_TYPE } from '@src/shared/common';
import { IString, stringFragment } from '@src/shared/misc/string.types';
import {
  PLAY_QUEST_FAMILY,
  PLAY_QUEST_OWNER,
  PLAY_QUEST_PUBLICITY,
  QUEST_COST,
  QUEST_STATE,
  QUEST_TYPE,
} from '@src/shared/quests';

export interface IRewards {
  balance: number;
  claimed: number;
  end: number;
}

export const rewardsFragment = gql`
  fragment RewardsFragment on Rewards {
    balance
    claimed
    end
  }
`;

export interface IEnergy {
  amount: number;
  end: number;
}

export const createDefaultEnergy = () => ({ amount: 0, end: 0 });

export const energyFragment = gql`
  fragment EnergyFragment on Energy {
    amount
    end
  }
`;

interface IPlayerParty {
  partyId: IdType;
  rate: number;
}

interface IQuestReward {
  coins: number;
  energy: number;
}

export type QuestData = {
  gameId: IdType;
  gameName: string;
  minFriends: number;
  family: PLAY_QUEST_FAMILY;
  owner: PLAY_QUEST_OWNER;
  publicity: PLAY_QUEST_PUBLICITY;
  cost: QUEST_COST;
  text: IString;
  link: string;
};

export interface IPlayerQuestQl {
  id: IdType;
  type: QUEST_TYPE;
  state: QUEST_STATE;
  value: number;
  target: number;
  reward: IQuestReward;
  data: QuestData;
}

export const questFragment = gql`
  fragment PlayerQuestFragment on PlayerQuest {
    id
    type
    state
    value
    target
    reward {
      coins
      energy
    }
    data {
      gameId
      gameName
      minFriends
      family
      owner
      publicity
      cost
      text {
        ...StringFragment
      }
      link
    }
  }
  ${stringFragment}
`;

export interface IPlayerData {
  parties: IPlayerParty[];
  quests: IPlayerQuestQl[];
}

export const playerDataFragment = gql`
  fragment PlayerDataFragment on PlayerData {
    parties {
      partyId
      rate
    }
    quests {
      ...PlayerQuestFragment
    }
  }
  ${questFragment}
`;

export interface IPlayer {
  publicId: IdType;
  name: string;
  autocreate: boolean;
  avatarId: string;
  authType: AUTH_TYPE;
  rewards: IRewards;
  energy: IEnergy;
  cardDecks: IdType[];
}

export interface PlayerAuth extends IPlayer {
  newPlayer: boolean;
}

export const playerAuthFragment = gql`
  fragment PlayerAuthFragment on PlayerAuth {
    publicId
    name
    autocreate
    created
    avatarId
    newPlayer
    authType
    cardDecks
  }
`;
