import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const CloseIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M14.0711 13.9289C13.5681 14.4319 13.5681 15.2473 14.0711 15.7503L18.3208 20L14.0711 24.2497C13.5681 24.7527 13.5681 25.5681 14.0711 26.0711C14.574 26.574 15.3894 26.574 15.8924 26.0711L20.1421 21.8213L24.3919 26.0711C24.8948 26.574 25.7103 26.574 26.2132 26.0711C26.7161 25.5681 26.7162 24.7527 26.2132 24.2497L21.9635 20L26.2132 15.7503C26.7161 15.2473 26.7161 14.4319 26.2132 13.9289C25.7103 13.426 24.8948 13.426 24.3919 13.9289L20.1421 18.1787L15.8924 13.9289C15.3894 13.426 14.574 13.426 14.0711 13.9289Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
