import { useState } from 'react';
import { Button, CommonModal } from '@pc/components/ui';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';
import { IdType } from '@src/shared/generics';
import { Arrow, CloseIcon } from '@pc/components/icons';
import pandaColors from '@src/_pandacards/helpers/colors';
import { useDeckCards } from './useDeckCards';
import { styles } from './TakeCardsOpenedModal.styles';
import { CardPlacement } from '../../../standardSession.types';
import { TakeCardData, UsedDeck } from '@src/shared/sessionInterfaces/standardSession.socket';
import { SimpleCard } from '../../../parts';

type TProps = {
  isOpen: boolean;
  takeCard: TakeCardData;
  onCardTaken: (deckId: IdType | undefined, index: number) => void;
};

const TakeCardsOpenedModal: React.FC<TProps> = ({ isOpen, takeCard, onCardTaken }) => {
  const classes = styles();
  const cards = useDeckCards(takeCard.taken, takeCard.deckId);
  const [slidePosition, setSlidePosition] = useState<number>(0);
  const SwipeableViewsVirtualize = virtualize(SwipeableViews);

  // клик на "Взять"
  const onCardTakenClick = () => {
    onCardTaken(takeCard.deckId, slidePosition);
  };

  // клик на крестик
  // удалять карту из колоды, если взяли или не взяли ???
  const onCardNotTakenClick = () => {
    onCardTaken(undefined, 0);
  };

  const rotateLeft = () => setSlidePosition(slidePosition <= 0 ? cards.length - 1 : slidePosition - 1);
  const rotateRight = () => setSlidePosition(slidePosition + 1 < cards.length ? slidePosition + 1 : 0);

  return (
    <CommonModal isOpen={isOpen} handleClose={() => null} style={{ overflow: 'inherit' }}>
      <div className={classes.TakeCardsOpenedModal}>
        {/* close */}
        <i className={classes.TakeCardsOpenedModal__close} onClick={onCardNotTakenClick}>
          <CloseIcon color={pandaColors.black._500} />
        </i>

        {/* Выберите карту */}
        <h3>
          Выберите карту <span>{takeCard.takenAmount + 1}</span>/<span>{takeCard.amount}</span>
        </h3>

        <section className={classes.TakeCardsOpenedModal__slider}>
          {/* Слайдер с виртуальным списком */}
          {cards.length ? (
            <div className={`${classes.TakeCardsOpenedModal__sliderWrapper} TakeCardsOpenedModal__sliderWrapper`}>
              <SwipeableViewsVirtualize
                axis="x"
                index={slidePosition}
                slideCount={cards.length}
                className={classes.TakeCardsOpenedModal__sliderVirtualList}
                onChangeIndex={(slidePosition) => setSlidePosition(slidePosition)}
                slideRenderer={({ index, key }) => (
                  <div key={key} style={{ height: '480px', maxWidth: '480px', margin: 'auto' }}>
                    <SimpleCard
                      data={cards[index]}
                      placement={CardPlacement.MODAL}
                      slotAspect={{ height: 480, width: 480 }}
                    />
                  </div>
                )}
              />
            </div>
          ) : null}

          {/* Стрелочки */}
          <div className={`${classes.TakeCardsOpenedModal__sliderControls} TakeCardsOpenedModal__sliderControls`}>
            <i
              // className={`${slidePosition === 0 ? 'disabled' : ''}`}
              onClick={rotateLeft}
            >
              <Arrow />
            </i>
            <i
              // className={`${slidePosition === cards.length - 1 ? 'disabled' : ''}`}
              onClick={rotateRight}
            >
              <Arrow direction="right" />
            </i>
          </div>
        </section>

        {/* Взять */}
        <Button type="blue" onClick={onCardTakenClick}>
          Взять
        </Button>
      </div>
    </CommonModal>
  );
};

export default TakeCardsOpenedModal;
