import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  blueButtonHomePage: {
    flexShrink: 0,
    // marginRight: '15px',
    padding: '0px 25px',
    height: '40px !important',
    '& span': {
      fontSize: '24px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
    },
  },

  logout: {
    padding: '11px',
    width: 'auto !important',
    height: 'auto !important',
    background: 'white !important',
    boxShadow: 'none',
  },
}));
