import PandaMeditationImage from '@pc/assets/images/panda-meditation.svg';
import { styles } from './CardsWillBeSoon.styles';

const CardsWillBeSoon: React.FC = () => {
  const classes = styles();

  return (
    <div className={classes.CardsWillBeSoon}>
      <img src={PandaMeditationImage} alt="panda-invite.svg" />
      <h3>Скоро здесь появятся карты</h3>
    </div>
  );
};

export default CardsWillBeSoon;
