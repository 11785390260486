import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';

import Eye from '../../icons/Eye';
import { useStyles } from './styles';

interface Props {
  showPassword: boolean;
  errorPassword: boolean;
  password: string;
  handleChangePassword?: any;
  handleClickShowPassword?: any;
  handleMouseDownPassword?: any;
}

const PasswordInpunt: React.FC<Props> = ({
  showPassword,
  password,
  handleChangePassword,
  errorPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
}) => {
  const classes = useStyles();

  return (
    <FormControl variant="outlined" className={classes.rootPassword}>
      <InputLabel>Password</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={handleChangePassword}
        error={errorPassword}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              <Eye fill="#E8E9EB" />
              {showPassword}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
    </FormControl>
  );
};

export default PasswordInpunt;
