import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles(() => ({
  ChoosingCardsOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    flexGrow: 1,
    backgroundColor: 'rgba(0,0,0, .6)',
    alignItems: 'center',
    justifyContent: 'center',
  },

  ChoosingCardsOverlay__wrapper: {
    alignItems: 'center',
    flexDirection: 'column',

    // loader
    '& > div': {
      marginBottom: '10px',
    },

    // text
    '& > p': {
      textAlign: 'center',
      maxWidth: '175px',
      color: pandaColors.white._50,
      fontSize: '20px',
      fontWeight: 600,
    },
  },
}));
