import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { selectIsEnLocale } from '@store/reducers/session.reducer';
import { loginThunk } from '@src/store/thunk/login.thunk';
import { CommonModal } from '@components/Modals';
import { TextInput } from '@components/Inputs';
import Button from '@components/Button';
import { styles } from './styles';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import googleIcon from '@art/images/social/google.svg';
import facebookIcon from '@art/images/social/facebook.svg';
import twitterIcon from '@art/images/social/twitter.svg';
import vkontakteIcon from '@art/images/social/vkontakte.svg';
import { LoginSocialButton } from '@src/components/LoginButtons';
import { selectLocalPlayerName, selectPlayerWeak } from '@src/store/reducers/player.reducer';
import { useAuth } from 'oidc-react';
import { AUTH_TYPE } from '@src/shared/common';
import { getIsPlayerLoading } from '@src/store/reducers/loadActions';

interface Props {
  isOpen?: boolean;
  handleClose: () => void;
  onSubmit: () => void;
  ignoreOnError?: boolean;
  shouldAutologin?: boolean;
  nextPage?: string;
}

const getFormikData = (name: string | undefined, login: (name: string) => void) => ({
  initialValues: {
    userName: name || '',
  },
  onSubmit: (values: { userName: string }) => {
    login(values.userName);
  },
});

const LoginModal: React.FC<Props> = ({
  isOpen = true,
  handleClose,
  onSubmit,
  ignoreOnError = false,
  shouldAutologin = true,
  nextPage,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const player = useAppSelector(selectPlayerWeak);
  //const [logging, setLogging] = useState(true);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const name = useAppSelector(selectLocalPlayerName);
  const isLogging = useAppSelector(getIsPlayerLoading);

  // const onReject = () => (ignoreOnError ? undefined : setLogging(false));
  const onReject = () => {};

  const login = (shouldAutologin: boolean, authType: AUTH_TYPE, name?: string) =>
    dispatch(loginThunk(name || '', onSubmit, onReject, shouldAutologin, authType));
  const formik = useFormik(getFormikData(name, (name) => login(true, AUTH_TYPE.UID, name)));

  /*useEffect(() => {
    // optimize if we do not have proper ids?
    console.log('should login', !player);
    if (!player) {
      login(shouldAutologin, AUTH_TYPE.NONE);
    }
  }, []);*/

  /*if (isLogging) {
    // block all screen
    return <></>;
  }*/

  const iconsArray = [googleIcon, facebookIcon, twitterIcon, vkontakteIcon];
  const renderLinks = () =>
    iconsArray.map((icon, index) => <LoginSocialButton icon={icon} key={index} nextPage={nextPage} />);

  return (
    <CommonModal isOpen={isOpen} handleClose={handleClose} isFullscreen={true} backgroundColor={'white'}>
      <div className={classes.wrapper}>
        <h3 id="transition-modal-title">{isEnLocale ? 'Log In' : 'Войдите'}</h3>
        <form onSubmit={formik.handleSubmit} id="enter-name-id" noValidate autoComplete="off">
          <div className={classes.formInner}>
            <TextInput
              onChange={formik.handleChange}
              value={formik.values.userName}
              id="userName"
              name="userName"
              label={isEnLocale ? 'User name' : 'Имя пользователя'}
              variant="outlined"
              type="text"
            />

            <Button
              text={isEnLocale ? 'Next' : 'Далее'}
              buttonType="submit"
              type="primary-blue-new"
              disabled={isLogging || !formik.values.userName}
            />

            {
              <div className={classes.socialNetworks}>
                <p>
                  Or <br /> sign in with your social account
                </p>
                <div>{renderLinks()}</div>
              </div>
            }
          </div>
        </form>
      </div>
    </CommonModal>
  );
};

export default LoginModal;
