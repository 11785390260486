import { useAuth } from 'oidc-react';
import { useAppSelector } from '@src/app/hooks';
import { selectIsFullAuthenticated } from '@src/store/reducers/player.reducer';
import { selectIsEnLocale } from '@src/store/reducers/session.reducer';
import Button from '@components/Button';
import { MixProps } from '@src/common/generics';
import { useStyles } from './styles';
import { useState } from 'react';
import { LoginModal } from '../Modals';
import { useHistory, useLocation } from 'react-router-dom';
import logout from '@art/icons/logout.svg';

interface Props {
  icon: string;
  nextPage?: string;
}

const LoginSocialButton: React.FC<Props> = ({ icon, nextPage }) => {
  const history = useHistory();
  const auth = useAuth();
  const isAuthenticated = useAppSelector(selectIsFullAuthenticated);

  const onClick = () => {
    if (!auth.isLoading && !auth.userData && !isAuthenticated) {
      history.push({ pathname: '/login' }, { from: location, autologin: false });
      // might use #hash to call some popups
      //localStorage.setItem(LOCATION_PATH_PARAM, nextPage || location.pathname);
      // console.log(localStorage.getItem(LOCATION_PATH_PARAM), nextPage, location.pathname);
      //auth.signIn();
    }
  };

  return <img src={icon} onClick={onClick} />;
};

const LoginButton: React.FC<MixProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsFullAuthenticated);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const auth = useAuth();
  const classes = useStyles();

  const onClick = () => {
    // if (!auth.isLoading && !auth.userData && !isAuthenticated) {
    if (!isAuthenticated) {
      history.push({ pathname: '/login' }, { target: location, autologin: false });
      // localStorage.setItem(LOCATION_PATH_PARAM, location.pathname);
      // auth.signIn();
    } else {
      auth.signOutRedirect({ id_token_hint: auth.userData?.id_token });
      //window.location.assign(logoutUrl);
    }
  };

  return (
    <div style={props.style}>
      <Button
        className={isAuthenticated ? `${classes.logout}` : `${classes.blueButtonHomePage}`}
        type="primary-blue-new"
        text={isAuthenticated ? '' : isEnLocale ? 'Log In' : 'Вход'}
        onClick={onClick}
      >
        {isAuthenticated ? <img src={logout} alt="" /> : ''}
      </Button>
    </div>
  );
};

const MixedLoginButton: React.FC<MixProps> = (props) => {
  const [shouldShowLoginModal, setShouldShowLoginModal] = useState(false);
  const isEnLocale = useAppSelector(selectIsEnLocale);
  const classes = useStyles();
  const isAuthenticated = useAppSelector(selectIsFullAuthenticated);
  const auth = useAuth();

  const handleClose = () => {
    setShouldShowLoginModal(false);
  };

  const onLogInClick = () => {
    if (!auth.isLoading && !auth.userData && !isAuthenticated) {
      setShouldShowLoginModal(true);
    } else {
      auth.signOutRedirect({ id_token_hint: auth.userData?.id_token });
    }
  };

  const onSubmit = () => {
    handleClose();
  };

  console.log(auth.userData, auth.isLoading, !isAuthenticated);

  return (
    <div style={props.style}>
      <Button
        className={classes.blueButtonHomePage}
        type="primary-blue-new"
        text={isAuthenticated ? (isEnLocale ? 'Log Out' : 'Выход') : isEnLocale ? 'Log In' : 'Вход'}
        onClick={onLogInClick}
      />
      <LoginModal isOpen={shouldShowLoginModal} handleClose={handleClose} onSubmit={onSubmit} shouldAutologin={false} />
    </div>
  );
};

export { LoginButton, LoginSocialButton, MixedLoginButton };
