import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  root: {
    color: 'inherit',
  },

  wrapper: {
    color: 'inherit',
    flexDirection: 'column',
    '& > h3': {
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '42px',
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '15px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '5px',
      },
    },
  },

  button: {
    color: 'inherit',
  },

  btnContainer: {
    color: 'inherit',
    justifyContent: 'space-between',
  },
}));
