import { ScrollContainer } from '@pc/components/layout';
import { MixProps } from '@src/common/generics';
import { HowItWorksButton } from '../../common';
import { InviteBlock } from '@pc/pages/room/parts';
import { PersonItemBig } from '../../common/VideoChat/parts/PersonItem/PersonItemVariants';
import { LostConnection } from '@pc/sessions/standard/parts';
import { styles } from './DoctorLayout.styles';
import { selectCounterPartyIsOnline } from '@src/store/room/roomSlice';
import { useAppSelector } from '@src/app/hooks';

type TProps = {
  right?: React.ReactNode;
  bottom?: React.ReactNode;
};

export const DoctorLayout: React.FC<MixProps<TProps>> = (props) => {
  const classes = styles();
  const isOtherPlayerOnline = useAppSelector(selectCounterPartyIsOnline);

  return (
    <ScrollContainer>
      <main className={classes.DoctorLayout}>
        {/* top content */}
        <section className={classes.DoctorLayout__top}>
          <HowItWorksButton />
        </section>

        {/* center content */}
        <section className={classes.DoctorLayout__center}>
          <div style={{ flexGrow: 1 }}>
            {/* centerLeftContent - показываем большое окно с видео клиента */}
            <div className={classes.DoctorLayout__centerLeft}>
              {!isOtherPlayerOnline ? (<LostConnection isDoctor={true} />) : (<PersonItemBig />)}
            </div>

            {/* right content */}
            {props.right && <div className={classes.DoctorLayout__centerRight}>{props.right}</div>}
          </div>
        </section>

        {/* bottom content */}
        {props.bottom && <section className={classes.DoctorLayout__bottom}>{props.bottom}</section>}
      </main>
    </ScrollContainer>
  );
};

export const DoctorLobbyLayout: React.FC<MixProps<TProps>> = (props) => {
  const classes = styles();

  return (
    <ScrollContainer overflowXWidth={`700px`}>
      <main style={{ minWidth: 700, maxWidth: 'none', padding: '15px 30px 0' }} className={classes.DoctorLayout}>
        {/* top content */}
        <section className={classes.DoctorLayout__top}>
          <HowItWorksButton />
        </section>

        {/* center content */}
        <section className={classes.DoctorLayout__center}>
          <div style={{ flexGrow: 1 }}>
            {/* centerLeftContent */}
            <div className={classes.DoctorLayout__centerLeft}>
              <InviteBlock />
            </div>
          </div>
        </section>
      </main>
    </ScrollContainer>
  );
};
