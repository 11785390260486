import { makeStyles } from '@material-ui/core';

export const styles = makeStyles(() => ({
  Discarded: {
    position: 'relative',
    // width: '70px',
    maxWidth: '300px',
    height: '105px',
    marginRight: '10px',
    border: '10px',
    flexShrink: 0,
    flexGrow: 0,
  },
}));
