import { useAppSelector } from '@app/hooks';
import { sendDataToServer } from '@components/VideoChat/parts/helperMethods';
import { selectPlayerIdWeak } from '@src/store/reducers/player.reducer';
import React, { useEffect } from 'react';
import { useErrorBoundary } from 'use-error-boundary';

const ErrorBoundary: React.FC = ({ children }) => {
  const playerId = useAppSelector(selectPlayerIdWeak);
  const { ErrorBoundary, didCatch } = useErrorBoundary({
    onDidCatch: (error, errorInfo) => sendDataToServer(playerId || '', `${error.stack}`),
  });

  return <>{!didCatch && <ErrorBoundary>{children}</ErrorBoundary>}</>;
};

export { ErrorBoundary };
