import { Avatar } from './../../shared/misc/roomFeatures.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { createSelector } from 'reselect';
import { NUMBER_OF_PERSONS, PlayerStatus } from '@src/common/constants/system';
import {
  createMergeDraftReducer,
  createMergeReducer,
  createPayload,
  createSimpleDraftReducer,
} from '@store/reduxHelpers';
import { IdType } from '@shared/generics';
import { selectPlayerId, selectPlayerIdWeak } from '../reducers/player.reducer';
import { selectBackgrounds } from './roomFeaturesData.slice';

export enum PLAYER_STATE {
  LOBBY = 'LOBBY',
  JOIN_READY = 'JOIN_READY',
  GAME = 'GAME',
  QUIT_READY = 'QUIT_READY',
  ABSENT = 'ABSENT',
}

export enum ROOM_STATE {
  UNKNOWN = 'UNKNOWN',
  LOBBY = 'LOBBY',
  GAME = 'GAME',
}

export interface IRoomPlayer {
  playerId: IdType;
  name: string;
  state: PLAYER_STATE;
  isOnline: boolean;
  peerReady: boolean;
  avatarId: IdType;
  disconnectDate: number;
}

export interface IAgora {
  token: IdType;
  appId: IdType;
  videoProfile: string;
}

export type TModalType = 'settings' | 'choose-deck' | 'end-session' | 'leave' | '';

export interface IRoomState {
  roomId: IdType;
  name: string;
  state: ROOM_STATE;
  partyId: IdType;
  gameId: IdType;
  players: IRoomPlayer[];
  activeGameId: IdType;
  sessionId: IdType;
  isGameOver: boolean;
  backgroundId: IdType;
  isPublic: boolean;
  agora?: IAgora;
  questionNumber: number;
  sidebarModals: TModalType;
}

const initialState: IRoomState = {
  roomId: '',
  name: '',
  state: ROOM_STATE.UNKNOWN,
  partyId: '',
  gameId: '',
  players: [],
  activeGameId: '',
  sessionId: '',
  isGameOver: false,
  backgroundId: '',
  isPublic: false,
  questionNumber: 0,
  sidebarModals: '',
};

export const roomSlice = createSlice({
  name: 'room',
  initialState: initialState,
  reducers: {
    updatePlayerState: {
      reducer: (state, action: PayloadAction<{ playerId: IdType; state: PLAYER_STATE }>) => {
        const payload = action.payload;
        const player = state.players.find((player) => player.playerId === payload.playerId);
        if (player) {
          player.state = payload.state;
        }
      },
      prepare: (playerId: string, state: PLAYER_STATE) => createPayload({ playerId, state }),
    },
    setRoomName: createSimpleDraftReducer('name'),
    setRoomIsPublic: createSimpleDraftReducer('isPublic'),
    updateRoomState: createMergeReducer(),
    setInitialRoomState: (state, action: PayloadAction) => {
      return {
        ...state,
        ...initialState,
      };
    },
    updateAgoraToken: createMergeDraftReducer('agora'),
    setQuestionNumber: createSimpleDraftReducer('questionNumber'),
    setSidebarModals(state, { payload }: PayloadAction<TModalType>) {
      state.sidebarModals = payload;
    },
  },
});

export const {
  setInitialRoomState,
  setRoomName,
  updateAgoraToken,
  updatePlayerState,
  updateRoomState,
  setRoomIsPublic,
  setQuestionNumber,
  setSidebarModals,
} = roomSlice.actions;

export type PlayerDataOld = {
  name: string;
  gender: string;
  playerId: string;
  selectedGameId: string;
  isReady: boolean;
  playerOnlineStatus: boolean;
  playerStatus: PlayerStatus;
  avatarId: IdType;
};

const selectState = (state: RootState) => state.room || initialState;
export const selectRoom = createSelector(selectState, (state) => state);
export const selectRoomState = createSelector(selectState, (state) => state.state);
export const selectGameId = createSelector(selectState, (state) => state.gameId);
export const selectPartyId = createSelector(selectState, (state) => state.partyId);
export const selectAgora = createSelector(selectState, (state) => state.agora);
export const selectRoomPlayers = createSelector(selectState, (state) => state.players);
export const selectIsFullRoom = createSelector(selectState, (state) => state.players.length === NUMBER_OF_PERSONS);
export const selectIsInRoom = createSelector(selectState, (state) => state.state !== ROOM_STATE.UNKNOWN);
export const selectIsInGame = createSelector(selectState, (state) => state.state === ROOM_STATE.GAME);
export const selectIsInSession = selectIsInGame;
export const selectIsGameOver = createSelector(selectState, (state) => state.isGameOver);
export const selectBackgroundId = createSelector(selectState, (state) => state.backgroundId);
export const selectRoomName = createSelector(selectState, (state) => state.name);

export const selectIsRoomPublic = createSelector(selectState, (state) => state.isPublic);
export const getRoomId = createSelector(selectState, (state) => state.roomId);
export const selectRoomId = createSelector(selectState, (state) => state.roomId);
export const getRoomLink = createSelector(selectRoomId, (roomId) => `${window.location.origin}/room/${roomId}`);
/*export const selectDeeplink = (question: number) =>
  createSelector(
    selectPartyId,
    (partyId) => `${window.location.origin}/deeplink?partyId=${partyId}&question=${question}`
  );*/

export const selectQuestionNumber = createSelector(selectState, (state) => state.questionNumber);
export const selectDeeplink = createSelector(
  selectPartyId,
  selectQuestionNumber,
  (partyId, question) => `${window.location.origin}/deeplink?partyId=${partyId}&question=${question}`
);

export const getDefaultRoomPath = createSelector(selectRoomId, (roomId) => `/room/${roomId}/lobby`);
export const getDefaultPath = createSelector(selectRoomId, (roomId) => (roomId ? `/room/${roomId}/lobby` : `/`));

export const selectRoomPlayer = (playerId: IdType | undefined) =>
  createSelector(selectRoomPlayers, (players) => players.find((player) => player.playerId === playerId));

export const selectIsAlonePlayer = createSelector(selectRoomPlayers, (players) => players.length === 1);

export const selectFriends = createSelector(selectRoomPlayers, selectPlayerId, (players, playerId) =>
  players.filter((player) => player.playerId !== playerId && player.state !== PLAYER_STATE.ABSENT)
);

export const selectIsFirstPlayer = createSelector(
  selectRoomPlayers,
  selectPlayerIdWeak,
  (players, playerId) => players[0]?.playerId === playerId
);

export const selectIsEndGamePopup = createSelector(
  selectIsInGame,
  selectIsFirstPlayer,
  (isGame, isFirstPlayer) => isGame && isFirstPlayer
);

export const selectCurrentRoomPlayer = createSelector(selectRoomPlayers, selectPlayerId, (players, playerId) =>
  players.find((player) => player.playerId === playerId)
);

export const selectCurrentPlayerState = createSelector(
  selectCurrentRoomPlayer,
  (player) => player?.state || PLAYER_STATE.ABSENT
);

export const isPlayerReadyToQuit = createSelector(
  selectRoomPlayers,
  selectPlayerId,
  (roomPlayers, playerId) =>
    roomPlayers.find((player) => player.playerId === playerId)?.state === PLAYER_STATE.QUIT_READY
);

export const selectBackground = createSelector(selectBackgrounds, selectBackgroundId, (backgrounds, backgroundId) =>
  backgrounds.find((background) => background.id === backgroundId)
);

export const selectDoctor = createSelector(selectRoomPlayers, (players) => players[0]);
export const selectClient = createSelector(selectRoomPlayers, (players) => players[1]);
export const selectIsDoctor = selectIsFirstPlayer;
export const selectIsClient = createSelector(selectIsDoctor, (isDoctor) => !isDoctor);
export const selectCounterParty = createSelector(
  selectIsDoctor,
  selectDoctor,
  selectClient,
  (isDoctor, doctor, client) => (isDoctor ? client : doctor)
);
export const selectCounterPartyId = createSelector(selectCounterParty, (counterParty) => counterParty?.playerId);
export const selectCounterPartyDisconnectDate = createSelector(selectCounterParty, (counterParty) => counterParty?.disconnectDate);
export const selectCounterPartyIsOnline = createSelector(selectCounterParty, (counterParty) => counterParty?.isOnline);
export const selectClientId = createSelector(selectClient, (client) => client?.playerId);
export const selectIsSessionActive = selectIsInSession;

export const selectSidebarModals = createSelector(selectState, (state) => state.sidebarModals);

export default roomSlice;
