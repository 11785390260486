export type IdType = string;
export type ObjectType = Record<string, unknown>;

export type SimpleFunction = () => void;
export type AnyFunction = () => any;
export type AsyncQueueFunction = () => void | string;

export const simpleFunction: SimpleFunction = () => {};

export type OneOnly<Obj, Key extends keyof Obj> = {
  [key in Exclude<keyof Obj, Key>]?: null;
} &
  Pick<Obj, Key>;

export type OneOfByKey<T> = { [Key in keyof T]: OneOnly<T, Key> };
export type ValueOf<T> = T[keyof T];
export type OneOfType<T> = ValueOf<OneOfByKey<T>>;
