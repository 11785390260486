import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

export const styles = makeStyles((theme) => ({
  TakeCardsOpenedModal: {
    display: 'flex',
    flexDirection: 'column',
    /*alignItems: 'center',
    width: '520px',
    padding: '25px 25px 30px',
    fontFamily: 'SF Pro Display',
    boxSizing: 'border-box',*/

    // client mobile
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%',
      minWidth: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      /*'& .TakeCardsOpenedModal__sliderControls': {
        left: 0,
        right: 0,

        '& > i': {
          width: '45px',
          height: '45px',
          borderRadius: 0,
          backgroundColor: 'none',
          '& path': {
            fill: pandaColors.black._900,
          },
          '&:first-child': {
            left: 0,
          },
          '&:last-child': {
            right: 0,
          },
        },
      },*/
    },

    '& > *': {
      boxSizing: 'border-box',
    },

    '& > h3': {
      textAlign: 'center',
      fontSize: '32px',
      fontWeight: 600,
      marginTop: 0,
      marginBottom: '25px',

      // client mobile
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '25px',
        paddingRight: '25px',
        marginBottom: '20px',
        marginTop: '20px',
        fontSize: '24px',
        lineHeight: '31px',
      },
    },

    '& > button': {
      height: '50px',
      width: '280px',
      alignSelf: 'center',
      marginTop: '15px',
      marginBottom: '25px',
    },
  },

  TakeCardsOpenedModal__slider: {
    position: 'relative',
    flexGrow: 1,
  },
  TakeCardsOpenedModal__sliderWrapper: {
    width: '362px',
    minHeight: '370px',
    overflow: 'hidden',
    borderRadius: '10px',
    marginBottom: '30px',
    // client mobile
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 100px)',
      height: '100%',
      // marginBottom: '0px',
      margin: 'auto',
      minHeight: '10px',
    },
  },

  TakeCardsOpenedModal__sliderControls: {
    position: 'absolute',
    top: '50%',
    marginTop: '-55px',
    left: 0,
    // zIndex: 2,
    width: '100%',

    '& > i': {
      position: 'absolute',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '80px',
      height: '80px',
      backgroundColor: 'rgba(255,255,255, 0.1)',
      borderRadius: '50%',
      transition: 'all 0.23s',
      '&.disabled': {
        opacity: '0.5',
        pointerEvents: 'none',
      },
      '& path': {
        transition: 'all 0.23s',

        [theme.breakpoints.down('xs')]: {
          // fill: pandaColors.black._900,
          fill: pandaColors.white._25,
        },
      },
      '&:first-child': {
        left: '-180px',
        '& svg': {
          marginRight: '5px',
        },

        [theme.breakpoints.down('xs')]: {
          left: '0px',

          '& svg': {
            marginRight: '0px',
          },
        },
      },
      '&:last-child': {
        right: '-180px',
        '& svg': {
          marginLeft: '5px',
        },

        [theme.breakpoints.down('xs')]: {
          right: '0px',

          '& svg': {
            marginLeft: '0px',
          },
        },
      },
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(255,255,255, 0.25)',
        '& path': {
          fill: pandaColors.primary.gold,
        },

        [theme.breakpoints.down('xs')]: {
          backgroundColor: 'inherit',
        },
      },
    },

    [theme.breakpoints.down('xs')]: {
      left: 0,
      right: 0,
      marginTop: 0,
      alignItems: 'center',

      '& > i': {
        width: '45px',
        height: '45px',
        borderRadius: 0,
        backgroundColor: 'inherit',
      },
    },
  },

  TakeCardsOpenedModal__sliderVirtualList: {
    flexGrow: 1,
    '& > div': {
      width: '100%',
    },
  },

  TakeCardsOpenedModal__virtualSlide: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '362px',
    height: '480px',
    width: '362px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    // client mobile
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
    },
  },

  TakeCardsOpenedModal__close: {
    position: 'absolute',
    // zIndex: 2,
    right: '5px',
    // top: '5px',
    display: 'block',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
    '& path': {
      transition: 'all 0.23s',
    },
    '&:hover path': {
      fill: pandaColors.black._900,
    },

    [theme.breakpoints.down('xs')]: {
      '&:hover path': {
        fill: pandaColors.primary.gold,
      },
    },
  },
}));
