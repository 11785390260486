import { makeStyles } from '@material-ui/core';

export const styles = makeStyles(() => ({
  DoctorSession__right: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },

  DoctorSession__rightCards: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '80%',
  },
}));
