import { AppDispatch } from '@app/store';
import { setLocalPlayerAuthType, setPlayer } from '../reducers/player.reducer';
import { IPlayer } from '@src/graphql/player.fragment';
import { AUTH_TYPE } from '@src/shared/common';

export const updatePlayerThunk = (player: IPlayer) => (dispatch: AppDispatch) => {
  localStorage.setItem('authType', player.authType);
  if (player.authType !== AUTH_TYPE.AUTH) {
    localStorage.setItem('publicId', player.publicId);
    localStorage.setItem('name', player.name);
  }
  dispatch(setPlayer(player));
  dispatch(setLocalPlayerAuthType(player.authType));
  // isLoginshown? should be true
};
