import { makeStyles } from '@material-ui/core/styles';
import pandaColors from '@src/_pandacards/helpers/colors';

// fixed sizes
const TOP = 50;
const CLIENT_LAYOUT_WIDTH = 830;
const CENTER_HEIGHT = 760;
const PADDING = 20;
const MOBILE_CENTER_HEIGHT = 'calc(70vh - 100px)';

export const styles = makeStyles((theme) => ({
  ClientLayout: {
    position: 'relative',
    display: 'flex',
    padding: `${PADDING}px`,
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',

    // --- LAYOUT fixed sizes --- //
    width: `${CLIENT_LAYOUT_WIDTH}px`,
    // --- LAYOUT fixed sizes --- //

    // client mobile
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
      paddingTop: 0,
      width: '100%',
      height: '100%',
      // minHeight: '577px',
      marginLeft: 0,
      marginRight: 0,
    },
  },

  ClientLayout__top: {
    display: 'flex',
    marginBottom: '20px',
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: pandaColors.black._800,

    // client mobile
    [theme.breakpoints.down('xs')]: {
      // marginLeft: '-15px',
      // marginRight: '-15px',
      borderRadius: 0,
      marginBottom: '0px',
    },

    // --- TOP fixed sizes --- //
    minHeight: `${TOP}px`,
    maxHeight: `70px`,
    // --- TOP fixed sizes --- //
  },

  ClientLayout__center: {
    display: 'flex',
    flexShrink: 0,
    borderRadius: '10px',

    // --- CENTER fixed sizes --- //
    height: `${CENTER_HEIGHT}px`,
    maxHeight: `${CENTER_HEIGHT}px`,
    flexBasis: `${CENTER_HEIGHT}px`,
    // --- CENTER fixed sizes --- //

    // client mobile
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
      // maxHeight: 'none',
      height: MOBILE_CENTER_HEIGHT,
      flexBasis: MOBILE_CENTER_HEIGHT,
    },
  },
}));
