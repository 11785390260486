import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';
const { white, black } = pandaColors;

export const styles = makeStyles((theme) => ({
  PandaRoomWrapper: {
    color: white._50,
    backgroundColor: black._900,
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',

    '& > *': {
      boxSizing: 'border-box',
    },

    '&.isClient': {
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
  },
}));
