import React, { useEffect, useState } from 'react';

type RenderPromiseProps = {
  promise: Promise<any>;
};

// issue - possible double render
export const RenderPromise: React.FC<RenderPromiseProps> = ({ promise }) => {
  const [result, setResult] = useState<string>();

  useEffect(() => {
    promise
      .then((res) => {
        setResult(JSON.stringify(res));
      })
      .catch((err) => {
        setResult(JSON.stringify(err));
      });
  }, [promise]);

  return <>{result}</>;
};
