// import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Sidebar, SidebarMobile } from '@pc/components/common';
import { MobileEnter } from '@pc/pages/room/parts';
import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';
import { PandaRoomService } from '@src/services/pandaRoomService';
import { getIsJoinRoomLoading } from '@src/store/reducers/loadActions';
import { LoaderWsConnect } from '@pc/components/ui';
import { styles } from './PandaRoomWrapper.styles';

const PandaRoomWrapper: React.FC = ({ children }) => {
  const classes = styles();
  const isDoctor = useAppSelector(selectIsDoctor);
  const isLoading = useAppSelector(getIsJoinRoomLoading);

  if (isDoctor && isMobile) return <MobileEnter />;
  if (isLoading) return <LoaderWsConnect />;

  return (
    <div className={`pandacards ${classes.PandaRoomWrapper} ${!isDoctor ? 'isClient' : ''}`}>
      <PandaRoomService roomId="" />

      <Sidebar />

      {children}
    </div>
  );
};

export default PandaRoomWrapper;
