import { useState } from 'react';
import { getRoomLink } from '@store/room/roomSlice';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { copyLinkToClipboard } from '@src/common/misc';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { CopyIcon } from '@pc/components/icons';
import { Button } from '@pc/components/ui';
import { styles } from './CopyRoomLink.styles';

const CopyRoomLink = () => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const roomLink = useAppSelector(getRoomLink);
  const [isLinkCopied, setLinkCopied] = useState(false);

  const onCopyLink = () => {
    dispatch(copyLinkToClipboard(roomLink, 'MENU'));
    dispatch(pushNotification({ type: NOTIFICATION_TYPE.COPY_LINK }));
    setLinkCopied(true);

    setTimeout(() => {
      setLinkCopied(false);
    }, 2000);
  };

  return (
    <div className={classes.CopyRoomLink}>
      {/* link */}
      <div className={classes.CopyRoomLink__link} onClick={onCopyLink}>
        <div>{roomLink}</div>

        <i>
          <CopyIcon />
        </i>
      </div>

      {/* button */}
      <div className={classes.CopyRoomLink__button}>
        <Button type="blue" onClick={onCopyLink}>
          Скопировать ссылку
        </Button>
      </div>

      {isLinkCopied && (
        <span className={`${classes.CopyRoomLink__isLinkCopied} animate__animated animate__fadeInDown`}>
          Ссылка скопирована
        </span>
      )}
    </div>
  );
};

export default CopyRoomLink;
