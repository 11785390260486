import {
  BalanceUpdateServerEvent,
  MoneyReceiverServerEvent,
  MoneySenderServerEvent,
  QuestUpdateServerEvent,
  QuestAddServerEvent,
  DepositServerEvent,
  CardDeckUpdatedServerEvent,
} from './../../shared/socketEvents/socketEvents';
import { History } from 'history';
import { API_WS_URL } from '@src/common/constants/system';
import { CLIENT_EVENTS, SERVER_EVENTS, SYSTEM_EVENTS } from '@shared/socketEvents/eventTypes';
import WSClient from '@src/sockets';
import { setReceivedInfo, setWSConnectStatus } from '@store/reducers/common';
import { createRoomFinish, createRoomStart, joinRoomFinish, joinRoomStart } from '@store/reducers/loadActions';
import {
  updateRoomState,
  getRoomId,
  setRoomName,
  selectRoom,
  ROOM_STATE,
  IRoomState,
  PLAYER_STATE,
} from '@store/room/roomSlice';
import { StickerSentServerEvent } from '@shared/socketEvents/socketEvents';
import { createAsyncThunk, Func1 } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@src/app/store';
import { analytics } from '@services/amplitude';
import { sendDataToServer } from '@components/VideoChat/parts/helperMethods';
import { ROOM_JOIN_STATE } from '@shared/common';
import {
  setRoomFullShown,
  setNotificationType,
  SNACKBAR_TYPE,
  SNACKBAR_SUCCESS,
  setOperationSuccess,
} from '@store/reducers/popups';
import { FACEBOOK_EVENT, sendFacebookEvent } from '@services/facebookPixel';
import { pushNotification, pushStickerAction } from '@store/room/roomFeaturesData.slice';
import { mutateGraphql } from '@src/library/fetch';
import { createRoomMutation, CreateRoomResponse } from '@src/graphql/debugQueries';
import { ObjectType } from '@src/shared/generics';
import {
  selectPlayerId,
  selectPlayerName,
  setBalance,
  setPlayerRoom,
  updateCardDecks,
} from '@store/reducers/player.reducer';
import {
  IDepositNotification,
  IQuestNotification,
  IRecievedNotification,
  IResultNotification,
  NOTIFICATION_TYPE,
} from '../room/notifications';
import { IPlayerQuestQl } from '@src/graphql/player.fragment';

export interface RoomJoinData {
  isPublicRoom: boolean;
  partyId?: string;
  roomId?: string;
  history?: History;
  question?: string;
}

export const roomJoin = (joinData: RoomJoinData) => async (dispatch: AppDispatch, getState: () => RootState) => {
  dispatch(joinRoomStart());

  const playerId = selectPlayerId(getState());
  const isPublicRoom = joinData.isPublicRoom; // always false
  const roomId = joinData.roomId;
  const partyId = joinData.partyId; // only to join
  const question = joinData.question; // only to join

  await WSClient.connect(API_WS_URL, playerId, dispatch);
  WSClient.on(SYSTEM_EVENTS.CONNECT, () => {
    dispatch(setWSConnectStatus(true));
    // console.log(partyId, question);
    WSClient.emit(CLIENT_EVENTS.PLAYER_JOINED_ROOM, {
      roomId,
      partyId,
      question,
    });
  });

  WSClient.on(SYSTEM_EVENTS.DISCONNECT, (reason: string) => {
    dispatch(setWSConnectStatus(false));

    const exitMethod = () => dispatch(exitToHomepage(`WS disonnect ${reason}`));

    if (reason === 'io server disconnect') {
      exitMethod();
      return;
    }

    sendDataToServer(playerId || '', `WS disconnect ${reason}`);

    // will try reconnect himself if errors or not reconnect if 'io client disconnect'

    if (reason === 'io client disconnect') {
      return;
    }
  });

  WSClient.on(SERVER_EVENTS.ROOM_JOIN, ({ data }: any) => {
    switch (data.state) {
      case ROOM_JOIN_STATE.NO_ERROR:
        return;
      case ROOM_JOIN_STATE.ROOM_FULL:
        dispatch(setRoomFullShown(true));
        dispatch(updateRoomState({ state: ROOM_STATE.UNKNOWN }));
        return;
      default:
        dispatch(exitToHomepage(`Room joined failed ${data.state}`));
    }
  });

  WSClient.on(SERVER_EVENTS.ROOM_STATE_UPDATED, ({ data }: any) => {
    console.log(SERVER_EVENTS.ROOM_STATE_UPDATED, data);
    dispatch(updateRoomStateThunk(data.room));
    dispatch(joinRoomFinish());
  });

  WSClient.on(SERVER_EVENTS.ROOM_REMOVED, ({ data }: any) => {
    console.log(SERVER_EVENTS.ROOM_REMOVED, data);
    dispatch(removeRoomThunk(data));
    dispatch(joinRoomFinish());
  });

  WSClient.on(SERVER_EVENTS.TICKETS_RAN_OUT_DUMMY, ({ data }: any) => {
    dispatch(isPublicRoom ? leaveRoom(roomId!) : leaveGame(roomId!, data.activeGameId));
  });

  WSClient.on(SERVER_EVENTS.GAME_STATE_UPDATED, ({ data }: any) => {
    //console.log(SERVER_EVENTS.GAME_STATE_UPDATED, data);
  });

  WSClient.on(SERVER_EVENTS.PLAYER_CARD_DECK_UPDATED, ({ data }: { data: CardDeckUpdatedServerEvent }) => {
    dispatch(updateCardDecks(data.cardDecks));
  });

  WSClient.on(SERVER_EVENTS.PLAYER_STICKER_SENT, ({ data }: { data: StickerSentServerEvent }) => {
    console.log(SERVER_EVENTS.PLAYER_STICKER_SENT, data);
    dispatch(pushStickerAction(data));
  });

  WSClient.on(SERVER_EVENTS.BALANCE_UPDATE, ({ data }: { data: BalanceUpdateServerEvent }) => {
    console.log(SERVER_EVENTS.BALANCE_UPDATE, data);
    if (data.balance != undefined) {
      dispatch(setBalance(data.balance));
    }
  });

  WSClient.on(SERVER_EVENTS.PLAYER_MONEY_SENT, ({ data }: { data: MoneySenderServerEvent }) => {
    console.log(SERVER_EVENTS.PLAYER_MONEY_SENT, data.result);
    if (data.balance !== undefined) {
      dispatch(setBalance(data.balance));
    }
    dispatch(pushNotification({ type: NOTIFICATION_TYPE.SEND, success: data.result } as IResultNotification));
    // dispatch(setNotificationType(SNACKBAR_TYPE.SEND));
    // dispatch(setOperationSuccess(data.result ? SNACKBAR_SUCCESS.SUCCESS : SNACKBAR_SUCCESS.UNSUCCESS));
  });

  WSClient.on(SERVER_EVENTS.PLAYER_MONEY_RECIEVED, ({ data }: { data: MoneyReceiverServerEvent }) => {
    console.log(SERVER_EVENTS.PLAYER_MONEY_RECIEVED);
    if (data.balance !== undefined) {
      dispatch(setBalance(data.balance));
    }
    // dispatch(setNotificationType(SNACKBAR_TYPE.RECEIVE));
    // dispatch(setReceivedInfo(data));

    /*dispatch(
      pushNotification({
        type: NOTIFICATION_TYPE.RECEIVE,
        playerId: data.playerId,
        amount: data.amount,
        name: data.name,
      } as IRecievedNotification)
    );*/
    analytics.logEvent(analytics.EVENTS.MONEY_RECIEVED, {
      from: data.playerId,
      amount: data.amount,
      donation: data.isDonation,
    });
  });

  WSClient.on(SERVER_EVENTS.PLAYER_MONEY_DEPOSITED, ({ data }: { data: DepositServerEvent }) => {
    if (data.balance !== undefined) {
      dispatch(setBalance(data.balance));
    }
    dispatch(
      pushNotification({
        type: NOTIFICATION_TYPE.DEPOSIT,
        result: data.result,
        amount: data.amount,
      } as IDepositNotification)
    );
  });

  return Promise.resolve(true);
};

export const exitToHomepage = (reason: string, info = '') => async (
  dispatch: AppDispatch,
  getState: () => RootState
) => {
  const playerId = selectPlayerId(getState());
  await sendDataToServer(playerId, `Exit to homepage ${reason} ${info}`);
  analytics.logEvent(analytics.EVENTS.EXIT_TO_HOMEPAGE, { reason });
  // WSClient.close();

  console.log(reason, info);
  window.location.assign('/');
};

const updateRoomStateThunk = (data: IRoomState) => (dispatch: any, getState: any) => {
  const playerId = selectPlayerId(getState());
  const roomState = selectRoom(getState());

  const currentPlayer = data.players.find((player) => player.playerId === playerId);

  if (!currentPlayer || currentPlayer.state === PLAYER_STATE.ABSENT) {
    dispatch(exitToHomepage('Player is not in the room'));
    return;
  }

  if (!currentPlayer.isOnline) {
    sendDataToServer(currentPlayer.playerId, 'I see my own disconnect');
  }

  // console.log(data);
  dispatch(updateRoomState(data));
};

const removeRoomThunk = (data: any) => (dispatch: AppDispatch, getState: () => RootState) => {
  const roomId = getRoomId(getState());

  if (data.roomId !== roomId) {
    console.error(`Removed room mismatch: ${data.roomId} vs ${roomId}`);
  }

  dispatch(exitToHomepage('Remove room thunk'));
};

export const leaveGame = (roomId: string, activeGameId: string) => (dispatch: any, getState: any) => {
  const playerId = selectPlayerId(getState());

  WSClient.emit(CLIENT_EVENTS.PLAYER_LEFT_GAME, {
    roomId,
    activeGameId,
    playerId,
  });
};

export const leaveRoom = (roomId: string) => (dispatch: any, getState: any) => {
  const playerId = selectPlayerId(getState());

  WSClient.emit(CLIENT_EVENTS.PLAYER_LEFT_ROOM, {
    playerId,
    roomId,
  });
};

export const leaveRoomThunk = createAsyncThunk(
  'room/leave',
  (roomId: string, { getState }) => {
    const playerId = selectPlayerId(getState() as RootState);
    WSClient.emit(CLIENT_EVENTS.PLAYER_LEFT_ROOM, {
      playerId,
      roomId,
    });
    return Promise.resolve;
  },
  {
    condition(roomId): boolean {
      return true;
    },
  }
);

/*export const updateAgoraTokenThunk = (roomId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const playerId = selectPlayerId(getState());

  fetchApi<AgoraProps>('/get-agora-token', RequestType.POST, { playerId, roomId }).then((data) => {
    dispatch(updateAgoraToken(data));
  });
};*/

export const reconnectInRoomThunk = (roomId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const playerId = selectPlayerId(getState());

  dispatch(setWSConnectStatus(false));
  const query = '/rooms/joinPrivate';
  const payload = { playerId, roomId };
  dispatch(updateRoomState({ state: ROOM_STATE.UNKNOWN }));
  WSClient.reconnect();
};

export const createRoomThunk = (
  title = '',
  onSuccess: Func1<string, void>,
  onError?: Func1<string, void>,
  iconIndex?: number
) => (dispatch: AppDispatch, getState: () => RootState) => {
  const owner = selectPlayerId(getState());
  dispatch(createRoomStart());

  const automatic = title === '';

  if (automatic) {
    const ownerName = selectPlayerName(getState());
    title = `${ownerName}'s room`;
  }

  const roomData = {
    title,
    owner,
    iconIndex,
  };

  mutateGraphql<ObjectType, CreateRoomResponse>(createRoomMutation, { roomData })
    .then((data) => {
      const room = data.createRoom;
      dispatch(setPlayerRoom(room));
      // dispatch(setRoomName(room.title));
      analytics.logEvent(analytics.EVENTS.ROOM_CREATED, {
        roomId: room.id,
        title: room.title,
        autocreate: automatic,
      });
      sendFacebookEvent(FACEBOOK_EVENT.ROOM_CREATED);
      onSuccess(room.id);
    })
    .catch((reason) => {
      console.log(reason);

      /* eslint-disable-next-line prefer-spread */
      onError?.apply(null, reason);
    })
    .finally(() => {
      dispatch(createRoomFinish());
    });
};
