import { AppDispatch, RootState } from '@app/store';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { getRoomId, selectPartyId, selectRoomPlayers } from '@store/room/roomSlice';
import { analytics } from './amplitude';

export const sendRoundStarted = (roundId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
  const roomId = getRoomId(getState());
  const partyId = selectPartyId(getState());
  const roomPlayers = selectRoomPlayers(getState());

  analytics.logEvent(analytics.EVENTS.ROUND_STARTED, {
    roomId,
    partyId,
    roundId,
    playersNumber: roomPlayers.length,
  });
};

export const sendRoomJoined = (roomId: string, from: FROM_LOCATIONS) => {
  analytics.logEvent(analytics.EVENTS.PLAYER_JOINED_ROOM, {
    roomId,
    from,
  });
};
