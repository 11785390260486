import React from 'react';
import { useAppSelector } from '@app/hooks';
import { getIsHomePageLoading } from '@src/store/reducers/loadActions';
// import { selectIsFullAuthenticated, selectPlayerRoomId } from '@src/store/reducers/player.reducer';
import {
  useHistory,
  //  useLocation
} from 'react-router-dom';
// import { useAuth } from 'oidc-react';
import { LoaderWsConnect, Button } from '@pc/components/ui';
import { ScrollContainer } from '@pc/components/layout';

import PandacardsLogoBig from '@pc/assets/images/pandacards-logo-big.svg';
import VideoPlayer from '@pc/components/common/VideoPlayer/VideoPlayer';
import { styles } from './PandaHome.styles';
import { useDeviceSize } from '@src/common/screenSizes';

const PandaHome: React.FC = () => {
  // const auth = useAuth();
  const classes = styles();
  const history = useHistory();
  const { isMobile } = useDeviceSize();
  // const location = useLocation();
  // const playerRoomId = useAppSelector(selectPlayerRoomId);
  const isHomePageLoading = useAppSelector(getIsHomePageLoading);
  // const isAuthenticated = useAppSelector(selectIsFullAuthenticated);

  /*const onAuthClick = () => {
    if (!isAuthenticated) {
      history.push({ pathname: '/login' }, { target: location, autologin: false });
    } else {
      auth.signOutRedirect({ id_token_hint: auth.userData?.id_token });
    }
  };*/

  const onEnterRoomClick = () => {
    history.push(`/cabinet`);
  };

  return (
    <>
      {!isHomePageLoading ? (
        <ScrollContainer>
          <main className={`pandacards ${classes.PandaHome}`}>
            {/* wrapper */}
            <div className={classes.PandaHome__wrapper}>
              <img className={classes.PandaHome__logo} src={PandacardsLogoBig} alt="pandacards-logo.svg" />

              <div className={classes.PandaHome__content}>
                <div className={classes.PandaHome__contentLeft}>
                  <h2>Пандакарты</h2>
                  <p>Это платформа для удаленной работы с метафорическими ассоциативными картами по направлениям:</p>
                  <ul>
                    <li>Психология и психотерапия</li>
                    <li>Коучинг</li>
                    <li>Таро</li>
                  </ul>
                  <p>Пандакарты дают все преимущества онлайн работы:</p>
                  <ul>
                    <li>Видеосвязь с клиентом и работа с картами на одном экране</li>
                    <li>
                      Все ваши колоды в цифровом виде и доступны в один клик. Больше не нужны огромные сумки с десятками
                      бумажных колод.
                    </li>
                    <li>Клиент может полноценно работать с мобильного телефона</li>
                    <li>Вы можете проводить сессию из любого места где есть интернет.</li>
                  </ul>
                  <p>
                    <em>Сайт работает в тестовом режиме, все материалы предоставлены в демонстрационных целях.</em>
                  </p>

                  {isMobile && (
                    <div className={classes.PandaHome__enter}>
                      <Button onClick={onEnterRoomClick}>Войти в кабинет</Button>
                    </div>
                  )}

                  {/* Войти в кабинет */}
                  {/*isAuthenticated && (
                    <div className={classes.PandaHome__enter}>
                      <Button onClick={onEnterRoomClick}>Войти в кабинет</Button>
                    </div>
                  )*/}

                  {/* Авторизация */}
                  {/*!isAuthenticated && (
                    <div className={classes.PandaHome__login}>
                      <Button onClick={onAuthClick}>Авторизация</Button>
                    </div>
                  )*/}

                  {/* Выйти */}
                  {/*isAuthenticated && (
                    <div className={classes.PandaHome__logout}>
                      <Button onClick={onAuthClick} mode="outlined">
                        Выйти
                      </Button>
                    </div>
                  )*/}
                </div>

                <div className={classes.PandaHome__contentRight}>
                  {/* <img src={VideoPlaceholder} alt="video.jpeg" /> */}
                  <VideoPlayer />
                  {!isMobile && (
                    <div className={classes.PandaHome__enter}>
                      <Button onClick={onEnterRoomClick}>Войти в кабинет</Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </main>
        </ScrollContainer>
      ) : (
        <LoaderWsConnect />
      )}
    </>
  );
};

export default PandaHome;
