import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  root: {
    height: '48px',
    fontSize: '14px',
    lineHeight: '21px',
    color: '#1B2036',
    boxSizing: 'border-box',
    [theme.breakpoints.up('sm')]: {
      height: '44px',
    },

    '& .MuiMenu-list': {
      width: '100%',
    },

    '&:hover fieldset': {
      borderColor: '#BBBCC3 !important',
      borderWidth: '1px !important',
    },

    // focus
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: '#2C74F3 !important',
        borderWidth: '1px !important',
      },

      '& label': {
        color: 'red !important',
      },
    },

    '& .MuiSelect-outlined.MuiSelect-outlined': {
      height: '48px',
      textAlign: 'left',
      paddingTop: '14px',
      paddingBottom: '14px',
      backgroundColor: 'white',
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        height: '44px',
        paddingTop: '12px',
        paddingBottom: '12px',
      },
    },

    '& fieldset': {
      borderColor: '#E8E9EB',
    },
  },

  wrapper: {
    width: '100hw',
    color: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',
    // paddingTop: '5px',
    // paddingBottom: '5px',

    '& > h3': {
      fontFamily: 'Nunito',
      fontWeight: 900,
      fontSize: '32px',
      lineHeight: '42px',
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '15px',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '5px',
      },
    },
  },

  formControl: {
    minWidth: 120,
  },

  formInner: {
    color: 'inherit',
    flexDirection: 'column',
    marginBottom: '25px',

    '& > button': {
      marginTop: '15px',
    },
    '& div:first-child': {
      marginBottom: 0,
    },

    // checbox label
    '& .MuiFormControlLabel-label': {
      fontFamily,
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '21px',
      color: '#1B2036',
    },
  },

  button: {
    color: 'inherit',
  },
  label: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#BBBCC3',
    transform: 'translate(14px, 12px) scale(1)',

    [theme.breakpoints.up('sm')]: {
      transform: 'translate(14px, 10px) scale(1)',
    },

    '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -8px) scale(0.75)',
    },

    '&.Mui-focused': {
      color: '#2C73F3',
    },
  },
  socialNetworks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > p': {
      textAlign: 'center',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '15px',
      lineHeight: '18px',
      color: '#5F6372',
    },
    '& > div': {
      '& > img': {
        width: '40px',
        height: '40px',
        flexShrink: 0,
        cursor: 'pointer',
        '&:not(last-child)': {
          marginRight: '10px',
        },
      },
    },
  },
}));

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    // input
    rootEmail: {
      width: '100%',
      border: 0,
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },

      // label focused
      '& label.Mui-focused': {
        color: '#2C74F3',
        transform: 'translate(14px, -8px) scale(0.75)',
      },

      '& label.MuiInputLabel-shrink': {
        transform: 'translate(14px, -8px) scale(0.75)',
      },

      // out-focus
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#E8E9EB',
          borderWidth: '1px',
          '& legend span': {
            fontSize: '0.9em',

            [theme.breakpoints.up('sm')]: {
              fontSize: '0.75em',
            },

            [theme.breakpoints.up('md')]: {
              fontSize: '0.7em',
            },
          },
        },

        // hover
        '&:hover fieldset': {
          borderColor: '#BBBCC3',
          borderWidth: '1px',
        },

        // focus
        '&.Mui-focused fieldset': {
          borderColor: '#2C74F3',
          borderWidth: '1px',
        },
      },
    },

    // lable in outfocus
    lableEmail: {
      fontSize: '14px',
      lineHeight: '21px',
      color: '#BBBCC3',
      transform: 'translate(14px, 12px) scale(1)',
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },

    inputEmail: {
      // padding: '14.5px 14px',
      height: '48px',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#1B2036',
      boxSizing: 'border-box',
      [theme.breakpoints.up('sm')]: {
        height: '44px',
      },
    },

    formHelperEmail: {
      // fontSize: 13,
    },

    rootPassword: {
      width: '100%',
      border: 0,
      marginBottom: '20px',
      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },

      // change icon color when clicked
      '& input[type="text"] + div svg > path': {
        fill: '#2C74F3',
      },

      // eye button
      // '& .MuiIconButton-edgeEnd': {
      // outline: '1px solid red',
      // },

      // label (floating) static
      '& label': {
        fontSize: '14px',
        lineHeight: '21px',
        color: '#BBBCC3',
        transform: 'translate(14px, 12px) scale(1)',
        [theme.breakpoints.up('sm')]: {
          transform: 'translate(14px, 10px) scale(1)',
        },

        // label (floating) focused
        '&.Mui-focused': {
          color: '#2C74F3',
          transform: 'translate(14px, -8px) scale(0.75)',
        },
        '&.MuiInputLabel-shrink': {
          transform: 'translate(14px, -8px) scale(0.75)',
        },
      },

      // input
      '& .MuiOutlinedInput-root': {
        height: '48px',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#1B2036',
        boxSizing: 'border-box',
        [theme.breakpoints.up('sm')]: {
          height: '44px',
        },

        // input static
        '& fieldset': {
          borderColor: '#E8E9EB',
          borderWidth: '1px',
        },

        // input hover
        '&:hover fieldset': {
          borderColor: '#BBBCC3',
          borderWidth: '1px',
        },

        // input focus
        '&.Mui-focused fieldset': {
          borderColor: '#2C74F3',
          borderWidth: '1px',

          // label bg
          '& legend': {
            width: '55px !important',
          },
        },
      },

      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
    },

    loginPage: {
      fontFamily: fontFamily,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '600px',
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        background: '#F3F4F5',
      },
      '& *': {
        boxSizing: 'border-box',
      },
    },
    whiteBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '30px 0 25px',
      background: theme.palette.common.white,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: '380px',
        minHeight: '570px',
        borderRadius: '20px',
        border: '1px solid #E8E9EB',
      },
    },
    helloLogo: {
      width: '95px',
      marginBottom: '35px',
      [theme.breakpoints.up('sm')]: {
        width: '120px',
        marginBottom: '20px',
      },
    },
    title: {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: 500,
      marginBottom: '6px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
    divider: {
      position: 'relative',
      zIndex: 1,
      height: '1px',
      width: '100%',
      background: '#E8E9EB',
      marginLeft: '-25px',
      marginRight: '-25px',
      '& span': {
        position: 'absolute',
        zIndex: 2,
        background: 'white',
        top: '-12px',
        left: '50%',
        marginLeft: '-10px',
        width: '30px',
        height: '21px',
        display: 'inline-flex',
        justifyContent: 'center',
        fontSize: '15px',
        lineHeight: '21px',
        color: '#BBBCC3',
      },
    },
    haveAcount: {
      fontWeight: 500,
      width: '100%',
      textAlign: 'center',
      fontSize: '12px',
      lineHeight: '14px',
      '& a': {
        color: '#2C74F3',
      },
    },

    wrapper: {
      width: '100%',
      flexDirection: 'column',
      alignSelf: 'center',
      '& > h3': {
        fontFamily: 'Nunito',
        fontWeight: 900,
        fontSize: '32px',
        lineHeight: '42px',
        textTransform: 'uppercase',
        marginTop: 0,
        [theme.breakpoints.up('md')]: {
          marginTop: '5px',
        },
      },
    },

    btnContainer: {
      color: 'inherit',
    },

    formInner: {
      display: 'flex',
      flexDirection: 'column',
    },
    socialNetworks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > p': {
        textAlign: 'center',
        fontFamily: fontFamily,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        color: '#5F6372',
      },
      '& > div': {
        '& > img': {
          width: '40px',
          height: '40px',
          flexShrink: 0,
          cursor: 'pointer',
          '&:not(last-child)': {
            marginRight: '10px',
          },
        },
      },
    },
  })
);
