import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';
import { CardsWillBeSoon, ChoosingCardsOverlay, LostConnection, SimpleCard } from '..';
import { CardPlacement } from '../../standardSession.types';
import { HookType } from '../../standardSession.hook';
import { useLayouts } from './useLayouts';
import { CardFieldLayout } from './CardFieldLayout';
import { styles } from './CardField.styles';
import { useEffect, useRef, useState } from 'react';
import { Aspect, defaultAspect } from '../cardMath';
import { CardSwapperFieldMobile } from '../CardSwapperMobile/CardSwapperFieldMobile';

interface Props {
  hook: HookType;
}

const CardFieldMobile: React.FC<Props> = ({ hook }) => {
  const isDoctor = useAppSelector(selectIsDoctor);
  const session = hook.session;

  const isLostConnection = false;

  // lost connection for client
  if (!isDoctor && isLostConnection) return <LostConnection isDoctor={false} />;

  return (
    <>
      {/* Слоты с картами */}
      {/*<CardFieldLayout hook={hook} slotAspect={slotAspect} />*/}
      {session.slots.length > 0 && <CardSwapperFieldMobile hook={hook} />}

      {/* Скоро здесь появятся карты */}
      {!isDoctor && !session.slots.length && <CardsWillBeSoon />}
    </>
  );
};

export { CardFieldMobile };
