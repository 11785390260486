import InvitePanda from '@pc/assets/images/panda-invite.svg';
import { MixProps } from '@src/common/generics';
import { CopyRoomLink } from '..';
import { styles } from './InviteBlock.styles';

const InviteBlock: React.FC<MixProps> = (props) => {
  const classes = styles();

  return (
    <div className={classes.InviteBlock} style={props.style}>
      {/* image */}
      <img className={classes.InviteBlock__image} src={InvitePanda} alt="panda-invite.svg" />

      {/* title */}
      <h2 className={classes.InviteBlock__title}>Пригласите клиента в кабинет</h2>

      {/* description */}
      <p className={classes.InviteBlock__description}>Отправьте ссылку на встречу вашему клиенту</p>

      <CopyRoomLink />
    </div>
  );
};

export default InviteBlock;
