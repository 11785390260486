const getBrowserMethods = () => {
  let hiddenProperty, visibilityChangeProperty;

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hiddenProperty = 'hidden';
    visibilityChangeProperty = 'visibilitychange';
    // @ts-ignore
  } else if (typeof document.msHidden !== 'undefined') {
    hiddenProperty = 'msHidden';
    visibilityChangeProperty = 'msvisibilitychange';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    hiddenProperty = 'webkitHidden';
    visibilityChangeProperty = 'webkitvisibilitychange';
  }

  return { hiddenProperty, visibilityChangeProperty };
};

const isMobileBrowser = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const isAppleBrowser = () => /Apple/i.test(navigator.vendor);

const isDesktopBrowser = () => !isMobileBrowser();

type DefaultAction = () => void;
type ConditionAction = () => boolean;

// use it for hooks.
const visibilitySubscribeActionThunk = (onHide: DefaultAction, onShow: DefaultAction) => () => {
  if (!isMobileBrowser()) {
    return;
  }

  const { hiddenProperty, visibilityChangeProperty } = getBrowserMethods();
  if (typeof document.addEventListener === 'undefined' || hiddenProperty === undefined) {
    return;
  }

  const handleVisibilityChange = () => {
    // @ts-ignore
    document[hiddenProperty] ? onHide() : onShow();
  };

  // @ts-ignore
  document.addEventListener(visibilityChangeProperty, handleVisibilityChange, false);
  console.log('add visibility change listener');

  return () => {
    // @ts-ignore
    document.removeEventListener(visibilityChangeProperty, handleVisibilityChange);
    console.log('remove visibility change listener');
  };
};

const subscribeListenerThunk = (condition: ConditionAction, property: string) => (onAction: DefaultAction) => () => {
  if (!condition()) {
    return;
  }

  document.body.addEventListener(property, onAction, false);
  console.log(`add ${property} listener`);

  return () => {
    document.body.removeEventListener(property, onAction, false);
    console.log(`remove ${property} listener`);
  };
};

const subscribeActionThunk = (condition: ConditionAction, property: string) => (onAction: DefaultAction) => () => {
  if (!condition()) {
    return;
  }

  const onEventAction = () => {
    document.body.removeEventListener(property, onEventAction, false);
    console.log(`remove ${property} listener`);
    onAction();
  };

  document.body.addEventListener(property, onEventAction, false);
};

/*const isAppleMobileBrowser = () => isMobileBrowser() && isAppleBrowser();
const isAppleDesktopBrowser = () => isDesktopBrowser() && isAppleBrowser();

const touchListenerThunk = subscribeListenerThunk(isAppleMobileBrowser, 'touchstart');
const clickListenerThunk = subscribeListenerThunk(isAppleDesktopBrowser, 'mousedown');*/

const touchListenerThunk = subscribeListenerThunk(isMobileBrowser, 'touchstart');
const clickListenerThunk = subscribeListenerThunk(isDesktopBrowser, 'mousedown');

export { visibilitySubscribeActionThunk, touchListenerThunk, clickListenerThunk };
