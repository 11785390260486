import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const CardsIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1162 24.8357C17.1162 25.8646 17.9348 26.6987 18.9445 26.6987H27.1719C28.1816 26.6987 29.0002 25.8646 29.0002 24.8357V10.863C29.0002 9.8341 28.1816 9 27.1719 9H18.9445C17.9348 9 17.1162 9.8341 17.1162 10.863V24.8357ZM18.9445 10.2H27.1719C27.4977 10.2 27.8002 10.4755 27.8002 10.863V24.8357C27.8002 25.2232 27.4977 25.4987 27.1719 25.4987H18.9445C18.6187 25.4987 18.3162 25.2232 18.3162 24.8357V10.863C18.3162 10.4755 18.6187 10.2 18.9445 10.2Z"
      fill={color}
    />
    <path
      d="M12.519 13.5297L14.8308 13.125V24.8357C14.8308 27.1508 16.6725 29.0275 18.9445 29.0275H24.5589C24.2999 29.2946 23.9595 29.4847 23.5687 29.5532L15.4602 30.9728C14.465 31.147 13.5197 30.4662 13.3487 29.4522L11.0267 15.6813C10.8557 14.6673 11.5238 13.704 12.519 13.5297Z"
      fill={color}
    />
  </svg>
);

export default CardsIcon;
