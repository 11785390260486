import { ClickAwayListener, Tooltip } from '@mui/material';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { styles } from './Card.styles';
import { ActionsType } from '../../standardSession.hook';
import { CardPlacement, SlotInfo } from '../../standardSession.types';
import { useCardTooltip } from './useCardTooltip';
import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { CardContext, createCardContext } from './useCardContext';
import { Instance } from '@popperjs/core';
import { MixProps } from '@src/common/generics';

export type TTooltipPlacement =
  | undefined
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-end'
  | 'left-start';

interface Props {
  card: TableCard;
  placement: CardPlacement;
  actions?: ActionsType;
  slotInfo?: SlotInfo;
  children: ReactElement<any, any>;
}

const CardTooltip: React.FC<MixProps<Props>> = ({ card, placement, actions, slotInfo, children }) => {
  const classes = styles();
  const popperRef = useRef<Instance>(null);
  const [links, cardContext] = useCardTooltip(card, actions, placement, slotInfo);
  const tooltipId = `${card.cardId}_popper`;
  // const [placement2, setPlacement2] = useState('');

  return (
    <CardContext.Provider value={cardContext}>
      <ClickAwayListener onClickAway={() => cardContext.setTooltipOpen(false)}>
        <Tooltip
          open={cardContext.isTooltipOpen}
          onClose={() => cardContext.setTooltipOpen(false)}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          PopperProps={{
            popperRef,
            popperOptions: {
              onFirstUpdate: (state) => {},
            },
            id: tooltipId,
            disablePortal: true,
            // anchorEl: { getBoundingClientRect: cardContext.getBoundingRect },
            /*modifiers: [
              {
                name: 'flip',
                options: {
                  altBoundary: true,
                },
                // enabled: placement2 ? false : true,
              },
              {
                name: 'preventOverflow',
                options: {
                  altBoundary: true,
                },
                // enabled: placement2 ? false : true,
                // enabled: true,
              },
            ],*/
            // @ts-ignore
            // placement: placement2 ? placement2 : 'auto'
            placement: 'auto',
          }}
          title={
            <ul className={classes.Card__menu}>
              {links.map((link, index) => (
                <li key={index} onClick={link.action}>
                  {link.title}
                </li>
              ))}
            </ul>
          }
        >
          {children}
        </Tooltip>
      </ClickAwayListener>
    </CardContext.Provider>
  );
};

export default CardTooltip;
