import { FetchResult, Observable, ServerError, ServerParseError } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { userManager } from './auth.config';
import { authLogger, authSignInSilent, SERVER_ERROR_AUTH_NO_USER } from './auth.helper';
import { buildAuthHeaders } from './auth.fetch';
import { SERVER_ERROR_AUTH_STATUS_CODE } from './auth.helper';

function isServerError(networkError: Error | ServerError | ServerParseError): networkError is ServerError {
  return !!(networkError as ServerError).statusCode;
}

const withToken = setContext(async (graphqlRequest, prevContext) => {
  const authHeaders = prevContext?.useAuth ? await buildAuthHeaders() : {};

  return {
    headers: {
      ...prevContext.headers,
      ...authHeaders,
    },
  };
});

const resetToken = onError(({ networkError, operation, forward }) => {
  if (networkError && isServerError(networkError) && networkError.statusCode === SERVER_ERROR_AUTH_STATUS_CODE) {
    return new Observable<FetchResult>((observer) => {
      authSignInSilent()
        .then(() => withToken.request(operation, forward))
        .then((observable) => {
          if (observable) {
            observable.subscribe(observer);
            // let's be verbose for now // deleted
          } else {
            return Promise.reject(`Failed to process ${operation.operationName}`);
          }
        })
        .catch((err) => {
          console.log('REMOVE USER', err);
          userManager.removeUser().then(() => observer.error(err));
          // observer.error(err);
        })
        .finally(() => {
          // we should not close observer, or we will have error before the second request.
          // observer.complete();
        });
    });
  }
});

export const apolloAuthLink = withToken.concat(resetToken);
