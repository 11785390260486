import { makeStyles } from '@material-ui/core/styles'; // without /styles?
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    fontFamily,
    backgroundColor: '#000',
    flexDirection: 'column',
    color: '#1B2036',
    outline: 'none',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1301,
  },
  img: {
    width: 85,
    height: 90,
  },
  text: {
    maxWidth: 420,
    width: '100%',
    fontSize: '28px',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    textAlign: 'center',
    lineHeight: '28px',
    fontWeight: 500,
    marginTop: 20,
  },
}));
