import { FROM_LOCATIONS } from '@src/common/constants/system';
import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { sendRoomJoined } from './analyticsThunk';
import { useAppSelector } from '@src/app/hooks';
import { getIsJoinRoomLoading } from '@src/store/reducers/loadActions';
import { VideoChat } from '@src/_pandacards/components/common';

interface Props {
  roomId: string;
}

export const PandaRoomService: React.FC<Props> = ({ roomId }) => {
  const location = useLocation();
  const isLoading = useAppSelector(getIsJoinRoomLoading);
  const from = location.state?.from as FROM_LOCATIONS;

  useEffect(() => {
    sendRoomJoined(roomId, from);
  }, []);

  return (
    <>
      <VideoChat isLoading={isLoading} />
    </>
  );
};
