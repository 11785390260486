import { makeStyles } from '@material-ui/core/styles';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles((theme) => ({
  PandaHome: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: pandaColors.black._900,
    color: pandaColors.white._50,
  },

  PandaHome__wrapper: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    width: '1200px',
    padding: '30px',
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },

  PandaHome__logo: {
    width: '360px',
    marginBottom: '100px',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '292px',
      marginBottom: '30px',
    },
  },

  PandaHome__content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },

  PandaHome__contentLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '600px', // '440px',
    flexGrow: 0,
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',
    },

    // title
    '& > h2': {
      fontSize: '48px',
      fontWeight: 600,
      fontFamily: 'Cormorant Garamond',
      marginTop: '15px',
      marginBottom: '16px',
      color: pandaColors.white._0,
    },

    // description
    '& > p': {
      fontSize: '18px',
      lineHeight: '27px', // 1.5
      fontStyle: 'normal',
      fontWeight: 500,
      color: pandaColors.white._200,
      // marginBottom: '15px', // '35px',
    },

    '& > ul': {
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
    },

    '& > em': {
      fontStyle: 'italic',
      color: '#777',
    },
  },

  PandaHome__contentRight: {
    flexBasis: '500px', // '640px',
    flexGrow: 0,
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'auto',

      '& .VideoPlayer': {
        maxWidth: 'none !important',
      },
    },
  },

  PandaHome__enter: {
    marginTop: '35px',
    alignSelf: 'center',
    '& > button': {
      width: '280px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '25px',
      alignSelf: 'auto',
      '& > button': {
        width: '100%',
      },
    },
  },
}));
