import { useSessionHook } from './standardSession.hook';
import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';
import { DoctorLayout, ClientLayout } from '@pc/components/layout';
import { CardField, Discarded, GiveCardModal, QuestionInput, SimpleCard } from './parts';
import { TakeCardsModal } from './modals/TakeCards/TakeCards';
import { CardPlacement } from './standardSession.types';
import { TableCard } from '@src/shared/sessionInterfaces/standardSession.socket';
import { styles } from './StandardSession.styles';
import { ClientLayoutMobile } from '@src/_pandacards/components/layout/ClientLayout/ClientLayoutMobile';
import { useDeviceSize } from '@src/common/screenSizes';

// session
const StandardSession: React.FC = () => {
  const classes = styles();
  const hook = useSessionHook();
  const isDoctor = useAppSelector(selectIsDoctor);
  const { isMobile } = useDeviceSize();

  if (isDoctor) {
    // Doctor layout
    return (
      <>
        <DoctorLayout
          // right content
          right={
            <div className={classes.DoctorSession__right}>
              <QuestionInput sessionQuestion={hook.session.question} onQuestionMade={hook.actions.onQuestionMade} />

              <div className={classes.DoctorSession__rightCards} id={'cardFieldId'}>
                <CardField hook={hook} />
              </div>
            </div>
          }
          // bottom content
          bottom={
            <>
              {hook.session.discarded.map((card, index) => (
                <Discarded key={index}>
                  <SimpleCard
                    data={card as TableCard}
                    placement={CardPlacement.DISCARD}
                    actions={hook.actions}
                    slotAspect={{ height: 105, width: 300 }}
                  />
                </Discarded>
              ))}
            </>
          }
        />

        <GiveCardModal
          isOpen={hook.isState.DEAL_CARD}
          sessionQuestion={hook.session.question}
          onCardsDealed={hook.actions.onCardsDealed}
          closeModal={() => hook.actions.onCardsDealing(false)}
        />
      </>
    );

    // Client layout
  } else {
    if (isMobile) {
      return <ClientLayoutMobile hook={hook} />;
    } else {
      return (
        <>
          <ClientLayout question={hook.session.question} content={<CardField hook={hook} />} />

          <TakeCardsModal
            isOpen={hook.isState.TAKE_CARD}
            takeCard={hook.session.takeCard}
            onCardTaken={hook.actions.onCardTaken}
          />
        </>
      );
    }
  }
};

export { StandardSession };
