import { combineReducers } from '@reduxjs/toolkit';
import { roomSlice } from '@store/room/roomSlice';
import { playerSlice } from '@src/store/reducers/player.reducer';
import { gameSlice } from '@store/reducers/game.reducer';
import commonSlice from '@store/reducers/common';
import loaderReducer, { LOADER_STORE_NAME } from '@store/reducers/loadActions';
import { popupsSlice } from '@store/reducers/popups';
import { roomFeaturesSlice } from '@store/room/roomFeaturesData.slice';
import { playerDataSlice } from '@store/reducers/playerData.reducer';
import { sessionSlice } from '@src/store/reducers/session.reducer';

const rootReducer = combineReducers({
  [roomSlice.name]: roomSlice.reducer,
  [playerSlice.name]: playerSlice.reducer,
  [commonSlice.name]: commonSlice.reducer,
  [sessionSlice.name]: sessionSlice.reducer,
  [LOADER_STORE_NAME]: loaderReducer,
  [gameSlice.name]: gameSlice.reducer,
  [popupsSlice.name]: popupsSlice.reducer,
  [playerDataSlice.name]: playerDataSlice.reducer,
  [roomFeaturesSlice.name]: roomFeaturesSlice.reducer,
});

export default rootReducer;
