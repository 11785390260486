import { makeStyles } from '@material-ui/core';
// import { fontFamily } from '@theme/textStyles';

export const styles = makeStyles((theme) => ({
  buttonLink: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: '#2C74F3',
    textDecoration: 'none',
    minWidth: '95px',
    padding: '5px 10px',
    minHeight: '28px',
    // fontFamily: 'Nunito Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '16px',
    border: '1px solid #2C74F3',
    borderRadius: '100px',
    transition: 'ease-out 0.2s',
    // [theme.breakpoints.up('sm')]: {
    //   minWidth: '122px',
    // },
    '&:hover': {
      backgroundColor: '#356FD6',
      color: 'white',
    },
  },

  button: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    borderRadius: '70px',
    border: '0.5px solid #000000',
    boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.3)',
    transition: 'all .23s',
    cursor: 'pointer',

    '& > span': {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '24px',
      textAlign: 'center',
      color: '#FFFFFF',
      textStroke: '0.5px #000000',
      textShadow: '0px 1px 0px #000000',
      [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
      },
    },

    '&.general': {
      width: '165px',
      height: '44px',
      [theme.breakpoints.up('sm')]: {
        width: '200px',
        height: '55px',
      },
    },

    '&.small': {
      width: 'auto',
      height: '32px',
      padding: '1px 15px',
      '& > span': {
        fontSize: '20px',
        lineHeight: '20px',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '5px 15px',
        height: '40px',
      },
      '& > .coin': {
        fontSize: '20px',
        lineHeight: '17px',
        marginLeft: '9px',
      },
    },

    '& > .coin': {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#FFDE17',
      textAlign: 'right',
      textStroke: '1px #AE5D02',
      textShadow: '1px -1px 0px #683700',
      marginLeft: '14px',
      '& > img': {
        width: '19px',
        height: '19px',
        marginLeft: '4px',
        marginBottom: '2px',
      },
    },

    // PRIMARY-BLUE
    '&.primary-blue': {
      backgroundColor: '#2C74F3',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      width: 'auto !important',
      height: '36px !important',
      borderRadius: '30px',
      cursor: 'pointer',
      transition: 'all .23s',
      boxSizing: 'border-box',
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: '0px 10px',
      '&[disabled]': {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.5,
      },
      [theme.breakpoints.up('sm')]: {
        // width: '220px !important',
        height: '40px !important',
      },

      '& span': {
        display: 'inline-flex',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        transition: 'all .23s',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
      '&:hover, &:focus': {
        backgroundColor: '#356FD6',
      },
      '&:active': {
        backgroundColor: '#3662AE',
      },
    },

    '&.primary-blue-new': {
      background: 'linear-gradient(180deg, #0A97FF 0%, #1050CB 100%)',
      '&:hover, &:focus': {
        backgroundColor: '#356FD6',
      },
      '&:active': {
        backgroundColor: '#3662AE',
      },
    },

    // PRIMARY-GREEN
    '&.primary-green': {
      backgroundColor: '#3AB957',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      width: 'auto !important',
      height: '36px !important',
      borderRadius: '30px',
      cursor: 'pointer',
      transition: 'all .23s',
      boxSizing: 'border-box',
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: '0px 5px',
      '&[disabled]': {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.5,
      },
      [theme.breakpoints.up('sm')]: {
        // width: '220px !important',
        height: '40px !important',
      },

      '& span': {
        display: 'inline-flex',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '18px',
        transition: 'all .23s',
        [theme.breakpoints.up('sm')]: {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
      '&:hover, &:focus': {
        backgroundColor: '#32A84D',
      },
      '&:active': {
        backgroundColor: '#2C9644',
      },
    },

    // PRIMARY-RED-NEW
    '&.primary-red-new': {
      background: 'linear-gradient(180deg, #F76585 0%, #E32D55 100%)',
      '&:hover, &:focus': {
        backgroundColor: '#F83E67',
        '& span': {
          color: 'white',
        },
      },
      '&:active': {
        backgroundColor: '#F83E67',
        '& span': {
          color: '#white',
        },
      },
    },

    // PRIMARY-GREEN-NEW
    '&.primary-green-new': {
      background: 'linear-gradient(180deg, #9FEE3A 0%, #43AD17 100%)',
      '&:hover, &:focus': {
        backgroundColor: '#32A84D',
      },
      '&:active': {
        backgroundColor: '#2C9644',
      },
    },

    // SECONDARY
    '&.secondary': {
      backgroundColor: 'transparent',
      borderRadius: '30px',
      cursor: 'pointer',
      transition: 'all .23s',
      border: 'none',
      boxShadow: 'none',
      padding: '17px 19px',
      '&[disabled]': {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: 0.5,
      },

      '& span': {
        fontWeight: 900,
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: 'center',
        color: '#FFFFFF',

        textStroke: '0.5px #000000',
        textShadow: '0px 1px 0px #000000',
      },
      '&:hover, &:focus': {
        backgroundColor: '#fff',
        '& span': {
          color: '#BBBCC3',
        },
      },
      '&:active': {
        backgroundColor: '#fff',
        '& span': {
          color: '#BBBCC3',
        },
      },
    },

    // PRIMARY-WHITE
    '&.primary-white': {
      backgroundColor: 'white',
      '& span': {
        color: '#2C74F3',
      },
      '&:hover, &:focus': {
        backgroundColor: '#2C74F3',
        '& span': {
          color: 'white',
        },
      },
      '&:active': {
        backgroundColor: '#fff',
        '& span': {
          color: '#BBBCC3',
        },
      },
    },

    // DANGER
    '&.danger': {
      backgroundColor: '#F83E67',
      '& span': {
        color: 'white',
      },
      '&:hover, &:focus': {
        backgroundColor: '#F83E67',
        '& span': {
          color: 'white',
        },
      },
      '&:active': {
        backgroundColor: '#F83E67',
        '& span': {
          color: '#white',
        },
      },
    },

    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none',
      background: '#BBBCC3 !important',
    },
  },
}));
