import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { VideoPlacement, selectPlayerVideoState, setVideoState } from '@store/reducers/common';
import { IRoomPlayer, selectIsDoctor } from '@store/room/roomSlice';
import PersonVideo from '../PersonVideo/PersonVideo';
import { ToggleVideoToBig, ToggleVideoToSmall } from '@pc/components/icons';
import { MixProps } from '@src/common/generics';
import { styles } from './PersonItem.styles';

interface Props {
  player: IRoomPlayer;
  placement: VideoPlacement;
  isLoading: boolean;
  isShowToggleButtonForClient?: boolean;
  // stickerAction?: StickerAction;
}

const getOppositePlacement = (placement: VideoPlacement) => {
  // if (placement == VideoPlacement.FIXED) return;
  return placement == VideoPlacement.SMALL ? VideoPlacement.LARGE : VideoPlacement.SMALL;
};

export const PersonItem: React.FC<MixProps<Props>> = ({
  player,
  placement,
  isLoading,
  style,
  className,
  isShowToggleButtonForClient = false,
}) => {
  const classes = styles();
  const dispatch = useAppDispatch();
  const playerVideoState = useAppSelector(selectPlayerVideoState(player?.playerId));
  const shouldBeShown = playerVideoState?.placement === placement;
  const containerId = `${player?.playerId}-video${placement}`;
  const playerId = player?.playerId;
  const isDoctor = useAppSelector(selectIsDoctor);
  const muteVideoCondition = !playerVideoState?.isVideoEnabled;
  const muteAudioCondition = !playerVideoState?.isAudioEnabled;

  useEffect(() => {
    if (shouldBeShown) {
      dispatch(setVideoState({ playerId, data: { containerId } }));
    }
    /*return () => {
      dispatch(setVideoState({ playerId, data: { containerId: undefined } }));
    };*/
  }, [shouldBeShown]);

  // onResizeClick
  const onResizeClick = () => {
    const nextPlacement = getOppositePlacement(placement);
    dispatch(setVideoState({ playerId, data: { placement: nextPlacement } }));
  };

  return (
    <>
      <div
        className={`${classes.PersonItem} ${className}`}
        // показываем большой видео-контейнер (<PersonItemBig />) только когда переключили placement
        style={{ display: shouldBeShown ? 'flex' : 'none', ...style }}
      >
        <div className={`${classes.PersonItem__wrapper} PersonWrapper`}>
          {/* туглер для переключения видео (только у клиента) */}
          {!isDoctor && shouldBeShown && isShowToggleButtonForClient && (
            <i onClick={onResizeClick}>{placement ? <ToggleVideoToSmall /> : <ToggleVideoToBig />}</i>
          )}

          {/* mute audio icon */}
          {muteAudioCondition && <div className={`${classes.PersonItem__muteAudio} PersonItem__muteAudio`} />}

          <PersonVideo containerId={containerId} muteVideoCondition={muteVideoCondition} />

          {/* {isBigMuteOpen && (
            <div className={classes.bigMute}>
              <img src={mute} />
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};
