import React, { useEffect, useState } from 'react';

export type PeriodicUpdateProps = {
  interval?: number;
  callback: (time: number) => any;
};

function getCurrentTime(): number {
  return new Date().getTime();
}

export const PeriodicUpdate: React.FC<PeriodicUpdateProps> = ({ interval = 60_000, callback }) => {
  const [time, setTime] = useState<number>(getCurrentTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getCurrentTime());
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <>{callback(time)}</>;
};
