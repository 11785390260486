import { FROM_LOCATIONS } from '@src/common/constants/system';
import React, { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { sendRoomJoined } from './analyticsThunk';

interface Props {
  roomId: string;
}

const RoomService: React.FC<Props> = ({ roomId }) => {
  const location = useLocation();
  const from = location.state?.from as FROM_LOCATIONS;

  useEffect(() => {
    sendRoomJoined(roomId, from);
  }, []);

  return <></>;
};

export { RoomService };
