import { useAppSelector } from '@app/hooks';
import { IdType } from '@shared/generics';
import { selectPlayerVideoState } from '@store/reducers/common';
import { ILocalAudioTrack, ILocalVideoTrack, IRemoteAudioTrack, IRemoteVideoTrack } from 'agora-rtc-sdk-ng';
import { useEffect } from 'react';

export interface VideoPlayerProps {
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  id: IdType;
}

const AgoraMedia = (props: VideoPlayerProps) => {
  const videoState = useAppSelector(selectPlayerVideoState(props.id));
  const containerId = videoState?.containerId;

  useEffect(() => {
    if (!containerId) return;
    props.videoTrack?.play(containerId);
    return () => {
      props.videoTrack?.stop();
    };
  }, [containerId, props.videoTrack, videoState?.shouldRestart]);

  useEffect(() => {
    props.audioTrack?.play();
    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack]);

  return <></>;
  // const container = useRef<HTMLDivElement>(null);
  // <div ref={container}  className="video-player" style={{ width: "320px", height: "240px"}}></div>
};

export { AgoraMedia };
