import React from 'react';
import { isMobile, withOrientationChange } from 'react-device-detect';
import orient from '@art/icons/orient.svg';
import { styles } from './styles';
import Backdrop from '@material-ui/core/Backdrop/Backdrop';

const OrientalModal: React.FC = () => {
  const classes = styles();
  const shouldShowModal = isMobile && window.innerHeight < 0.7 * window.innerWidth;

  return (
    <Backdrop open={shouldShowModal} className={classes.modal}>
      <>
        <img className={classes.img} src={orient} alt="phones" />
        <div className={classes.text}>Turn back to the vertical position</div>
      </>
    </Backdrop>
  );
};

export default withOrientationChange(OrientalModal);
