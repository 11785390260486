import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles(() => ({
  VideoPlayerContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: 'black',
  },
  VideoTrack: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '0px',
    top: '0px',
    transform: 'rotateY(180deg)',
    objectFit: 'cover',
  },
  AudioTrack: {
    width: '0px',
    height: '0px',
    position: 'absolute',
  },
}));
