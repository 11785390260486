import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const ToggleVideoToSmall = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
    <rect width="36" height="36" rx="2" fill="black" fillOpacity="0.6" />
    <path
      d="M21 20C20.4477 20 20 20.4477 20 21V27.5C20 28.3284 20.6716 29 21.5 29C22.3284 29 23 28.3284 23 27.5V23H27.5C28.3284 23 29 22.3284 29 21.5C29 20.6716 28.3284 20 27.5 20H21Z"
      fill="#F2F2F2"
    />
    <path
      d="M23 13H27.5C28.3284 13 29 13.6716 29 14.5C29 15.3284 28.3284 16 27.5 16H21C20.4477 16 20 15.5523 20 15V8.5C20 7.67157 20.6716 7 21.5 7C22.3284 7 23 7.67157 23 8.5V13Z"
      fill="#F2F2F2"
    />
    <path
      d="M8.5 20C7.67157 20 7 20.6716 7 21.5C7 22.3284 7.67157 23 8.5 23H13V27.5C13 28.3284 13.6716 29 14.5 29C15.3284 29 16 28.3284 16 27.5V21C16 20.4477 15.5523 20 15 20H8.5Z"
      fill="#F2F2F2"
    />
    <path
      d="M7 14.5C7 13.6716 7.67157 13 8.5 13H13V8.5C13 7.67157 13.6716 7 14.5 7C15.3284 7 16 7.67157 16 8.5V15C16 15.5523 15.5523 16 15 16H8.5C7.67157 16 7 15.3284 7 14.5Z"
      fill="#F2F2F2"
    />
  </svg>
);

export default ToggleVideoToSmall;
