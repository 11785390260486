import { IdType, ObjectType } from './../../shared/generics';
import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { createPayload, createSimpleDraftReducer, createSimpleReducer, simpleMergeThunk } from '@store/reduxHelpers';

export enum MUTE_TYPE {
  OWN = 0,
  ROOM_OWNER,
  GAME,
}

// const defaultAccess: (boolean | undefined)[] = [undefined, undefined, undefined];
const defaultObject: ObjectType = {};

export interface DeviceInfo {
  deviceId: string;
  label: string;
}

const initialState = {
  audioAccess: [false, undefined, undefined],
  videoAccess: [false, undefined, undefined],
  gameParams: defaultObject,
  microphones: [] as DeviceInfo[],
  microphone: undefined as DeviceInfo | undefined,
  cameras: [] as DeviceInfo[],
  camera: undefined as DeviceInfo | undefined,
  resetAgora: {},
  isAgoraLoading: false,
};

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;
const simpleMerge = simpleMergeThunk<StateType, PayloadType>();

export const playerDataSlice = createSlice({
  name: 'playerData',
  initialState: initialState,
  reducers: {
    setAudioAccess: {
      reducer: (state, action: PayloadAction<{ type: MUTE_TYPE; value: boolean | undefined }>) => {
        state.audioAccess[action.payload.type] = action.payload.value;
      },
      prepare: (type: MUTE_TYPE, value: boolean | undefined) => createPayload({ type, value }),
    },
    setVideoAccess: {
      reducer: (state, action: PayloadAction<{ type: MUTE_TYPE; value: boolean | undefined }>) => {
        state.videoAccess[action.payload.type] = action.payload.value;
      },
      prepare: (type: MUTE_TYPE, value: boolean | undefined) => createPayload({ type, value }),
    },
    setGameParams: createSimpleDraftReducer('gameParams'),
    setMicrophones: createSimpleDraftReducer('microphones'),
    setCameras: createSimpleDraftReducer('cameras'),
    setMicrophone: createSimpleDraftReducer('microphone'),
    setCamera: createSimpleDraftReducer('camera'),
    setResetAgora: createSimpleDraftReducer('resetAgora'),
    setIsAgoraLoading: createSimpleDraftReducer('isAgoraLoading'),
  },
});

export const {
  setAudioAccess,
  setVideoAccess,
  setGameParams,
  setMicrophones,
  setCameras,
  setCamera,
  setMicrophone,
  setResetAgora,
  setIsAgoraLoading,
} = playerDataSlice.actions;

const selectState = (state: RootState) => state.playerData || initialState;

export const selectIsAudioEnabled = createSelector(
  selectState,
  (state) => !state.audioAccess.some((access) => access == false)
);
export const selectIsVideoEnabled = createSelector(
  selectState,
  (state) => !state.videoAccess.some((access) => access == false)
);

export const selectIsAudioRestricted = createSelector(selectState, (state) =>
  state.audioAccess.some((access, index) => index > 0 && access != undefined)
);
export const selectIsVideoRestricted = createSelector(selectState, (state) =>
  state.videoAccess.some((access, index) => index > 0 && access != undefined)
);

export const selectAudioInfo = createSelector(
  selectIsAudioEnabled,
  selectIsAudioRestricted,
  (isAudioEnabled, isAudioRestricted) => [isAudioEnabled, isAudioRestricted]
);
export const selectVideoInfo = createSelector(
  selectIsVideoEnabled,
  selectIsVideoRestricted,
  (isVideoEnabled, isVideoRestricted) => [isVideoEnabled, isVideoRestricted]
);

export const selectGameParams = createSelector(selectState, (state) => state.gameParams);
export const selectMicrophones = createSelector(selectState, (state) => state.microphones);
export const selectCameras = createSelector(selectState, (state) => state.cameras);
export const selectMicrophone = createSelector(selectState, (state) => state.microphone);
export const selectCamera = createSelector(selectState, (state) => state.camera);
export const selectResetAgora = createSelector(selectState, (state) => state.resetAgora);
export const selectIsAgoraLoading = createSelector(selectState, (state) => state.isAgoraLoading);

export const selectHasMultipleCameras = createSelector(selectCameras, (cameras) => cameras.length > 1);
