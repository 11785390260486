import { useAppDispatch, useAppSelector } from '@app/hooks';
import { deviceSizeService } from '@common/screenSizes';
import { selectCurrentPlayerVideoState, selectResetData } from '@store/reducers/common';
import { selectIsInRoom, getRoomId, selectIsFirstPlayer } from '@store/room/roomSlice';
import { selectCurrentLocation } from '@store/reducers/session.reducer';
import React, { useEffect, useState } from 'react';
import { analytics } from './amplitude';
import { loadHomePageData } from '@src/graphql/loadHomePageData';
import {
  selectPlayerIdWeak,
  selectHasPlayerRoom,
  selectEnergy,
  selectEnergyAmount,
  selectBalance,
} from '@src/store/reducers/player.reducer';
import { setShouldShowLanguagePopup } from '@src/store/reducers/popups';
import { useAuth } from 'oidc-react';
import { requestEnergy, requestPlayerData, requestServerSync } from '@src/graphql/player.queries';

const AppService: React.FC = () => {
  const dispatch = useAppDispatch();
  const hasPlayerRoom = useAppSelector(selectHasPlayerRoom);
  const roomId = useAppSelector(getRoomId);
  const isInRoom = useAppSelector(selectIsInRoom);
  const isFirstPlayer = useAppSelector(selectIsFirstPlayer);
  const currentLocation = useAppSelector(selectCurrentLocation);
  const playerVideoState = useAppSelector(selectCurrentPlayerVideoState);
  const playerId = useAppSelector(selectPlayerIdWeak);
  const energy = useAppSelector(selectEnergy);
  const energyAmount = useAppSelector(selectEnergyAmount);
  const balance = useAppSelector(selectBalance);
  const resetData = useAppSelector(selectResetData);
  const _ = deviceSizeService();
  const auth = useAuth();
  const [hasTriedLogin, setHasTriedLogin] = useState(false);
  const isAuthLoading = auth.isLoading;

  useEffect(() => {
    analytics.initialize();

    const firstLaunch = localStorage.getItem('firstLaunch');
    if (!firstLaunch) {
      console.log('FIRST LAUNCH');
      analytics.logEvent(analytics.EVENTS.FIRST_LAUNCH);
      dispatch(setShouldShowLanguagePopup(true));
      localStorage.setItem('firstLaunch', 'true');
    }

    return () => {
      analytics.logEvent(analytics.EVENTS.APP_QUIT);
    };
  }, []);

  useEffect(() => {
    analytics.setUserProperties({
      hasRoom: hasPlayerRoom,
      roomId: roomId || '',
      isInRoom: isInRoom,
      isFirstPlayer: isFirstPlayer,
      userAgent: navigator.userAgent,
      hasVideo: playerVideoState.isVideoEnabled || false,
      energy: energyAmount,
      balance,
    });
  }, [hasPlayerRoom, isInRoom, isFirstPlayer, roomId, playerVideoState, energy, balance]);

  useEffect(() => {
    if (currentLocation) {
      analytics.logEvent(analytics.EVENTS.PAGE_SHOWN, { pathname: currentLocation });
    }
  }, [currentLocation]);

  useEffect(() => {
    dispatch(loadHomePageData);
    dispatch(requestServerSync);
  }, []);

  useEffect(() => {
    const diff = energy.end - Date.now();
    if (diff > 0) {
      const timeout = setTimeout(() => dispatch(requestEnergy), diff);
      return () => clearTimeout(timeout);
    }
  }, [energy.end]);

  useEffect(() => {
    const diff = resetData - Date.now();
    if (resetData > 0) {
      const timeout = setTimeout(() => dispatch(requestPlayerData), diff);
      return () => clearTimeout(timeout);
    }
  }, [resetData]);

  return <></>;
};

export { AppService };
