import { createRef } from 'react';
import { createSelector } from 'reselect';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import { createSimpleDraftReducer, createSimpleReducer, simpleMergeThunk } from '@store/reduxHelpers';
import { IdType } from '@src/shared/generics';
import { CLIENT_EVENTS } from '@src/shared/socketEvents/eventTypes';
import WSClient from '@src/sockets';
import { CardsDealingClientEvent } from '@src/shared/socketEvents/sessionEvents';
import { Instance } from '@popperjs/core';

export enum MENU_PAGE {
  MAIN,
  PROFILE,
  CHAT,
  COIN,
  ENERGY,
}

export enum SNACKBAR_TYPE {
  UNDEFINED = 'UNDEFINED',
  WITHDRAW = 'WITHDRAW',
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
}

export enum SNACKBAR_SUCCESS {
  UNDEFINED = 'UNDEFINED',
  SUCCESS = 'SUCCESS',
  UNSUCCESS = 'UNSUCCESS',
}

export enum PopupState {
  ABSENT = 'ABSENT',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

type OnCardsDealingType = (active: boolean) => void;

const initialState = {
  inviteOnce: false,
  invite: false,
  roomFull: false,
  inviteLink: '',
  page: MENU_PAGE.MAIN,
  shouldShowLanguagePopup: false,
  operationSuccess: SNACKBAR_SUCCESS.UNDEFINED,
  isOperationUnsuccessful: false,
  notificationType: SNACKBAR_TYPE.UNDEFINED,
  dealCardsData: {
    state: PopupState.ABSENT,
    sessionId: '',
    onCardsDealing: undefined as OnCardsDealingType | undefined,
  },
  tooltip: null as Instance | null,
  tooltipId: '',
  cardFieldId: '',
};

type StateType = typeof initialState;
type PayloadType = Partial<StateType>;

export const popupsSlice = createSlice({
  name: 'popups',
  initialState: initialState,
  reducers: {
    setInviteShownOnce: createSimpleDraftReducer('inviteOnce'),
    setInviteShown: createSimpleReducer('invite'),
    setRoomFullShown: createSimpleReducer('roomFull'),
    setInviteLink: createSimpleReducer('inviteLink'),
    setPage: createSimpleReducer('page'),
    setShouldShowLanguagePopup: createSimpleReducer('shouldShowLanguagePopup'),
    setOperationSuccess: createSimpleReducer('operationSuccess'),
    setNotificationType: createSimpleReducer('notificationType'),
    setDealCardsData: createSimpleReducer('dealCardsData'),
    setTooltip: createSimpleReducer('tooltip'),
    setTooltipId: createSimpleReducer('tooltipId'),
    setCardFieldId: createSimpleReducer('cardFieldId'),
  },
});

export const {
  setInviteShownOnce,
  setInviteShown,
  setRoomFullShown,
  setInviteLink,
  setPage,
  setShouldShowLanguagePopup,
  setOperationSuccess,
  setNotificationType,
  setDealCardsData,
  setTooltip,
  setTooltipId,
} = popupsSlice.actions;

const selectState = (state: RootState) => state.popups || initialState;

export const selectIsInviteShownOnce = createSelector(selectState, (state) => state.inviteOnce);
export const selectIsInviteShown = createSelector(selectState, (state) => state.invite);
export const selectIsRoomFullShown = createSelector(selectState, (state) => state.roomFull);
export const selectInviteLink = createSelector(selectState, (state) => state.inviteLink);
export const selectPage = createSelector(selectState, (state) => state.page);
export const selectIsProfilePage = createSelector(selectState, (state) => state.page === MENU_PAGE.PROFILE);
export const selectIsCoinPage = createSelector(selectState, (state) => state.page === MENU_PAGE.COIN);
export const selectIsEnergyPage = createSelector(selectState, (state) => state.page === MENU_PAGE.ENERGY);
export const selectShouldShowLanguagePopup = createSelector(selectState, (state) => state.shouldShowLanguagePopup);
export const selectOperationSuccess = createSelector(selectState, (state) => state.operationSuccess);
export const selectNotificationType = createSelector(selectState, (state) => state.notificationType);
export const selectDealCardsData = createSelector(selectState, (state) => state.dealCardsData);
export const selectTooltip = createSelector(selectState, (state) => state.tooltip);
export const selectTooltipId = createSelector(selectState, (state) => state.tooltipId);
export const selectCardFieldId = createSelector(selectState, (state) => state.cardFieldId);

export const selectDealingCardsEvent = createSelector(selectDealCardsData, (data) => () =>
  WSClient.emitStrict<CardsDealingClientEvent>(CLIENT_EVENTS.PLAYER_DEALING_CARDS, {
    sessionId: data.sessionId,
    active: true,
  })
);

export const findTooltip = createSelector(
  selectTooltipId,
  (tooltipId) => document.getElementById(tooltipId) as Instance | null
);
