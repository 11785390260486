import { createAction, createReducer, createSelector } from '@reduxjs/toolkit';
import { simpleMerge, createPayload } from '@store/reduxHelpers';

export const LOADER_STORE_NAME = 'loader';

export type LoaderStoreType = {
  login: boolean;
  gameList: boolean;
  hosts: boolean;
  checkRoom: boolean;
  createRoom: boolean;
  joinRoom: boolean;
  homePage: boolean;
  rooms: boolean;
};

const initialStore: LoaderStoreType = {
  login: false,
  gameList: false,
  hosts: false,
  checkRoom: false,
  createRoom: false,
  joinRoom: true,
  homePage: true,
  rooms: true,
};

type PayloadType = Partial<LoaderStoreType>;

type IState = {
  [LOADER_STORE_NAME]: LoaderStoreType;
};

const createStartLoadAction = (prefix: string, key: string) =>
  createAction(`${prefix}::START`, () => createPayload({ [key]: true }));
const createFinishLoadAction = (prefix: string, key: string) =>
  createAction(`${prefix}::FINISH`, () => createPayload({ [key]: false }));

export const homePageLoadStart = createStartLoadAction('HOME_PAGE_LOAD', 'homePage');
export const homePageLoadFinish = createFinishLoadAction('HOME_PAGE_LOAD', 'homePage');

export const loginStart = createStartLoadAction('LOGIN_LOAD', 'login');
export const loginFinish = createFinishLoadAction('LOGIN_LOAD', 'login');

export const roomsLoadStart = createStartLoadAction('ROOMS_LOAD', 'rooms');
export const roomsLoadFinish = createFinishLoadAction('ROOMS_LOAD', 'rooms');

export const gameListStart = createStartLoadAction('GAME_LIST_LOAD', 'gameList');
export const gameListFinish = createFinishLoadAction('GAME_LIST_LOAD', 'gameList');

export const hostsLoadStart = createStartLoadAction('HOSTS_LOAD', 'hosts');
export const hostsLoadFinish = createFinishLoadAction('HOSTS_LOAD', 'hosts');

export const checkRoomStart = createStartLoadAction('CHECK_ROOM_LOAD', 'checkRoom');
export const checkRoomFinish = createFinishLoadAction('CHECK_ROOM_LOAD', 'checkRoom');

export const createRoomStart = createStartLoadAction('CREATE_ROOM_LOAD', 'createRoom');
export const createRoomFinish = createFinishLoadAction('CREATE_ROOM_LOAD', 'createRoom');

export const joinRoomStart = createStartLoadAction('JOIN_ROOM_LOAD', 'joinRoom');
export const joinRoomFinish = createFinishLoadAction('JOIN_ROOM_LOAD', 'joinRoom');

const loaderReducer = createReducer(initialStore, (builder) => {
  builder
    .addCase(homePageLoadStart, simpleMerge)
    .addCase(homePageLoadFinish, simpleMerge)
    .addCase(roomsLoadStart, simpleMerge)
    .addCase(roomsLoadFinish, simpleMerge)
    .addCase(loginStart, simpleMerge)
    .addCase(loginFinish, simpleMerge)
    .addCase(hostsLoadStart, simpleMerge)
    .addCase(hostsLoadFinish, simpleMerge)
    .addCase(gameListStart, simpleMerge)
    .addCase(gameListFinish, simpleMerge)
    .addCase(checkRoomStart, simpleMerge)
    .addCase(checkRoomFinish, simpleMerge)
    .addCase(createRoomStart, simpleMerge)
    .addCase(createRoomFinish, simpleMerge)
    .addCase(joinRoomStart, simpleMerge)
    .addCase(joinRoomFinish, simpleMerge);
});

export default loaderReducer;

const loaderState = (state: IState) => state.loader || initialStore;

// homePageLoadingSelector;
export const getIsHomePageLoading = createSelector(loaderState, (loader) => loader.homePage);

export const selectAreRoomsLoading = createSelector(loaderState, (loader) => loader.rooms);

export const getIsPlayerLoading = createSelector(loaderState, (loader) => loader.login);

export const getIsGameListLoading = createSelector(loaderState, (loader) => loader.gameList);

export const getAreHostsLoading = createSelector(loaderState, (loader) => loader.hosts);

export const getIsCheckRoom = createSelector(loaderState, (loader) => loader.checkRoom);

export const getIsCreateRoomLoading = createSelector(loaderState, (loader) => loader.createRoom);

export const getIsJoinRoomLoading = createSelector(loaderState, (loader) => loader.joinRoom);

export const storeName = LOADER_STORE_NAME;
