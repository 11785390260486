import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const useStyles = makeStyles((theme) => ({
  formInner: {
    color: 'inherit',
    flexDirection: 'column',
    width: '100%',
  },

  customInput: {
    display: 'flex',
    position: 'relative',
    width: ' 100%',
    alignItems: 'center',
    justifyContent: 'center',

    '& > input': {
      background: 'rgba(15, 94, 213, 0.1)',
      boxShadow: 'inset 0px 2px 8px rgb(16 90 211 / 5%)',
      borderRadius: '30px',
      border: 'none',
      padding: '11px 20px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '21px',
      color: '#1B2036',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: '24px',
        lineHeight: '21px',
      },
      '&::-webkit-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-webkit-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-inner-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&::-moz-outer-spin-button': {
        display: 'none',
        webkitAppearance: 'none',
        margin: 0,
      },
      '&:hover': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
      '&:focus': {
        '&::-webkit-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-inner-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
        '&::-moz-outer-spin-button': {
          display: 'none',
          webkitAppearance: 'none',
          margin: 0,
        },
      },
    },
  },
}));
