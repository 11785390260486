import React, { ChangeEvent, ReactNode } from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';

const RangeCustom = withStyles({
  root: {
    color: '#D7E4FD',
    height: 8,
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },

    // hide label when value is NaN
    '&.is-nan-value': {
      '& .MuiSlider-valueLabel': {
        display: 'none',
      },
    },

    '&.isVote': {
      pointerEvents: 'none',
      '& .MuiSlider-thumb': {
        backgroundColor: '#D7E4FD',
      },
      '& .MuiSlider-valueLabel': {
        '& > span': {
          '& > span': {
            color: '#1B2036',
          },
        },
      },
    },

    '&.Mui-disabled': {
      '& .MuiSlider-thumb': {
        display: 'none',
      },
    },
  },

  // range circle
  thumb: {
    height: '48px',
    width: '48px',
    backgroundColor: '#2C74F3',
    marginTop: '-20px',
    marginLeft: '-24px',
    transitionProperty: 'background-color',
    transitionDuration: '0.23s',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3662AE',
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: '#3662AE',
      boxShadow: 'none',
    },
    // other events
    // '&:focus, &:hover, &$active': {
    //   outline: '1px solid red',
    // },
  },

  active: {
    '& > span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '-40px',
      width: '48px',
      height: '41px',
      borderRadius: '4px',
      border: '1px solid #E8E9EB',
      backgroundColor: '#fff',
      transitionProperty: 'border, background-color',
      transitionDuration: '50ms, 50ms',
      '& > span': {
        textAlign: 'center',
        '& > span': {
          fontSize: '20px',
          lineHeight: '20px',
          fontWeight: 500,
          color: '#1B2036',
        },
      },
    },
  },

  // floating label
  valueLabel: {
    left: 'calc(-50% + 24px)',
    top: '10px',
    transitionProperty: 'top',
    transitionDuration: '50ms',
    '& > span': {
      height: '48px',
      width: '48px',
      borderRadius: '50%',
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      '& > span': {
        borderRadius: 0,
        fontSize: '20px',
        lineHeight: '20px',
        fontWeight: 500,
        textAlign: 'center',
        color: 'white',
      },
    },
  },

  track: {
    height: '8px',
    borderRadius: '10px 0 0 10px',
  },

  rail: {
    background: '#F3F4F5',
    opacity: 1,
    height: '8px',
    borderRadius: '10px',
    border: '1px solid #E8E9EB',
  },
})(Slider);

interface Props {
  value: number;
  min?: number;
  max?: number;
  activePlayer?: boolean;
  isVote?: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<{}>, value: number | number[]) => void | { children?: ReactNode };
  onChangeCommitted?: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
}

const CustomizedSlider: React.FC<Props> = ({
  value,
  min = 1,
  max = 10,
  activePlayer,
  isVote,
  disabled,
  onChange,
  onChangeCommitted,
}) => {
  return (
    <RangeCustom
      className={`
        ${isNaN(value) ? 'is-nan-value' : ''} 
        ${!activePlayer ? 'notActivePlayer' : ''}
        ${isVote ? 'isVote' : ''}
      `}
      valueLabelDisplay="on"
      step={1}
      min={min}
      max={max}
      value={value}
      onChange={onChange}
      onChangeCommitted={onChangeCommitted}
      disabled={disabled}
    />
  );
};

export default CustomizedSlider;
