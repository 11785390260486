import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { createRoomThunk } from '@store/thunk/room';
import { selectAreRoomsLoading } from '@store/reducers/loadActions';
import { FROM_LOCATIONS } from '@src/common/constants/system';
import { IdType } from '@shared/generics';
import { selectPlayerRoomId } from '@src/store/reducers/player.reducer';

interface RouterProps {
  roomId: IdType;
}

interface Props extends RouteComponentProps<RouterProps> {}

const DeepLink: React.FC<Props> = ({ match, location }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const areRoomsLoading = useAppSelector(selectAreRoomsLoading);
  const playerRoomId = useAppSelector(selectPlayerRoomId);

  const onRoomClick = (roomId: string) => {
    history.push(`/room/${roomId}${location.search}`, { from: FROM_LOCATIONS.DEEP_LINK });
  };

  const onRoomAutocreate = () => {
    dispatch(createRoomThunk('', (roomId) => onRoomClick(roomId)));
  };

  useEffect(() => {
    if (areRoomsLoading) return;

    playerRoomId ? onRoomClick(playerRoomId) : onRoomAutocreate();
  }, [areRoomsLoading]);

  return <></>;
};

export { DeepLink };
