import { gql } from '@apollo/client';
import { LOCALE } from '@shared/common';

export interface IImageSourceSet {
  x1?: string;
  x2?: string;
  x3?: string;
  mobile?: string;
  desktop?: string;
  tablet?: string;
}

export interface IImage {
  srcSet?: IImageSourceSet;
  src?: string;
}

export const imageFragment = gql`
  fragment ImageFragment on Image {
    srcSet {
      x1
      x2
      x3
      mobile
      tablet
      desktop
    }
    src
  }
`;

export interface IVideoData {
  video: string;
  poster: string;
}

export interface IVideo {
  locale: LOCALE;
  mobile?: IVideoData;
  desktop?: IVideoData;
  tablet?: IVideoData;
}

export const videoDataFragment = gql`
  fragment VideoDataFragment on VideoData {
    video
    poster
  }
`;

export const videoFragment = gql`
  fragment VideoFragment on Video {
    locale
    mobile {
      ...VideoDataFragment
    }
    tablet {
      ...VideoDataFragment
    }
    desktop {
      ...VideoDataFragment
    }
  }
  ${videoDataFragment}
`;
