import _ from 'lodash';
import { IdType } from '@shared/generics';
import { ObjectType } from '@src/common/generics';
import { createSelector } from 'reselect';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardDeck } from '@graphql/queries';
import { RootState } from '@app/store';
import { IActiveRoom } from '@src/shared/misc/room.fragment';
import { selectPlayerId, selectPlayerIdWeak, selectPlayerDecks } from './player.reducer';
import { createSimpleReducer } from '../reduxHelpers';
import { Avatar } from '@src/shared/misc/roomFeatures.types';

export enum VideoPlacement {
  SMALL,
  LARGE,
}

export type VideoState = {
  isVideoEnabled: boolean;
  isAudioEnabled: boolean;
  containerId: string;
  placement: VideoPlacement;
  shouldRestart: ObjectType;
};

export type VideoStateRequest = {
  shouldEnableAudio?: boolean;
  shouldEnableVideo?: boolean;
};

export interface VideoStateData {
  [playerId: string]: Partial<VideoState>;
}

type VideoStatesPayload = PayloadAction<VideoStateData>;

export interface IPlayerCard {
  playerId: IdType;
  avatar?: Partial<Avatar>;
  name: string;
  isStreamer: boolean;
}

export type CommonStoreState = {
  decks: CardDeck[];
  rooms: IActiveRoom[];
  playerVideoStateRequest: VideoStateRequest;
  playersVideoState: {
    [playerId: string]: VideoState;
  };
  isWsConnected: boolean;
  resetData: number;
};

const initialStore: CommonStoreState = {
  decks: [],
  rooms: [],
  playerVideoStateRequest: {
    shouldEnableAudio: undefined,
    shouldEnableVideo: undefined,
  },
  playersVideoState: {},
  isWsConnected: false,
  resetData: 0,
};

const commonSlice = createSlice({
  name: 'common',
  initialState: initialStore,
  reducers: {
    setDecks: createSimpleReducer('decks'),
    setRooms: (state, action: PayloadAction<IActiveRoom[]>) => {
      return {
        ...state,
        rooms: action.payload.filter((value) => value !== null),
      };
    },
    setWSConnectStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isWsConnected: action.payload,
      };
    },
    setVideoState: (state, action: PayloadAction<{ playerId: string; data: Partial<VideoState> }>) => {
      return {
        ...state,
        playersVideoState: {
          ...state.playersVideoState,
          [action.payload.playerId]: {
            ...state.playersVideoState[action.payload.playerId],
            ...action.payload.data,
          },
        },
      };
    },
    setVideoStates: (state, action: VideoStatesPayload) => {
      // TODO: get rid from immerse merge.
      _.merge(state.playersVideoState, action.payload);
      return state;
    },
    setVideoStateRequest: (state, action: PayloadAction<Partial<VideoStateRequest>>) => {
      return {
        ...state,
        playerVideoStateRequest: {
          ...state.playerVideoStateRequest,
          ...action.payload,
        },
      };
    },
    setResetDataConfig: (state, action: PayloadAction<number>) => {
      state.resetData = action.payload;
    },
  },
});

export const {
  setDecks,
  setRooms,
  setWSConnectStatus,
  setVideoState,
  setVideoStates,
  setVideoStateRequest,
  setResetDataConfig,
} = commonSlice.actions;
export default commonSlice;

const commonState = (state: RootState) => state.common || initialStore;

export const getRooms = createSelector(commonState, (state) => state.rooms);
export const selectRoomsNotOwned = createSelector(getRooms, selectPlayerIdWeak, (rooms, playerId) =>
  rooms.filter((room) => !room.owner || room.owner !== playerId)
);
export const selectDecks = createSelector(commonState, (state) => state.decks);

export const selectResetData = createSelector(commonState, (state) => state.resetData);
export const getPlayersVideoState = createSelector(commonState, (state) => state.playersVideoState);

export const getIsWsConnected = createSelector(commonState, (state) => state.isWsConnected);

export const selectCurrentPlayerVideoState = createSelector(commonState, selectPlayerIdWeak, (state, playerId) =>
  playerId ? state.playersVideoState[playerId] || {} : ({} as VideoState)
);
export const selectPlayerVideoContainerId = (playerId: string) =>
  createSelector(getPlayersVideoState, (videoState) => videoState[playerId]?.containerId);

export const selectPlayerVideoState = (playerId: string) =>
  createSelector(getPlayersVideoState, (videoState) => videoState[playerId]);

export const selectPlayerVideoStatePlacement = (playerId: string) =>
  createSelector(selectPlayerVideoState(playerId), (videoState) => videoState.placement);

export const selectVideoStateRequest = createSelector(commonState, (state) => state.playerVideoStateRequest);

// helpers. we have to memoize them.
export const getGameRooms = (gameId: string) =>
  createSelector(getRooms, (rooms) => rooms.filter((room) => room.gameId === gameId));

export const selectPlayerDecksExtended = createSelector(selectDecks, selectPlayerDecks, (decks, deckIds) =>
  decks.filter((deck) => deckIds.includes(deck.id))
);

export const selectDeck = (deckId?: IdType) =>
  createSelector(selectDecks, (decks) => decks.find((deck) => deck.id === deckId));
