import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const InfoIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50434 17.0835C6.88476 17.3397 5.44238 18 4.114 18C2.88476 18 1.20942 17.4383 1.20942 15.6348C1.20942 13.5456 2.69641 10.5694 2.69641 8.84479C2.69641 6.92308 0.183395 7.96277 0 8.03175L0.252789 7.00684C0.872368 6.75554 2.34449 6.09034 3.64808 6.09034C4.70384 6.09034 6.5031 6.46975 6.5031 8.45552C6.5031 10.4511 5.01611 13.6441 5.01611 15.2456C5.01611 15.689 4.95663 16.3887 6.34449 16.3887C6.88476 16.3887 7.62825 16.1177 7.76208 16.0586L7.50434 17.0835ZM5.74473 4.19327C4.84758 4.19327 3.48451 3.58226 3.48451 2.10402C3.48451 0.606077 4.9368 0 5.74473 0C6.70136 0 8 0.694771 8 2.10402C8 2.95647 7.3259 4.19327 5.74473 4.19327Z"
      fill={color}
    />
  </svg>
);

export default InfoIcon;
