import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setShouldUpdateOffsets } from '@store/reducers/session.reducer';
import { selectCounterPartyId, selectIsInRoom } from '@store/room/roomSlice';
import { reconnectInRoomThunk } from '@store/thunk/room';
import WSClient from '@src/sockets';
import { visibilitySubscribeActionThunk, touchListenerThunk, clickListenerThunk } from './helpers/visibilityChange';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectPlayerId } from '@src/store/reducers/player.reducer';
// import PersonsContainer from './parts/PersonsContainer/PersonsContainer';
import { useAgora } from './helpers/useAgora';
import { AgoraMedia } from './helpers/agoraMedia';
import { VideoChatControl } from './VideoChatControl';
import { styles as useStyles } from './VideoChat.styles';
import { usePeer } from './simplePeer/usePeer';
import { PeerMedia } from './simplePeer/peerMedia';
import { IS_AGORA_ENABLED } from '@src/common/constants/system';

interface Props {
  isLoading: boolean;
}

const VideoChat = React.forwardRef<HTMLDivElement, Props>(function VideoChat(props, ref) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const playerId = useAppSelector(selectPlayerId);
  const counterPartyId = useAppSelector(selectCounterPartyId);
  const isInRoom = useAppSelector(selectIsInRoom);
  const { roomId } = useParams<{ roomId: string }>();
  const agora = IS_AGORA_ENABLED ? useAgora(true, roomId) : undefined;
  const peer = !IS_AGORA_ENABLED ? usePeer(true, roomId) : undefined;
  const [playStream, setPlayStream] = useState(true);

  useEffect(() => {
    dispatch(setShouldUpdateOffsets({}));

    return () => {
      console.log('leaving videoChat');
      // destroyClient();
    };
  }, [props.isLoading]);

  useEffect(
    visibilitySubscribeActionThunk(
      function () {
        WSClient.close();
        setPlayStream(false);
        peer?.stopTracks();
        agora?.stopTracks();
      },

      function () {
        agora?.resumeTracks();
        peer?.resumeTracks();
        setPlayStream(true);
        dispatch(reconnectInRoomThunk(roomId));
      }
    ),
    [peer?.localMedia, agora?.stopTracks, agora?.resumeTracks]
  );

  // ToDo: Make callbacks reassignable after corresponding agora events.
  useEffect(
    touchListenerThunk(() => peer?.setAutoPlayEnabled()), //unmuteRemoteStreams()),
    []
  );

  useEffect(
    clickListenerThunk(() => peer?.setAutoPlayEnabled()), //unmuteRemoteStreams()),
    []
  );

  const renderAgoraMedia = () => {
    return (
      <>
        <VideoChatControl />

        {playStream && isInRoom && (
          <>
            <AgoraMedia videoTrack={agora!.localVideoTrack} audioTrack={undefined} id={playerId} />

            {agora!.remoteUsers.map((user, index) => (
              <AgoraMedia
                videoTrack={user.videoTrack}
                audioTrack={user.audioTrack}
                id={user.uid as string}
                key={index}
              />
            ))}
          </>
        )}
      </>
    );
  };

  const renderPeerMedia = () => {
    return (
      <>
        <VideoChatControl />

        {playStream && isInRoom && (
          <>
            <PeerMedia media={peer!.localMedia} id={playerId} shouldMute={true} />
            <PeerMedia media={peer!.remoteMedia} id={counterPartyId} shouldMute={!peer!.isAutoPlayEnabled} />
          </>
        )}
      </>
    );
  };

  return (
    <div ref={ref} className={classes.VideoChat}>
      {IS_AGORA_ENABLED ? renderAgoraMedia() : renderPeerMedia()}
    </div>
  );
});

export default VideoChat;
