import {
  StandardSessionState,
  STANDARD_SESSION_STATE,
  TableCard,
} from '@src/shared/sessionInterfaces/standardSession.socket';

export const initialMapState = {
  DEFAULT: false,
  DEAL_CARD: false,
  TAKE_CARD: false,
  DRAG: false,
  ENLARGE: false,
};

export const defaultSessionState: StandardSessionState = {
  sessionId: '',
  state: STANDARD_SESSION_STATE.DEFAULT,
  question: '',
  slots: [],
  cards: [],
  discarded: [],
  enlarged: -1,
};

export type MapState = typeof initialMapState;

export enum CardPlacement {
  TABLE = 'table',
  TABLE_TOP = 'table_top',
  DISCARD = 'discard',
  MODAL = 'modal',
  ENLARGED = 'enlarged',
}

export interface SlotInfo {
  index: number;
  dragEnabled: boolean;
}

export interface ITableSlot {
  cards: TableCard[];
  info: SlotInfo;
}
