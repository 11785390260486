import { createMuiTheme } from '@material-ui/core/styles';

export const INTELLECTUAL_GREY = '#1B2036';
export const LIGHT_GREY = '#EAEAEA';
export const BACKGROUND_COLOR = '#f5f5f5';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#68A6FC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F98593',
      // contrastText: '#fff',
    },
    error: {
      main: '#F98593',
    },
    background: {
      paper: BACKGROUND_COLOR,
    },
  },
  // status: {
  //   danger: 'orange',
  // },
  typography: {
    fontFamily: '"Titillium Web", sans-serif',
    h2: {},
    h3: {},
    h4: {},
    h5: {},
    body1: {},
    body2: {},
    button: {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '22px',
      textAlign: 'center',
      textTransform: 'none',
      color: '#FDFCFA',
    },
    caption: {
      //TODO: check this
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 10,
      lineHeight: '12px',
      textAlign: 'center',
    },
    overline: {},
    // color: INTELLECTUAL_GREY,
  },
  overrides: {
    MuiCardMedia: {
      media: {
        height: 150,
        objectFit: 'fill',
        maxWidth: 150,
        margin: '0 auto',
        padding: 10,
      },
    },
    MuiContainer: {
      root: {
        justifyContent: 'center',
        display: 'flex', //block for small screens safari fix
        paddingLeft: '0!important',
        paddingRight: '0!important',
        maxWidth: '100%',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#ffffff',
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 18,
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FDFCFA',
        minHeight: 48,
      },
      contained: {
        color: '#FDFCFA',
      },
    },
  },
});

theme.typography.h3 = {
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 26,
  lineHeight: '26px',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: 36,
    lineHeight: '36px',
  },
};
theme.typography.h4 = {
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 20,
  lineHeight: '20px',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    fontSize: 24,
    lineHeight: '24px',
  },
};
theme.typography.body1 = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '24px',
  [theme.breakpoints.up('sm')]: {
    fontSize: 20,
    lineHeight: '24px',
  },
};
theme.typography.body2 = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 14,
  lineHeight: '17px',
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
    lineHeight: '22px',
  },
};
theme.typography.overline = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  lineHeight: '19px',
  textAlign: 'center',
  textTransform: 'none',
  color: '#767986',
  [theme.breakpoints.up('sm')]: {
    fontSize: 18,
    lineHeight: '22px',
  },
};

export default theme;
