import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  VideoPlayer: {
    position: 'relative',
    zIndex: 5,
    '& > video': {
      width: '100%',
      height: 'auto',
      position: 'relative',
      minWidth: '500px', // '640px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
      },
    },
  },

  // custom poster
  VideoPlayer__poster: {
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',

    // poster hover
    '&:hover': {
      cursor: 'pointer',
    },

    // play icon
    '& > span': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '70px',
      height: '70px',
      marginTop: '10px',
      '& > svg': {
        width: '50%',
        height: 'auto',
      },
    },

    // poster title
    '& > h3': {
      position: 'absolute',
      zIndex: '110',
      bottom: '15px',
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'white',
      fontSize: '40px',
      lineHeight: '50px',
      marginTop: 0,
      marginBottom: 0,
      textShadow: '0 0 3px black',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
    },
  },
}));
