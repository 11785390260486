import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  wrapper: {
    // height: '200px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '520px',
      maxHeight: '485px',
      height: 'fit-content',
      width: '100%',
      // minHeight: '460px',
    },
  },

  optionsWrapper: {
    maxHeight: '385px',
    // overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '720px !important',
      maxHeight: '645px',
      height: '100%',
      width: '100%',
      marginTop: '75px',

      '& > div': {
        maxHeight: '100%',
        // height: '385px',
      },
    },
  },
  title: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    color: '#1B2036',
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '25px 30px',
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    // overflowY: 'scroll',
    flexDirection: 'column',
    padding: '0px 15px 15px',
    [theme.breakpoints.up('sm')]: {
      padding: '0px 30px 25px',
    },
  },
  inputTitle: {
    fontFamily: fontFamily,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '21px',
    textAlign: 'center',
    color: '#5568AE',
    display: 'flex',
    marginBottom: '6px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '29px',
      marginBottom: '9px',
    },
  },

  switcherContainer: {
    fontFamily: fontFamily,
    boxSizing: 'border-box',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    // [theme.breakpoints.up('sm')]: {
    //   marginTop: '40px',
    // },
  },

  switcherLang: {
    color: '#5568AE',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
      lineHeight: '24px',
      marginTop: '7px',
    },
  },

  switcherBar: {
    flexShrink: 0,
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    background: 'rgba(15, 94, 213, 0.1)',
    boxShadow: 'inset 0px 2px 8px rgba(16, 90, 211, 0.05)',
    borderRadius: '30px',
    width: '100%',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '4px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '25px',
    },
    '& .mode': {
      fontFamily: 'Nunito Sans',
      fontStyle: 'normal',
      fontWeight: 900,
      fontSize: 24,
      lineHeight: '28px',
      color: '#5568AE',
      padding: '8px 15px',
      whiteSpace: 'nowrap',
      width: '50%',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        fontSize: 32,
        padding: '13px 30px',
      },
    },

    '& .active': {
      background: 'linear-gradient(180deg, #9FEE3A 0%, #43AD17 100%)',
      color: 'white',
      borderRadius: '70px',
      textStroke: '0.5px #000000',
      textShadow: '0px 2px 0px #000000',
    },
  },

  menuPointWrapper: {
    cursor: 'pointer',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > p': {
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '24px',
      [theme.breakpoints.up('sm')]: {
        fontSize: 24,
        lineHeight: '29px',
      },
    },
    '& > img': {
      width: '14px',
      height: '20px',
    },
  },

  border: {
    height: '1px',
    display: 'block',
    background: 'rgba(15, 94, 213, 0.1)',
    margin: '15px -15px 15px',
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      margin: '23px -30px 20px',
    },
  },
  itemsContainer: {
    height: '310px',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    display: 'grid',
    gap: '15px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(65px, 105px))',
    // gridTemplateRows: 'repeat(auto-fill, minmax(65px, 75px))',
    overflowY: 'scroll',
    paddingBottom: '75px',
    marginTop: '15px',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      height: '645px',
      gridTemplateColumns: 'repeat(auto-fill, minmax(75px, 150px))',
      marginTop: '30px',
      gap: '20px',
      // gridTemplateRows: 'repeat(auto-fill, minmax(75px, 95px))',
    },
    // '& > div': {
    //   height: '75px',
    //   width: '100%',
    //   borderRadius: '5px',
    //   [theme.breakpoints.up('sm')]: {
    //     height: '95px',
    //   },
    // },
  },

  avatar: {
    display: 'flex',
    cursor: 'pointer',
    transition: '0.1s ease-out',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    '&.active': {
      border: '2px solid #2C74F3',
      '&:hover': {
        cursor: 'default',
      },
    },
  },

  backgroundItem: {
    height: '105px',
    width: '100%',
    borderRadius: '8px',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      height: '150px',
    },
    '&.active': {
      border: '2px solid #2C74F3',
      '&:hover': {
        cursor: 'default',
      },
    },
    '& > .free': {
      background: '#43AD17',
      color: 'white',
      textShadow: 'none',
      textStroke: 'initial',
      fontSize: '12px',
      textTransform: 'uppercase',
      borderRadius: '4px 0px',
      width: '38px',
      height: '17px',
      fontFamily: 'SF Pro Rounded Black',
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: '17px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 'auto',
      marginLeft: 'auto',
    },
  },

  check: {
    position: 'absolute',
    zIndex: 2,
    transform: 'translate(-50%, -50%)',
    top: '48%',
    left: '48%',
    width: '21px',
    height: '21px',
    [theme.breakpoints.up('sm')]: {
      width: '35px',
      height: '35px',
    },
  },

  background: {
    background: 'rgba(255, 255, 255, 0.45)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1,
    border: '2px solid #2C74F3',
    borderRadius: '4px',
  },

  optionsButton: {
    marginBottom: '15px',
    zIndex: 2,
    position: 'absolute',
    bottom: '0px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },

  block: {
    display: 'block',
    overflow: 'hidden',
    height: '323px',
    [theme.breakpoints.up('sm')]: {
      height: '100%',
    },
  },
}));
