import { makeStyles } from '@material-ui/core/styles';
import { fontFamily } from '@src/art/theme/textStyles';

export const styles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#E5E5E5',
    flexDirection: 'column',
    '& > svg': {
      '& > rect': {
        '&:nth-child(1)': {
          animation: 'change-fill 1.2s 0s ease infinite;',
        },
        '&:nth-child(2)': {
          animation: 'change-fill 1.2s 0.1s ease infinite;',
        },
        '&:nth-child(3)': {
          animation: 'change-fill 1.2s 0.2s ease infinite;',
        },
        '&:nth-child(4)': {
          animation: 'change-fill 1.2s 0.3s ease infinite;',
        },
        '&:nth-child(5)': {
          animation: 'change-fill 1.2s 0.4s ease infinite;',
        },
        '&:nth-child(6)': {
          animation: 'change-fill 1.2s 0.5s ease infinite;',
        },
        '&:nth-child(7)': {
          animation: 'change-fill 1.2s 0.6s ease infinite;',
        },
        '&:nth-child(8)': {
          animation: 'change-fill 1.2s 0.7s ease infinite;',
        },
        '&:nth-child(9)': {
          animation: 'change-fill 1.2s 0.8s ease infinite;',
        },
        '&:nth-child(10)': {
          animation: 'change-fill 1.2s 0.9s ease infinite;',
        },
        '&:nth-child(11)': {
          animation: 'change-fill 1.2s 1s ease infinite;',
        },
        '&:nth-child(12)': {
          animation: 'change-fill 1.2s 1.1s ease infinite;',
        },
      },
    },
    '& > p': {
      marginTop: '12px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '21px',
      color: '#5F6372',
    },
  },
}));
