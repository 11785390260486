import pandaColors from '@pc/helpers/colors';

type TProps = {
  color?: string;
};

const CameraOnIcon = ({ color = pandaColors.white._50 }: TProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7931 15.2H12.9655C12.5623 15.2 12.2 15.5384 12.2 16V24C12.2 24.4616 12.5623 24.8 12.9655 24.8H22.7931C23.1963 24.8 23.5586 24.4616 23.5586 24V16C23.5586 15.5384 23.1963 15.2 22.7931 15.2ZM12.9655 14C11.88 14 11 14.8954 11 16V24C11 25.1046 11.88 26 12.9655 26H22.7931C23.8786 26 24.7586 25.1046 24.7586 24V16C24.7586 14.8954 23.8786 14 22.7931 14H12.9655ZM26.5404 18.2669C26.2417 18.5202 26.069 18.8955 26.069 19.2912V20.7088C26.069 21.1045 26.2417 21.4798 26.5404 21.7331L28.9254 23.7555C29.3521 24.1173 30 23.8085 30 23.2433V16.7567C30 16.1915 29.3521 15.8827 28.9254 16.2445L26.5404 18.2669Z"
      fill={color}
    />
  </svg>
);

export default CameraOnIcon;
