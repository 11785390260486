import { makeStyles } from '@material-ui/core';
import pandaColors from '../../../helpers/colors';
import videoOff from '@pc/assets/images/video-off.svg';

const { black, primary } = pandaColors;

export const styles = makeStyles((theme) => ({
  Sidebar: {
    // outline: '1px solid lightgreen',
    width: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: black._800,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '280px',

    // scrollbars
    overflowY: 'scroll',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      background: 'transparent',
      width: 0,
      display: 'none',
    },

    // logo
    '& > img': {
      width: '100%',
    },

    // client mobile
    '&.isClient': {
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        padding: '15px',
        overflowY: 'inherit',
        flexBasis: 'auto',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& > img': {
          display: 'none',
        },

        '& > div': {
          width: '30vw',
          height: '30vw',
          flexBasis: '30%',
          minWidth: '30vw',
          minHeight: '30vw',
          flexGrow: 1,
          flexShrink: 1,
          marginRight: '15px',
          marginBottom: 0,
          backgroundSize: 'contain',
        },

        '& > ul': {
          width: '30vw',
          height: '30vw',
          flexBasis: '30%',
          minWidth: '30vw',
          minHeight: '30vw',
          flexGrow: 1,
          flexShrink: 1,
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'center',
          '& > li': {
            width: 'auto',
            padding: 0,
            minHeight: 'auto',
            marginBottom: 0,
            '&:last-child': {
              marginRight: 0,
            },
            '&.settings': {
              display: 'none',
            },
          },
          '& span': {
            display: 'none',
          },
        },

        '& .PersonVideoStream': {
          width: '30vw',
          height: '30vw',
        },

        '& .PersonWrapper': {
          '& > i': {
            right: '5px',
            bottom: '5px',
            width: '24px',
            height: '24px',
            '& > svg': {
              width: '24px',
              height: '24px',
            },
          },

          '& .PersonItem__muteAudio': {
            top: '5px',
            right: '5px',
          },
        },
      },
    },
  },

  // menu
  Sidebar__menu: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    fontWeight: 500,
    fontSize: '18px',
    width: '100%',

    '& > li': {
      width: '100%',
      minHeight: '40px',
      padding: '0 20px',
      marginBottom: '10px',
      transition: 'all 0.23s',

      '&.disabled': {
        cursor: 'default',
        pointerEvents: 'none',
        opacity: '0.5',
      },

      '& > div': {
        display: 'inline-flex',
        alignItems: 'center',
        '&:hover': {
          cursor: 'pointer',
          '& > i': {
            '& path': {
              fill: primary.gold,
            },
          },
          '& > span': {
            color: primary.gold,
          },
        },
        '& > i': {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          backgroundColor: black._650,
          borderRadius: '50%',
          marginRight: '14px',
          transition: 'all 0.23s',
          '& path': {
            transition: 'all 0.23s',
          },
        },

        '& > span': {
          display: 'inline-flex',
          transition: 'all 0.23s',
        },
      },
    },
  },

  Sidebar__videoPlaceholder: {
    flexShrink: 0,
    width: '240px',
    height: '240px',
    borderRadius: '10px',
    marginBottom: '20px',
    backgroundColor: pandaColors.black._650,
    backgroundImage: `url(${videoOff})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },

  // Вкл/выкл видео
  video: {
    '&.isVideoOff': {
      '& > div': {
        '& > span': {
          color: pandaColors.red,
        },
        '&:hover': {
          '& > i': {
            '& path': {
              fill: pandaColors.white._50,
            },
          },
          '& > span': {
            color: pandaColors.red,
          },
        },
      },
    },
  },

  // Вкл/выкл звук
  sound: {
    '&.isAudioOff': {
      '& > div': {
        '& > span': {
          color: pandaColors.red,
        },
        '&:hover': {
          '& > i': {
            '& path': {
              fill: pandaColors.white._50,
            },
          },
          '& > span': {
            color: pandaColors.red,
          },
        },
      },
    },
  },

  // Настройки
  settings: {},

  // Выбрать колоду - Дать карту
  chooseCards: {
    display: 'flex',
    flexDirection: 'column',
    background: pandaColors.black._700,

    '& > div': {
      alignSelf: 'flex-start',
    },
    '& > button': {
      marginTop: '15px',
      height: '40px',
    },
  },

  // Завершить сеанс
  endSession: {},

  // Выйти
  logOut: {},
}));
