import { makeStyles } from '@material-ui/core';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles(() => ({
  MobileEnter: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      position: 'relative',
      width: '250px',
      paddingLeft: '12px',
      '& > img:first-child': {
        width: '189px',
      },
      '& > img:last-child': {
        position: 'absolute',
        right: '8px',
        bottom: '-6px',
      },
    },
    '& > h2': {
      color: pandaColors.white._0,
      textAlign: 'center',
      fontSize: '28px',
      fontWeight: 600,
      width: '250px',
      marginTop: 30,
      marginBottom: 0,
    },
  },
}));
