import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';

export const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    loginPage: {
      fontFamily,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '600px',
      background: theme.palette.common.white,
      [theme.breakpoints.up('sm')]: {
        background: '#F3F4F5',
      },
      '& *': {
        boxSizing: 'border-box',
      },
    },
    whiteBox: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '30px 0 25px',
      background: theme.palette.common.white,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        width: '380px',
        minHeight: '570px',
        borderRadius: '20px',
        border: '1px solid #E8E9EB',
      },
    },
    helloLogo: {
      width: '95px',
      marginBottom: '35px',
      [theme.breakpoints.up('sm')]: {
        width: '120px',
        marginBottom: '20px',
      },
    },
    title: {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: 500,
      marginBottom: '6px',
      [theme.breakpoints.up('sm')]: {
        fontSize: '14px',
        lineHeight: '17px',
      },
    },
    divider: {
      position: 'relative',
      zIndex: 1,
      height: '1px',
      width: '100%',
      background: '#E8E9EB',
      marginLeft: '-25px',
      marginRight: '-25px',
      '& span': {
        position: 'absolute',
        zIndex: 2,
        background: 'white',
        top: '-12px',
        left: '50%',
        marginLeft: '-10px',
        width: '30px',
        height: '21px',
        display: 'inline-flex',
        justifyContent: 'center',
        fontSize: '15px',
        lineHeight: '21px',
        color: '#BBBCC3',
      },
    },
    haveAcount: {
      fontWeight: 500,
      width: '100%',
      textAlign: 'center',
      fontSize: '12px',
      lineHeight: '14px',
      '& a': {
        color: '#2C74F3',
      },
    },
  })
);
