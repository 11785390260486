export const SERVER_ERROR_AUTH_NO_USER = 400;
export const SERVER_ERROR_AUTH_STATUS_CODE: number = 401;

import { logger } from '@src/common/logger';
import { UserManager } from 'oidc-client';
import { userManager } from './auth.config';

export const authLogger = {
  log: (...args: string[]) => {
    console.debug('AUTH_LOG', ...args);
  },
};

export const authSignInSilent = () => {
  return userManager
    .getUser()
    .then((user) => {
      if (user && user.expired) {
        return userManager.signinSilent();
      }
      return user;
    })
    .then((user) => {
      console.log(user);
      if (user && !user.expired) {
        return Promise.resolve();
      } else {
        // userManager.signOut
        // user not updated, error?
        return userManager.removeUser().finally(() => Promise.reject('Failed to refresh user token'));

        // return Promise.reject('Failed to refresh user token');
      }
    });
};
