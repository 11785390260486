import DesktopImage from '@pc/assets/images/desktop.svg';
import TabletImage from '@pc/assets/images/tablet.svg';
import { styles } from './MobileEnter.styles';

const MobileEnter: React.FC = () => {
  const classes = styles();

  return (
    <section className={`pandacards ${classes.MobileEnter}`}>
      <div>
        <img src={DesktopImage} alt="desktop.svg" />
        <img src={TabletImage} alt="tablet.svg" />
      </div>

      <h2>Войдите в кабинет с компьютера или&nbsp;планшета</h2>
    </section>
  );
};

export default MobileEnter;
