const clickCallback = () => {};
const stringCallback = (parameter: string) => {};

export type ClickCallbackType = typeof clickCallback;
export type StringCallbackType = typeof stringCallback;

export enum RequestType {
  GET = 'GET',
  POST = 'POST',
}
