import { makeStyles } from '@material-ui/core/styles';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: pandaColors.black._900,
    flexDirection: 'column',
  },

  Loader: {
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',
  },

  Loader__type2: {
    // type-2
    '& > div': {
      position: 'absolute',
      width: '6px',
      height: '6px',
      borderRadius: '50%',
      animation: 'loader-default 1.2s linear infinite',

      '&:nth-child(1)': {
        animationDelay: '0s',
        top: '37px',
        left: '66px',
      },
      '&:nth-child(2)': {
        animationDelay: '-0.1s',
        top: '22px',
        left: '62px',
      },
      '&:nth-child(3)': {
        animationDelay: '-0.2s',
        top: '11px',
        left: '52px',
      },
      '&:nth-child(4)': {
        animationDelay: '-0.3s',
        top: '7px',
        left: '37px',
      },
      '&:nth-child(5)': {
        animationDelay: '-0.4s',
        top: '11px',
        left: '22px',
      },
      '&:nth-child(6)': {
        animationDelay: '-0.5s',
        top: '22px',
        left: '11px',
      },
      '&:nth-child(7)': {
        animationDelay: '-0.6s',
        top: '37px',
        left: '7px',
      },
      '&:nth-child(8)': {
        animationDelay: '-0.7s',
        top: '52px',
        left: '11px',
      },
      '&:nth-child(9)': {
        animationDelay: '-0.8s',
        top: '62px',
        left: '22px',
      },
      '&:nth-child(10)': {
        animationDelay: '-0.9s',
        top: '66px',
        left: '37px',
      },
      '&:nth-child(11)': {
        animationDelay: '-1s',
        top: '62px',
        left: '52px',
      },
      '&:nth-child(12)': {
        animationDelay: '-1.1s',
        top: '52px',
        left: '62px',
      },
    },
  },

  text: {
    fontFamily: 'SF Pro Display',
    color: pandaColors.white._0,
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textAlign: 'center',
    marginTop: '10px',
  },
}));
