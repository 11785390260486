import { TakeCardData, UsedDeck } from '@src/shared/sessionInterfaces/standardSession.socket';
import TakeCardsClosedModal from './TakeCardsClosedModal/TakeCardsClosedModal';
import TakeCardsOpenedModal from './TakeCardsOpenedModal/TakeCardsOpenedModal';
import { IdType } from '@src/shared/generics';
import { TakeCardsOpenedMobileModal } from './TakeCardsOpenedModal/TakeCardsOpenedMobileModal';
import { CardSwapperMobile } from '../../parts/CardSwapperMobile/CardSwapperMobile';

interface Props {
  isOpen: boolean;
  takeCard?: TakeCardData;
  onCardTaken: (deckId: IdType | undefined, index: number, faceUp?: boolean) => void;
}

const TakeCardsModal: React.FC<Props> = ({ isOpen, takeCard, onCardTaken }) => {
  if (!isOpen) return <></>;

  return (
    <>
      <TakeCardsClosedModal takeCard={takeCard!} onCardTaken={onCardTaken} isOpen={isOpen && !takeCard?.faceUp} />

      <TakeCardsOpenedModal takeCard={takeCard!} onCardTaken={onCardTaken} isOpen={isOpen && takeCard!.faceUp} />
    </>
  );
};

const TakeCardsMobileModal: React.FC<Props> = ({ isOpen, takeCard, onCardTaken }) => {
  if (!isOpen) return <></>;

  return (
    <>
      <CardSwapperMobile takeCard={takeCard!} onCardTaken={onCardTaken} isOpen={isOpen} />
      {/*<TakeCardsClosedModal takeCard={takeCard!} onCardTaken={onCardTaken} isOpen={isOpen && !takeCard?.faceUp} />

      <TakeCardsOpenedMobileModal takeCard={takeCard!} onCardTaken={onCardTaken} isOpen={isOpen && takeCard!.faceUp} />*/}
    </>
  );
};

export { TakeCardsModal, TakeCardsMobileModal };
