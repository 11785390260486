import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';

interface Props {
  type: string;
  icon?: string;
  iconSize?: string;
  iconPosition?: string;
  onClick: () => void;
}

const SocialItem: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const colorBoxStyles = {
    backgroundImage: `url(${props.icon})`,
    backgroundSize: props.iconSize,
    backgroundPosition: props.iconPosition,
  };

  return (
    <a href="#" onClick={props.onClick} className={`${classes.socialItem} ${props.type}`}>
      <div className="color-box" style={colorBoxStyles} />
      <Typography>Sign In with {props.type}</Typography>
    </a>
  );
};

export default SocialItem;
