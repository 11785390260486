import { useAppSelector } from '@src/app/hooks';
import { styles } from './ScrollContainer.styles';
import { findTooltip, selectTooltip, selectTooltipId } from '@src/store/reducers/popups';
import { useEffect, useState } from 'react';

type TProps = {
  overflowXWidth?: string;
  children: React.ReactNode;
};

const ScrollContainer = ({ children, overflowXWidth = 'auto' }: TProps) => {
  const classes = styles();

  return (
    <div className={classes.ScrollContainerY}>
      {overflowXWidth ? (
        <div className={classes.ScrollContainerX} style={{ minWidth: overflowXWidth }}>
          {children}
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default ScrollContainer;
