import { makeStyles } from '@material-ui/core';
import arrowDown from '@art/icons/arrow-down.svg';
import pandaColors from '@src/_pandacards/helpers/colors';

export const styles = makeStyles((theme) => ({
  SettingsModal: {
    fontFamily: 'SF Pro Display',
    flexDirection: 'column',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    padding: '25px 25px 20px',
    minWidth: '540px',
    position: 'relative',
    '& > h3': {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      marginTop: 0,
      fontStyle: 'normal',
      textAlign: 'center',
      color: pandaColors.black._900,
      marginBottom: '10px',
    },
  },

  SettingsModal__content: {
    display: 'flex',
    flexDirection: 'column',
  },

  SettingsModal__contentItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .title': {
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '24px',
      fontStyle: 'normal',
      textAlign: 'start',
      color: pandaColors.primary.blue,
      marginBottom: '4px',
    },

    '& .menu': {
      backgroundColor: pandaColors.white._50,
      borderRadius: '30px',
      border: 'none',
      padding: '11px 20px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '21px',
      color: pandaColors.black._900,
      width: '100%',
      display: 'block',
      alignItems: 'center',
      marginBottom: '15px',
      appearance: 'none',
      backgroundImage: `url(${arrowDown})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '8px 12px',
      backgroundPositionX: '95%',
      backgroundPositionY: '55%',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
    },
  },
}));
