import { useAppSelector } from '@src/app/hooks';
import { selectIsDoctor } from '@src/store/room/roomSlice';
import { CardsWillBeSoon, ChoosingCardsOverlay, LostConnection, SimpleCard } from '..';
import { CardPlacement } from '../../standardSession.types';
import { HookType } from '../../standardSession.hook';
import { useLayouts } from './useLayouts';
import { CardFieldLayout } from './CardFieldLayout';
import { styles } from './CardField.styles';
import { useEffect, useRef, useState } from 'react';
import { Aspect, defaultAspect } from '../cardMath';
import { EnlargedSlot } from './EnlargedSlot';

interface Props {
  hook: HookType;
}

const CardField: React.FC<Props> = ({ hook }) => {
  const classes = styles();
  const cardFieldRef = useRef<HTMLDivElement>(null);
  const isDoctor = useAppSelector(selectIsDoctor);
  const session = hook.session;
  const [aspect, setAspect] = useState<Aspect>(defaultAspect);
  const [gap, padding, slotAspect] = useLayouts(isDoctor, aspect, session.slots.length);

  const isLostConnection = false;

  useEffect(() => {
    if (cardFieldRef.current) {
      setAspect({
        height: cardFieldRef.current.clientHeight - 2 * padding,
        width: cardFieldRef.current.clientWidth - 2 * padding,
      });
    }
  }, []);

  // lost connection for client
  if (!isDoctor && isLostConnection) return <LostConnection isDoctor={false} />;

  return (
    <div style={{ height: '100%', width: '100%' }} ref={cardFieldRef}>
      <div
        className={`${classes.CardField} ${isDoctor ? 'isDoctor' : 'isClient'}`}
        style={{ gap: `${gap}px`, padding: `${padding}px` }}
      >
        {/* Слоты с картами */}
        <CardFieldLayout hook={hook} slotAspect={slotAspect} />

        {/* enlarged card */}
        {session.isState.ENLARGE && session.enlarged >= 0 && (
          <EnlargedSlot
            className={classes.CardField__enlarge}
            hook={hook}
            slotIndex={session.enlarged}
            slotAspect={aspect}
          />
        )}

        {/* Клиент выбирает карты */}
        {isDoctor && hook.isState.TAKE_CARD && (
          <ChoosingCardsOverlay cards={session.cards} takeCardData={hook.session.takeCard!} />
        )}

        {/* Скоро здесь появятся карты */}
        {!isDoctor && !session.slots.length && <CardsWillBeSoon />}
      </div>
    </div>
  );
};

export default CardField;
