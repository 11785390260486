import { makeStyles } from '@material-ui/core/styles';
import pandaColors from '@src/_pandacards/helpers/colors';

// fixed sizes
const TOP = 40;
const CENTER_LEFT = 680;
const CENTER_RIGHT = 390;
const BOTTOM = 130;
const PADDING = 30;
const DOCTOR_LAYOUT_WIDTH = CENTER_LEFT + CENTER_RIGHT + PADDING * 3;

export const styles = makeStyles(() => ({
  DoctorLayout: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: `${PADDING / 2}px`,
    paddingLeft: `${PADDING}px`,
    paddingRight: `${PADDING}px`,
    paddingBottom: 0,
    marginLeft: 'auto', // центровка по горизонтали
    marginRight: 'auto', // центровка по горизонтали
    alignSelf: 'flex-start', // центровка по вертикали по верхнему краю

    // если не будет переполнения контента, то можно убрать, но лучше оставить как защиту
    // --- LAYOUT fixed sizes --- //
    width: DOCTOR_LAYOUT_WIDTH,
    // --- LAYOUT fixed sizes --- //
  },

  DoctorLayout__top: {
    display: 'flex',
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: `${PADDING / 2}px`,

    // --- TOP fixed sizes --- //
    height: `${TOP}px`,
    maxHeight: `${TOP}px`,
    flexBasis: `${TOP}px`,
    // --- TOP fixed sizes --- //
  },

  DoctorLayout__center: {
    display: 'flex',
    marginBottom: '20px',
    flexGrow: 0,
    flexSrink: 0,
  },

  DoctorLayout__centerLeft: {
    position: 'relative',
    zIndex: 1,
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'flex-start',
    marginRight: `${PADDING}px`,

    // --- CENTER_LEFT fixed sizes --- //
    width: `${CENTER_LEFT}px`,
    height: `${CENTER_LEFT}px`,
    maxHeight: `${CENTER_LEFT}px`,
    flexBasis: `${CENTER_LEFT}px`,
    // --- CENTER_LEFT fixed sizes --- //
  },

  DoctorLayout__centerRight: {
    alignItems: 'flex-start',
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: '0 0 10px 10px ',
    border: `1px solid ${pandaColors.black._650}`,

    // --- CENTER_RIGHT fixed sizes --- //
    // height: `${CENTER_LEFT}px`,
    // maxHeight: `${CENTER_LEFT}px`,
    height: `${CENTER_LEFT}px`,
    maxHeight: `${CENTER_LEFT}px`,
    flexBasis: `${CENTER_RIGHT}px`,
    width: `${CENTER_RIGHT}px`,
    // --- CENTER_RIGHT fixed sizes --- //
  },

  DoctorLayout__bottom: {
    display: 'flex',
    padding: '10px',
    borderRadius: '10px 10px 0px 0px',
    backgroundColor: pandaColors.black._800,
    flexGrow: 0,
    flexShrink: 0,
    overflowX: 'auto',

    // --- BOTTOM fixed sizes --- //
    height: `${BOTTOM}px`,
    maxHeight: `${BOTTOM}px`,
    flexBasis: `${BOTTOM}px`,
    // --- BOTTOM fixed sizes --- //

    // add custom scrollbar
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    // y-scrollbar track width / height
    '&::-webkit-scrollbar': {
      width: 0,
      height: '4px',
    },

    // scrollbar track background (полоска)
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },

    // scrollbar thumb (бегунок)
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: pandaColors.black._600,
      borderRadius: '8px',
    },
  },
}));
