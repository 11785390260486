import { fetchGraphql, mutateGraphql } from '@library/fetch';
import { postClientLog } from '@graphql/debugQueries';
import { logger } from '@common/logger';
import { NOTIFICATION_TYPE } from '@src/store/room/notifications';
import { pushNotification } from '@src/store/room/roomFeaturesData.slice';
import { AppDispatch, AppGetState } from '@src/app/store';

type AgoraError = {
  userId: string;
  state: number;
  message: string;
};

const sendError = (userId: string, state: number, message: string) => sendErrorToServer({ userId, state, message });

const sendErrorToServer = (error: AgoraError) => {
  const errorMessage = `Agora error: ${error.message} - ${error.state}`;
  return sendDataToServer(error.userId, errorMessage, 'AGORA');
};

const sendAgoraError = (userId: string, message: string, showError = false) => (
  dispatch: AppDispatch,
  getState: AppGetState
) => {
  sendDataToServer(userId, message, 'AGORA');
  if (showError) {
    dispatch(pushNotification({ type: NOTIFICATION_TYPE.ERROR }));
  }
};

const sendDataToServer = (userId: string, message: string, tag = 'CLIENT') => {
  logger.error(message);
  return mutateGraphql(postClientLog, { userId, tag, message }, false).then((data) => {});
};

export { sendErrorToServer, sendDataToServer, sendError, sendAgoraError };
