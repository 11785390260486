import googleIcon from '@art/icons/Social/google.svg';
import facebookIcon from '@art/icons/Social/facebook.svg';
import twitterIcon from '@art/icons/Social/twitter.svg';
import vkontakteIcon from '@art/images/social/vkontakte.svg';

export const loginSocialCirleItems = [
  {
    type: 'Google',
    icon: googleIcon,
    backgroundColor: '#DC4E41',
  },
  {
    type: 'Facebook',
    icon: facebookIcon,
    backgroundColor: '#5180E4',
  },
  {
    type: 'Twitter',
    icon: twitterIcon,
    backgroundColor: '#20ACFB',
  },
  {
    type: 'More',
    icon: '',
  },
];

export const loginSocialCirleItemsNew = [
  {
    type: 'Google',
    icon: googleIcon,
    backgroundColor: '#DC4E41',
  },
  {
    type: 'Facebook',
    icon: facebookIcon,
    backgroundColor: '#5180E4',
  },
  {
    type: 'Twitter',
    icon: twitterIcon,
    backgroundColor: '#20ACFB',
  },
  {
    type: 'Vkontakte',
    icon: vkontakteIcon,
    backgroundColor: '#ffffff',
  },
];
