import { makeStyles } from '@material-ui/core';
import pandaColors from '@pc/helpers/colors';

const { black } = pandaColors;

export const styles = makeStyles((theme) => ({
  ImagineQuestion: {
    flexGrow: 1,
    width: '100%',
    padding: '12px 30px 17px',
    background: pandaColors.white._0,
    alignItems: 'center',

    // client mobile
    [theme.breakpoints.down('xs')]: {
      padding: '9px 15px',
    },

    '& > span': {
      fontSize: '18px',
      color: black._500,
      fontWeight: 400,

      // client mobile
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
      },
    },

    '& > p': {
      color: black._900,
      fontSize: '18px',
      fontWeight: 500,
      margin: 0,

      // client mobile
      [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
      },
    },
  },
}));
