import { gql } from '@apollo/client';

export type IImageSourceSet = {
  x1?: string;
  x2?: string;
  x3?: string;
  mobile?: string;
  desktop?: string;
  tablet?: string;
};

export interface IImage {
  srcSet?: IImageSourceSet;
  src?: string;
}

export const imageFragment = gql`
  fragment ImageFragment on Image {
    srcSet {
      x1
      x2
      x3
      mobile
      tablet
      desktop
    }
    src
  }
`;
