import React from 'react';

// types checking
interface Props {
  fill: string;
}

// FC
const Eye: React.FC<Props> = ({ fill }: Props) => {
  return (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none">
      <path
        d="M9 0.634766C5.56091 0.634766 2.44216 2.51632 0.140841 5.57248C-0.0469469 5.82286 -0.0469469 6.17266 0.140841 6.42304C2.44216 9.48288 5.56091 11.3644 9 11.3644C12.4391 11.3644 15.5578 9.48288 17.8592 6.42673C18.0469 6.17634 18.0469 5.82654 17.8592 5.57616C15.5578 2.51632 12.4391 0.634766 9 0.634766ZM9.2467 9.77745C6.96379 9.92105 5.07855 8.03949 5.22215 5.7529C5.33998 3.86766 6.86806 2.33958 8.7533 2.22176C11.0362 2.07815 12.9214 3.95971 12.7778 6.2463C12.6563 8.12786 11.1283 9.65594 9.2467 9.77745ZM9.13256 8.03213C7.90273 8.10945 6.88647 7.09687 6.96747 5.86705C7.03007 4.85078 7.85486 4.02967 8.87113 3.96339C10.101 3.88607 11.1172 4.89865 11.0362 6.12848C10.9699 7.14842 10.1451 7.96953 9.13256 8.03213Z"
        fill={fill}
      />
    </svg>
  );
};

export default Eye;
