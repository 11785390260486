import { makeStyles } from '@material-ui/core';
import { fontFamily } from '@src/art/theme/textStyles';
import arrowDown from '@art/icons/arrow-down.svg';

export const styles = makeStyles((theme) => ({
  wrapper: {
    color: 'inherit',
    flexDirection: 'column',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    '& > h3': {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
      marginTop: 0,
      fontStyle: 'normal',
      textAlign: 'center',
      color: 'black',
      marginBottom: '10px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& .title': {
      fontFamily: fontFamily,
      fontWeight: 600,
      fontSize: '17px',
      lineHeight: '24px',
      fontStyle: 'normal',
      textAlign: 'start',
      color: '#5568AE',
      marginBottom: '4px',
    },
    '& .menu': {
      background: 'rgba(15, 94, 213, 0.1)',
      boxShadow: 'inset 0px 2px 8px rgb(16 90 211 / 5%)',
      borderRadius: '30px',
      border: 'none',
      padding: '11px 20px',
      fontFamily: fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '21px',
      color: '#1B2036',
      width: '100%',
      display: 'block',
      alignItems: 'center',
      marginBottom: '15px',
      appearance: 'none',
      backgroundImage: `url(${arrowDown})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '8px 12px',
      backgroundPositionX: '95%',
      backgroundPositionY: '55%',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      // [theme.breakpoints.up('sm')]: {
      //   fontSize: '24px',
      //   lineHeight: '21px',
      // },
    },
  },
}));
