import { createStyles, makeStyles } from '@material-ui/core/styles'; // without /styles?
import { fontFamily } from '@src/art/theme/textStyles';
import { Theme } from '@material-ui/core';

export interface StylesProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const styles = makeStyles<Theme, StylesProps>((theme: Theme) =>
  createStyles({
    modal: {
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      animationName: 'appearFromBottom',
      animationDuration: '.23s',

      // overlayTransparent
      '&.overlayTransparent .MuiBackdrop-root': {
        backgroundColor: 'transparent',
      },

      '& *': {
        boxSizing: 'border-box',
      },
      '& .MuiBackdrop-root': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        right: (props) => `${props.right}px`,
        bottom: (props) => `${props.bottom}px`,
        top: (props) => `${props.top}px`,
      },
      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
      },

      '&.isFullscreen': {
        top: 0,
        right: 0,
        '& .MuiBackdrop-root': {
          top: 0,
          right: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      },
    },

    paper: {
      fontFamily,
      backgroundColor: '#fff',
      flexDirection: 'column',
      borderRadius: '20px 20px 0px 0px',
      color: '#1B2036',
      outline: 'none',
      width: '100%',
      position: 'relative',
      '&::-webkit-scrollbar': {
        background: 'transparent',
        width: 0,
        display: 'none',
      },

      '&.resetWidth': {
        padding: 0,
        [theme.breakpoints.up('sm')]: {
          padding: 0,
          maxWidth: 'none',
        },
        [theme.breakpoints.up('md')]: {
          padding: 0,
          maxWidth: 'none',
        },
      },

      '&.padding': {
        padding: '22px 14px 15px',
        // [theme.breakpoints.up('sm')]: {
        //   padding: '22px 35px 25px',
        // },
        // [theme.breakpoints.up('md')]: {
        //   padding: '15px',
        // },
      },

      // profile modal
      '&.profile': {
        // width: '465px',
        padding: 0,
        maxWidth: '100%',
        [theme.breakpoints.up('sm')]: {
          width: '465px',
          minHeight: '220px', // '220px'
          height: 'fit-content',
          maxHeight: '345px',
        },
        [theme.breakpoints.up('md')]: {
          maxWidth: '465px',
        },
      },
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        maxWidth: '385px',
        minWidth: '375px',
        borderRadius: '20px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '415px',
      },
      '&.change-background': {
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
          width: '515px',
          maxWidth: '515px',
          paddingLeft: '15px',
          paddingRight: '15px',
        },
        [theme.breakpoints.up('md')]: {
          width: '660px',
          maxWidth: '660px',
          paddingLeft: '30px',
          paddingRight: '30px',
        },
      },
    },

    content: {
      flexDirection: 'column',
      position: 'relative',
      // maxHeight: '380px',
      height: 'content-fit',
      // overflow: 'scroll',
      // '&::-webkit-scrollbar': {
      //   background: 'transparent',
      //   width: 0,
      //   display: 'none',
      // },

      '& h3': {
        textAlign: 'center',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        marginTop: 0,
        marginBottom: '10px',
      },
      // '& .button': {
      //   width: '145px',
      //   height: '36px',
      //   '& span': {
      //     fontWeight: 500,
      //     fontSize: '15px',
      //     lineHeight: '18px',
      //   },
      // },
    },
  })
);
