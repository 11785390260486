import React from 'react';

interface IProps {
  fill?: string;
}

const Close: React.FC<IProps> = ({ fill = 'url(#paint0_linear_6875_16464)' }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.640532 0.686516C-0.21351 1.54055 -0.213511 2.92522 0.640531 3.77925L4.86162 8.00027L0.641064 12.2207C-0.212978 13.0748 -0.212978 14.4594 0.641064 15.3135L0.686321 15.3587C1.54036 16.2128 2.92504 16.2128 3.77908 15.3587L7.99963 11.1383L12.2209 15.3595C13.075 16.2135 14.4596 16.2135 15.3137 15.3595L15.3589 15.3142C16.213 14.4602 16.213 13.0755 15.3589 12.2215L11.1377 8.00027L15.3595 3.77852C16.2135 2.92449 16.2135 1.53982 15.3595 0.685784L15.3142 0.640528C14.4602 -0.213508 13.0755 -0.21351 12.2215 0.640525L7.99964 4.86227L3.77855 0.641259C2.92451 -0.212777 1.53983 -0.212778 0.685789 0.641259L0.640532 0.686516Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear_6875_16464"
          x1="8.04849"
          y1="1.72733e-06"
          x2="8.04849"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0A97FF" />
          <stop offset="1" stopColor="#1050CB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Close;
