import WSClient from '@src/sockets';
import { useEffect } from 'react';
import { useAppDispatch } from '@src/app/hooks';
import { CLIENT_EVENTS } from '@shared/socketEvents/eventTypes';
import { ProviderActionsType, useSessionProvider } from './standardSession.provider';
import {
  AllDiscardedEvent,
  CardDiscardedEvent,
  CardDragChangedEvent,
  SlotDragEnabledEvent,
  CardDraggedEvent,
  CardEnlargedClientEvent,
  CardFlippedClientEvent,
  CardTakenClientEvent,
  CardWithdrawedEvent,
  CardWordChangedEvent,
  CardsDealedClientEvent,
  CardsDealingClientEvent,
  QuestionMadeClientEvent,
} from '@src/shared/socketEvents/sessionEvents';
import { IdType } from '@src/shared/generics';
import { PopupState, setDealCardsData } from '@src/store/reducers/popups';

const createActions = (sessionId: IdType, actions: ProviderActionsType) => {
  return {
    onQuestionMade: (question: string) => {
      WSClient.emitStrict<QuestionMadeClientEvent>(CLIENT_EVENTS.PLAYER_MADE_QUESTION, { sessionId, question });
    },
    onCardsDealing: (active: boolean) => {
      WSClient.emitStrict<CardsDealingClientEvent>(CLIENT_EVENTS.PLAYER_DEALING_CARDS, { sessionId, active });
    },
    onCardsDealed: (question: string, deckId: IdType, amount: number, faceUp: boolean) => {
      WSClient.emitStrict<CardsDealedClientEvent>(CLIENT_EVENTS.PLAYER_DEALED_CARDS, {
        sessionId,
        question,
        deckId,
        amount,
        faceUp,
      });
    },
    onCardTaken: (deckId: IdType | undefined, index: number, faceUp?: boolean) => {
      WSClient.emitStrict<CardTakenClientEvent>(CLIENT_EVENTS.PLAYER_TOOK_CARDS, { sessionId, deckId, index, faceUp });
    },
    onCardFlipped: (cardId: IdType, faceUp: boolean) => {
      WSClient.emitStrict<CardFlippedClientEvent>(CLIENT_EVENTS.PLAYER_FLIPPED_CARD, { sessionId, cardId, faceUp });
    },
    onCardEnlarged: (slot: number) => {
      WSClient.emitStrict<CardEnlargedClientEvent>(CLIENT_EVENTS.PLAYER_ENLARDED_CARD, { sessionId, slot });
    },
    onCardWordChanged: (cardId: IdType, word: string) => {
      WSClient.emitStrict<CardWordChangedEvent>(CLIENT_EVENTS.PLAYER_CHANGED_WORD, { sessionId, cardId, word });
    },
    onSlotDragEnabled: (slot: number, enabled: boolean) => {
      WSClient.emitStrict<SlotDragEnabledEvent>(CLIENT_EVENTS.PLAYER_ENABLED_DRAG, { sessionId, slot, enabled });
    },
    onCardDragChanged: (active: boolean) => {
      WSClient.emitStrict<CardDragChangedEvent>(CLIENT_EVENTS.PLAYER_DRAGGING_CARD, { sessionId, active });
    },
    onCardDragged: (cardId: IdType, from: number, to: number) => {
      actions.changeCardSlot(cardId, from, to);
      WSClient.emitStrict<CardDraggedEvent>(CLIENT_EVENTS.PLAYER_DRAGGED_CARD, { sessionId, cardId, from, to });
    },
    onCardDiscarded: (slot: number) => {
      WSClient.emitStrict<CardDiscardedEvent>(CLIENT_EVENTS.PLAYER_DISCARDED_CARD, { sessionId, slot });
    },
    onAllDiscarded: () => {
      WSClient.emitStrict<AllDiscardedEvent>(CLIENT_EVENTS.PLAYER_DISCARDED_ALL, { sessionId });
    },
    onCardWithdrawed: (cardId: IdType) => {
      WSClient.emitStrict<CardWithdrawedEvent>(CLIENT_EVENTS.PLAYER_WITHDRAWED_CARD, { sessionId, cardId });
    },
  };
};

const useSessionHook = () => {
  const dispatch = useAppDispatch();

  const provider = useSessionProvider();
  const actions = createActions(provider.session.sessionId, provider.providerActions);

  useEffect(() => {
    if (provider.isDoctor) {
      const state = provider.isState.DEFAULT ? PopupState.ENABLED : PopupState.DISABLED;

      // dispatch(setDealCardsData({ state, onCardsDealing: actions.onCardsDealing }));
      dispatch(
        setDealCardsData({ state, sessionId: provider.session.sessionId, onCardsDealing: actions.onCardsDealing })
      );
    }
  }, [provider.isState]);

  return {
    ...provider,
    actions,
  };
};

export type HookType = ReturnType<typeof useSessionHook>;
type ActionsType = HookType['actions'];

export type { ActionsType };

export { useSessionHook };
